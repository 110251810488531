import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer1 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-compradores-cómo-buscar-publicaciones">Para compradores, ¿cómo buscar publicaciones?</h1>
            
            <p>En Listo los compradores pueden buscar publicaciones activas de los agricultores o asociaciones, de varias maneras.</p>
            <br/>
            <ul>
              <li><Link to="#opción-1-en-la-página-de-inicio">A través de la página de inicio</Link></li>
              <li><Link to="#opción-2-el-botón-explorar">A través del botón Explorar</Link></li>
              <li><Link to="#opción-3-buscar-a-través-de-la-pestaña-intercambio">A través de la pestaña Intercambio</Link></li>
            </ul>

            <br/>

            <h2 id="opción-1-en-la-página-de-inicio">Opción 1. En la página de inicio</h2>

            <ol>
              <li>En <strong>la pestaña de Inicio</strong> encontrará una sección llamada <strong>En venta cerca de ti</strong>.</li>
              <li>En esta podrá <strong>observar las publicaciones realizadas por usuarios</strong> que se encuentran relativamente cerca de usted.</li>
            </ol>

            <br/>
            <br/>
            <h2 id="opción-2-el-botón-explorar">Opción 2. El botón Explorar</h2>

            <ol>
              <li>
                Si usted está buscando un producto en específico, en la pestaña de Inicio también encontrará <strong>una sección llamada Explorar</strong>, esta le permite <strong>buscar publicaciones activas a través del mapa</strong>.
              </li>
              <li>Haga clic en esta sección para <strong>abrir el mapa</strong> de Listo donde encontrará a los usuarios registrados que han activado la opción de Visibilidad para que sus perfiles aparezcan en el mapa.
                <blockquote>
                  <p>Estos usuarios estarán identificados con un ícono que corresponde a la función seleccionada durante el proceso de registro.</p>
                </blockquote>
              </li>
              <li>
                Usted podrá hacer clic sobre estos íconos para ver un resumen del perfil del usuario, si tiene publicaciones activas y los productos que comercializa.
              </li>
              <li>Dentro del mapa en la parte inferior derecha de la pantalla hay un botón que le permite acceder a <strong>la ventana de filtros</strong>, donde podrá personalizar su búsqueda de acuerdo con sus especificaciones.
                <blockquote>
                  <p>Por ejemplo, podrá seleccionar si quiere buscar por función y por artículos, e incluso adaptar la búsqueda para que solo aparezcan publicaciones activas en el mapa. Una vez seleccionados los filtros, <strong>oprima el botón Aplicar</strong>, ubicado en la esquina superior derecha de la pantalla, al hacer esto regresará al mapa y en él aparecerán únicamente los usuarios que tengan publicaciones activas de acuerdo con los filtros seleccionados.</p>
                </blockquote>
              </li>
              <li>Usted también podrá <strong>desplazarse por el mapa a otros lugares</strong> para buscar publicaciones activas en otras regiones del país.</li>
            </ol>

            <br/>
            <br/>
            <h2 id="opción-3-buscar-a-través-de-la-pestaña-intercambio">Opción 3. Buscar a través de la pestaña Intercambio</h2>
            <p>Otra manera de buscar publicaciones activas es a través de <strong>la pestaña Intercambio</strong>.</p>

            <ol>
              <li><strong>Seleccione la ventana Comprar</strong> y dentro de esta <strong>oprima el botón Comprar</strong>.
                <blockquote>
                  <p>Allí podrá buscar publicaciones activas del producto que le interese adquirir.</p>
                </blockquote>
              </li>
              <li>
                <strong>Seleccione una categoría</strong>.
              </li>
              <li>
                Después <strong>escoja el producto dentro de esa categoría</strong>; seguidamente <strong>seleccione la variedad</strong>, si aplica.
              </li>
              <li>
                Seleccione <strong>las unidades de volumen</strong>, es decir si desea comprar kilogramos, arrobas o cargas; <strong>el rango de volumen</strong> que está buscando; <strong>la ubicación</strong> desde la cual quiere hacer la búsqueda; <strong>la distancia de búsqueda</strong>; y finalmente <strong>el método de entrega</strong>.
              </li>
              <li>
                Al haber establecido los criterios de búsqueda, <strong>oprima el botón Buscar</strong> para ver las publicaciones activas de otros usuarios que cumplen con los parámetros que ha seleccionado previamente.
              </li>
              <li>Ahora verá las publicaciones activas en <strong>un formato de lista</strong>, es posible ver los resultados en el mapa, al oprimir el botón ubicado en la parte inferior derecha de la pantalla, podrá <strong>seleccionar la Vista del Mapa</strong> y ver la ubicación aproximada desde la que se está vendiendo el producto.
                <blockquote>
                  <p>También podrá <strong>ordenar los resultados de la búsqueda</strong> de acuerdo con sus preferencias.</p>
                </blockquote>
              </li>
            </ol>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer1;