import React, { useContext, useState } from 'react';

import { 
    Button, 
    Menu, 
    MenuItem, 
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    FormControl,
    createStyles,
    makeStyles
} from '@material-ui/core';

//import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from "react-intl";

import items from "../../JSONS/DICT_flat_item.json";
import disp from "../../JSONS/DICT_disp.json";

import { hasKey, getKeyValue, RE, sorting, M } from "../../utils";

import { Context } from "../../components/Wrapper";

import {
    typeItems,
    typeDisp,
} from "../../interfaces";



const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiTypography-root": {
                fontSize: "13px",
            }
        }
    }),
);

interface IProps {
    item: string | null
    processed: string | null
    setProcessed: React.Dispatch<React.SetStateAction<string | null>>
    handleClick: (event: React.MouseEvent<HTMLButtonElement>, setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
    handleClose: (setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
}


export default function ProcessedSelect(props: IProps) {

    const classes = useStyles();

    const context = useContext(Context);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setState:  React.Dispatch<React.SetStateAction<string | null>>) => {
        if (event.target.value === "") {
            setState(null);
        } else {
            setState(event.target.value);
        }
    };

    function ButtonDisabled() {
        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => props.handleClick(e, setAnchorEl)}
                    disabled={true}
                    className="disabled"
                >
                    <FormattedMessage id="options.option.processed" defaultMessage="Processed"/>
                </Button>
            </div>
        );
    }


    let getProcessed: string[] | null = null;
    let getProcessedSorted: M;


    if (props.item === null) {
        return <ButtonDisabled/>
    } else if (hasKey(items, props.item) === false) {
        return <ButtonDisabled/>
    } else {
        getProcessed = getKeyValue<keyof typeItems, typeItems>(props.item)(items).processed;
    }
    
    if (getProcessed === null) {
        return <ButtonDisabled/>
    } else {
        getProcessedSorted = sorting(getProcessed, context.locale);
    }

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => props.handleClick(e, setAnchorEl)}>
                <p>
                    {(props.processed) ?
                        <span className="option">{(RE(context.locale)) ? 
                            <>{getKeyValue<keyof typeDisp, typeDisp>(props.processed)(disp).disp_es}</> :
                            <>{getKeyValue<keyof typeDisp, typeDisp>(props.processed)(disp).disp_en}</>
                        }</span>:
                        <FormattedMessage id="options.option.processed" defaultMessage="Processed"/>
                    }
                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => props.handleClose(setAnchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
            >
                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="gender" name="gender1" value={props.processed || ""} onChange={(e) => handleChange(e, props.setProcessed)}>
                        <MenuItem key={0} onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value={""}
                                control={<Radio />}
                                label={<FormattedMessage id="options.option.any" defaultMessage="Any"/>}
                            />
                        </MenuItem>
                        {Array.from(getProcessedSorted.keys()).map((key, index) => {
                            return (
                                <MenuItem key={index} onClick={() => props.handleClose(setAnchorEl)}>
                                    <FormControlLabel
                                        value={key}
                                        control={<Radio />}
                                        label={getProcessedSorted.get(key)} //label={value} //label={<FormattedMessage id={getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_es} defaultMessage={key}/>}
                                    />
                                </MenuItem>
                            );   
                        })}
                    </RadioGroup>
                </FormControl>
            </Menu>
        </div>
    );
}
