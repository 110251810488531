import React, { Component } from "react";
import { Route, NavLink } from "react-router-dom";
import ReactGA from 'react-ga';

import Answer1 from "./aso/answer_1";
import Answer2 from "./aso/answer_2";
import Answer3 from "./aso/answer_3";
import Answer4 from "./aso/answer_4";
import Answer5 from "./aso/answer_5";
import Answer6 from "./aso/answer_6";
 
class Aso extends Component {
  render() {
    return (
      <div className="body">
        
        {ReactGA.pageview(window.location.pathname)}
        
        <div className="questions">
            <h1>Asociación</h1>
            
            <div>
                <ul>
                  
                  <li><NavLink to="/aso/crear_el_perfil_de_grupo">¿Cómo crear el perfil de la asociación?</NavLink></li>
                  <li><NavLink to="/aso/invitar_a_nuevos_miembros">¿Cómo invitar a los asociados en Listo?</NavLink></li>
                  <li><NavLink to="/aso/enviar_difusiones_y_alertas_a_los_miembros">¿Cómo enviar difusiones a sus miembros?</NavLink></li>
                  <li><NavLink to="/aso/crear_un_pedido_grupal">¿Cómo crear pedidos?</NavLink></li>
                  <li><NavLink to="/aso/monitorear_el_pedido_grupal">¿Cómo monitorear un pedido grupal?</NavLink></li>
                  <li><NavLink to="/aso/participar_en_los_pedidos_de_otros_compradores">¿Cómo participar en una solicitud de comprador?</NavLink></li>
                </ul>
            </div>

            <Route path="/aso/crear_el_perfil_de_grupo" component={Answer1}/>
            <Route path="/aso/invitar_a_nuevos_miembros" component={Answer2}/>
            <Route path="/aso/enviar_difusiones_y_alertas_a_los_miembros" component={Answer3}/>
            <Route path="/aso/crear_un_pedido_grupal" component={Answer4}/>
            <Route path="/aso/monitorear_el_pedido_grupal" component={Answer5}/>
            <Route path="/aso/participar_en_los_pedidos_de_otros_compradores" component={Answer6}/>

        </div>
      </div>
    );
  }
}
 
export default Aso;