import React from "react";
import ReactGA from 'react-ga';

import CircularIntermediate from "../components/CircularIntermediate";

export default function Redirect() {

	return (
		<div className="container">
		{ReactGA.pageview(window.location.pathname)}
		<div className="body">
		
			<div className="redirect">
				<div className="circleContainer">
					<CircularIntermediate/>
				</div>
				<br/><br/>
				<p>Por favor espera mientras eres redireccionado...</p>
				<br/><br/><br/>
			</div>
		</div>
		</div>
	);
}