import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { FormattedMessage } from "react-intl";

import PostPreview from "../components/PostPreview";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { PostCarouselModelPublicWebInterface } from "../interfaces";

interface Props {
    postCarouselArray: PostCarouselModelPublicWebInterface[] | undefined
}

export default function SimpleSlider(props: Props) {
    
    let loadingArray = [1,2,3,4]

    let settings = {
        dots: true,
        infinite: false, //infinite: false,
        speed: 500,
        variableWidth: true,
        slidesToShow: 3, //3.6; as a function of width of parent element (ie slider)
        slidesToScroll: 3, //1 //3
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 690, //425
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    if (props.postCarouselArray === undefined) {
        return (
            <div className="sliderContainer">
            <div>
                <h2>
                    <FormattedMessage id="home.posts" defaultMessage="Posts"/>
                </h2>
                <h3>
                    <FormattedMessage id="home.buy_from" defaultMessage="Buy from local vendors"/>
                </h3>
                <Slider {...settings}>
                    {loadingArray.map( (i: number) => 
                        <div key={i}>
                            <PostPreview postCarousel={undefined}/>
                        </div>
                    )}
                </Slider>
            </div>
            </div>
        );
    }

    if (props.postCarouselArray.length < 3) {
        return null
    }

    return (
        <div className="sliderContainer">
        <div>
            <span className="top">
                <h2>
                    <FormattedMessage id="home.posts" defaultMessage="Posts"/>
                </h2>
                <NavLink to={`/publicaciones`}>
                    <h2 className="navLink">
                        <FormattedMessage id="home.see_more" defaultMessage="See more"/>
                    </h2>
                </NavLink>
            </span>
            <h3>
                <FormattedMessage id="home.buy_from" defaultMessage="Buy from local vendors"/>
            </h3>
            <Slider {...settings}>
                {props.postCarouselArray.map( (postCarousel: PostCarouselModelPublicWebInterface, i: number) => 
                    <div key={i}>
                        <NavLink to={`/publicacion?postId=${postCarousel.id}`}>
                            <PostPreview postCarousel={postCarousel}/>
                        </NavLink>
                    </div>)
                }
            </Slider>
        </div>
        </div>
    );
}
