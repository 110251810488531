import React, { useState } from 'react';

import {
    Button,
    Menu,
    MenuItem,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from "react-intl";

import { BuySearchSortOptions } from "../../interfaces";

const useStyles = makeStyles(() =>
    createStyles({
        root: { 
            fontSize: "13px",
            "& .MuiTypography-root": {
                fontSize: "13px",
            }
        }
    }),
);


interface IProps {
    sortBy: BuySearchSortOptions
    distanceMax: number | null
    setDistanceMax: React.Dispatch<React.SetStateAction<number | null>>
    handleClick: (event: React.MouseEvent<HTMLButtonElement>, setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
    handleClose: (setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
}


export default function Distance(props: IProps) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setState:  React.Dispatch<React.SetStateAction<number | null>>) => {
        if (event.target.value === "0") {
            setState(null);
        } else {
            setState(Number(event.target.value));
        }
    };

    if (props.sortBy === BuySearchSortOptions.sortByDistanceLowHigh) {
        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => props.handleClick(e, setAnchorEl)}
                    disabled={true}
                    className="disabled"
                >
                    <FormattedMessage id="options.option.distance" defaultMessage="Distance"/>
                    {/* <span> – </span>
                    <FormattedMessage id="options.option.any" defaultMessage="Any"/> */}
                </Button>
            </div>
        );
    }

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => props.handleClick(e, setAnchorEl)}>
                <p>
                    {/* <FormattedMessage id="options.option.distance" defaultMessage="Distance"/>
                    <span> – </span> */}
                    {(props.distanceMax) ?
                        <span className="option">{props.distanceMax} km</span>:
                        <FormattedMessage id="options.option.any" defaultMessage="Any"/>}
                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => props.handleClose(setAnchorEl)} //onClose={handleCloseDistanceMax}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                PaperProps={{
                    style: {
                        maxHeight: 300
                    }
                }}
            >
                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="gender" name="gender1" value={props.distanceMax || 0} onChange={(e) => handleChange(e, props.setDistanceMax)}>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={1} control={<Radio />} label="1 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={3} control={<Radio />} label="3 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={5} control={<Radio />} label="5 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={7} control={<Radio />} label="7 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={10} control={<Radio />} label="10 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={20} control={<Radio />} label="20 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={30} control={<Radio />} label="30 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={50} control={<Radio />} label="50 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={100} control={<Radio />} label="100 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={200} control={<Radio />} label="200 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={300} control={<Radio />} label="300 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={400} control={<Radio />} label="400 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={500} control={<Radio />} label="500 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel value={1000} control={<Radio />} label="1000 km" />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value={0}
                                control={<Radio />}
                                label={<FormattedMessage id="options.option.any" defaultMessage="Any"/>} //label="any"
                            />
                        </MenuItem>
                    </RadioGroup>
                </FormControl>
            </Menu>
        </div>
    );
}
