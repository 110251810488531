import React, { Component } from "react";
import ReactPlayer from 'react-player/youtube';
 
class Answer2 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            <br/>
            

            <h1 id="para-agricultores-cómo-buscar-pedidos-y-contribuir">Para agricultores, ¿cómo buscar pedidos y contribuir?</h1>
            
            
            <p>Uno de los mecanismos de comercialización de Listo es la búsqueda de pedidos creados por compradores o asociaciones. Al buscar estos pedidos usted podrá solicitar participar en ellos, para así vender sus productos. Los pedidos de las asociaciones o de los compradores pueden ser grupales o individuales.</p>
            <br/>

            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=-yAxO0rR2D0&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>
            
            <h3 id="la-búsqueda-de-pedidos-solo-puede-realizarse-a-través-de-la-aplicación">La búsqueda de pedidos solo puede realizarse a través de la aplicación.</h3>

            <ol>
              <li>
                En la página de inicio, diríjase a la sección <strong>Compra</strong> y <strong>oprima el botón Buscar</strong> ubicado justo al lado del nombre de la sección.
              </li>
              <li>Se desplegará la lista de productos y podrá <strong>seleccionar el que desea vender</strong>.
                <blockquote>
                  Si hay pedidos activos de ese producto, los resultados aparecerán en una lista y también podrá verlos en el mapa para conocer la ubicación desde la cual un usuario está realizando el pedido.
                </blockquote>
              </li>
              <li>
                Si encuentra un pedido que sea de su interés, <strong>lea los requisitos</strong> del usuario, tales como <strong>volumen</strong>; <strong>precio total</strong> y <strong>precio unitario</strong>; <strong>método de entrega</strong> y <strong>ubicación de entrega</strong>; entre otros.
              </li>
              <li>Para participar de en un pedido, verifique que <strong>cumpla con los requisitos</strong> anteriormente mencionados, al final de la pantalla verá <strong>el botón Contribuir</strong>, <strong>haga clic</strong> sobre este si desea enviar una solicitud de participación.
                <blockquote>
                  <p>Si es un pedido individual, usted debe contribuir con la cantidad total pedida por el comprador, puede cambiar el precio unitario, ingresar la fecha de intercambio, observar el método de entrega y establecer una tarifa de entrega.<br />
            Por el contrario, si es un pedido grupal, usted podrá ingresar el volumen con el que puede contribuir (tenga en cuenta que debe ser igual o mayor al volumen de oferta mínimo establecido por el usuario), cambiar el precio unitario, ingresar la fecha de intercambio, observar el método de entrega y establecer una tarifa de entrega.</p>
                </blockquote>
              </li>
              <li>
                <strong>Agregue una descripción</strong> (con un máximo de 1000 caracteres) y también <strong>hasta 6 fotos del producto</strong> que está ofreciendo.
              </li>
              <li><strong>Oprima el botón Ver Resumen</strong> y verifique que toda la información anteriormente ingresada sea correcta.
                <blockquote>
                  <p>Si desea cambiar algo oprima el botón Editar.</p>
                </blockquote>
              </li>
              <li>Si todo está bien <strong>oprima el botón Enviar</strong> y seguidamente <strong>el botón Confirmar</strong>.</li>
            </ol>

            <br/>

        </div>


      </div>
    );
  }
}
 
export default Answer2;