import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer3 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-agricultores-cómo-editar-antes-del-intercambio">Para agricultores, ¿cómo editar antes del intercambio?</h1>
            
            <p>En Listo es posible editar la información de una publicación antes de realizar el intercambio.</p>

            <blockquote>
              <p>Por ejemplo, en caso de que el volumen acordado sea menor al momento del intercambio, puede comunicarse con el comprador o el administrador de la asociación e informarle de este cambio, esto con el objetivo de llevar el registro de todos los eventos relacionados con esa transacción.</p>
            </blockquote>

            <br/>
            <ul>
              <li><Link to="#cómo-hacer-una-edición">Cómo hacer una edición</Link></li>
              <li><Link to="#cómo-aceptar-o-rechazar-una-edición">Cómo aceptar o rechazar una edición</Link></li>
            </ul>
            

            <br/>

            <h2 id="cómo-hacer-una-edición">Cómo hacer una edición</h2>
            <blockquote>
              <p>Las ediciones solo pueden realizarse desde la aplicación. Una vez ha aceptado una oferta de un comprador a su publicación, o después de que su solicitud de contribución a un pedido sea aceptada, puede hacer cambios en la misma.</p>
            </blockquote>

            <ol>
              <li>
                <strong>Acceda a la oferta o la solicitud</strong>, en la ventana de la misma diríjase a la parte inferior de la pantalla, cuando alcance el final haga clic en <strong>el botón Editar</strong>.
              </li>
              <li>Esto lo llevará a <strong>la ventana Editar oferta</strong>, aquí verá información relacionada con la oferta o la solicitud.
                <blockquote>
                  <p>Cabe resaltar que antes de realizar cualquier cambio a una oferta o solicitud, le recomendamos que se comunique con el otro usuario para informarle previamente de los cambios que quiere realizar.</p>
                </blockquote>
              </li>
              <li>
                Usted puede <strong>cambiar el volumen, el precio unitario o precio total, la fecha de intercambio, el método de entrega y la tarifa de entrega</strong>.
              </li>
              <li>
                Realice los cambios que desee y oprima <strong>el botón Siguiente</strong>.
              </li>
              <li>
                En el resumen de la edición, verá una tabla donde aparece la oferta previa, si mueve esta tabla hacia la izquierda verá la nueva oferta.
              </li>
              <li>Verifique que todo esté bien y <strong>oprima el botón Enviar</strong> y seguidamente <strong>el botón Confirmar</strong>.</li>
            </ol>

            <p><strong>Ahora simplemente debe esperar a que el otro usuario acepte la nueva edición.</strong></p>

            <br/>
            <br/>

            <h2 id="cómo-aceptar-o-rechazar-una-edición">Cómo aceptar o rechazar una edición</h2>

            <br/>

            <h3 id="opción-1-a-través-del-menú-de-mensajes">Opción 1. A través del menú de mensajes</h3>
            <ol>
              <li>
                Recibirá un mensaje de texto que le informará que ha recibido <strong>una solicitud de edición</strong>.
              </li>
              <li>Diríjase al <strong>servicio de mensajes</strong> y <strong>lea la información</strong> recibida.
                <blockquote>
                  <p>Verá los detalles de la edición como: volumen, precio, tarifa de entrega y fecha de entrega.</p>
                </blockquote>
              </li>
              <li>
                Siga <strong>las instrucciones para aceptar o rechazar</strong> la edición.
              </li>
              <li>Responda <strong>LT editar</strong> seguido de <strong>los tres números</strong> que indique el mensaje para realizar la acción que desee.
                <blockquote>
                  <p>Por ejemplo, para <strong>aceptar</strong> responda <strong>LT editar 123</strong>, para <strong>rechazar</strong> responda <strong>LT editar 456</strong>.</p>
                </blockquote>
              </li>
            </ol>

            <br/>
            <h3 id="opción-2-a-través-de-la-aplicación">Opción 2. A través de la aplicación</h3>
            <ol>
              <li>
                Diríjase a <strong>la campana de notificaciones</strong> ubicada en <strong>la página de inicio</strong>.
              </li>
              <li>
                Allí verá <strong>la notificación</strong> que le ha sido enviada, <strong>haga clic</strong> sobre ella.
              </li>
              <li>
                Abrirá la ventana de edición y podrá <strong>ver los cambios</strong> que la otra parte ha sugerido.
              </li>
              <li>
                Al final de la pantalla encontrará <strong>los botones Aceptar</strong> y <strong>Rechazar</strong>.
              </li>
              <li>
                <strong>Haga clic sobre el botón</strong> que corresponda a la acción que desea realizar.
              </li>
            </ol>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer3;