import React, { Component } from "react";
 
class Answer6 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-compradores-cómo-enviar-ofertas">Para compradores, ¿cómo enviar ofertas?</h1>
            
            <p>Cuando haya encontrado una publicación que sea de su interés, podrá ofertar por el producto publicado.</p>

            <ol>
              <li>
                <strong>Haga clic sobre la publicación</strong>, una nueva ventana se abrirá y podrá ver los detalles de la publicación.
              </li>
              <li>
                <strong>Oprima el botón Hacer Oferta</strong> que encontrará al final de la pantalla.
              </li>
              <li>
                Abrirá una ventana con el mismo nombre, donde podrá <strong>ingresar los detalles de su oferta</strong>, como: <strong>precio total o precio unitario</strong>; <strong>fecha de intercambio</strong>; <strong>método de entrega</strong>; <strong>lugar de entrega</strong>; <strong>tarifa de entrega</strong> si quiere incluir el costo del envío.
              </li>
              <li>
                <strong>Oprima el botón Ver resumen</strong> para ver los detalles de la oferta que va a enviar.
              </li>
              <li>
                Si todo es correcto <strong>oprima el botón Enviar oferta</strong> y seguidamente <strong>el botón Confirmar</strong>.
                <blockquote>
                  <p>Si desea hacer algún cambio <strong>oprima el botón Editar</strong>.</p>
                </blockquote>
              </li>
            </ol>

            <p>Su oferta será enviada después de esto y ahora solo le queda esperar la respuesta del otro usuario.</p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer6;