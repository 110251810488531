import React, { useContext, useState, useEffect } from "react";

import Slider from "./Slider";
import { Context } from "./Wrapper";
import functions from "../firebase";

import {
    CloudResultPostCarousel,
    PostCarouselModelPublicWebInterface
} from '../interfaces';


interface coords {
    latitude: number | null
    longitude: number | null
}

interface GeolocationCoordinates {
    accuracy: number
    altitude: number
    altitudeAccuracy: null | number
    heading: null | number
    latitude: number
    longitude: number
    speed: null | number
}

interface Position {
    coords: GeolocationCoordinates
}


let CF_WEB_U_spatialPosts_onCall = functions.httpsCallable('CF_WEB_U_spatialPosts_onCall');

let PostCarouselArray:PostCarouselModelPublicWebInterface[]; // let init:PostCarouselModelPublicWebInterface[];


const PostsCarousel: React.FC = () => {

    const context = useContext(Context);
    const [postCarouselArray, setPostCarouselArray] = useState(PostCarouselArray);
    const [navCoords, setNavCoords] = useState({latitude: null, longitude: null});
    const [permissionSafari, setPermissionSafari] = useState(false);
    
    /*let options = {
        enableHighAccuracy: true,   // true; // enableHighAccuracy: false | true
        timeout: 5000,              // 5000; // integer (milliseconds) - amount of time before the error callback is invoked, if 0 it will never invoke.
        maximumAge: 0               // 0 // integer (milliseconds) | infinity - maximum cached position age.
    }*/

    function getCurrentPosition(options = {timeout: 5000}): Promise<Position> | any {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    const fetchCarousel = async (coords: coords) => {
        //console.log('fetchCarousel')
        //console.log(coords.latitude, coords.longitude)
        //let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
        try {
            let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
            setPostCarouselArray(result.data.response);
        } catch (error) {
            //console.log(result.data.errorKey)
            console.log(error)
        }
    }

    const fetchCoordinates = async () => {
        try {
            const { coords } = await getCurrentPosition();
            setNavCoords(coords);
            setPermissionSafari(true);
        } catch (error) {
            console.error(`Error code: ${error.code}; error msg: ${error.message}`)
        }
    };

    const methodThree = async () => {

        let permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
    
        if (permissionStatus.state === "granted") {
            
            //console.log("granted!")
            
            try {
                
                const { coords } = await getCurrentPosition(); //console.log(coords.latitude, coords.longitude);

                try {
                    let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
                    setPostCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error)
                }
            } catch {
                
                console.log("error w/ getCurrentPosition()")

                try {
                    let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                    setPostCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error)
                }
            }
        } else {
            //console.log("notGranted!")

            try {
                let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                setPostCarouselArray(result.data.response);
            } catch (error) {
                console.log(error)
            }

            try {
                const { coords } = await getCurrentPosition(); console.log("interpromt!");

                try {
                    //console.log("setting after interprompt")
                    let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
                    //console.log(result.data.response)
                    setPostCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error) //console.log(result.data.errorKey)
                }
            } catch {
                console.log("error w/ getCurrentPosition()")
            }
        }

        permissionStatus.onchange = async e => {
            //console.log("changed")
            setPostCarouselArray(PostCarouselArray);
            if (permissionStatus.state === "granted") {
                //console.log("grantedOnChange!")
                try {
                    const { coords } = await getCurrentPosition();
                    //console.log(coords.latitude, coords.longitude)
                    try {
                        let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
                        setPostCarouselArray(result.data.response);
                    } catch (error) {
                        console.log(error)
                    }
                } catch {
                    //console.log("error w/ getCurrentPosition()")
                    try {
                        let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                        setPostCarouselArray(result.data.response);
                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                //console.log("notGranted!")
                try {
                    let result: CloudResultPostCarousel = await CF_WEB_U_spatialPosts_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                    setPostCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error)
                }
            }
        }
        
    }
    
    useEffect(() => {
        try {
            //Chrome & firefox
            navigator.permissions.query({ name: 'geolocation' })
            methodThree();
        } catch {
            //Safari and others
            console.log("catch");
            fetchCarousel(navCoords);
            fetchCoordinates();
        }
    }, [permissionSafari]); // eslint-disable-line react-hooks/exhaustive-deps
    

    return <Slider postCarouselArray={postCarouselArray}/>
}

export default PostsCarousel;
