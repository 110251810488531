import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import English from "../languages/en.json";
import Spanish from "../languages/es.json";


interface IContextProps {
    locale: string;
    selectLang: ({type}:{type:string}) => void;
}

interface T {
    [key: string]: T | string
}


export const Context  = React.createContext({} as IContextProps);

const navLang = navigator.language;

let lang: any;
if (navLang.split("-")[0] === "es") {
    lang = Spanish;
} else {
    lang = English;
}

const Wrapper = (props: any) => {
    const [locale, setLocale] = useState(navLang);
    const [messages, setMessages] = useState(lang);

    //change any; check types
    //function selectLang(e: React.SyntheticEvent) {
    function selectLang(e: any) {
        //const element = e.target as HTMLInputElement
        //const newLocale = element.value;
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === "en") {
            setMessages(English);
        } else {
            setMessages(Spanish);
        }
    }

    return (
        <Context.Provider value={{ locale, selectLang }}>
            <IntlProvider messages={flattenMessages(messages) as Record<string, string>} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}

export default Wrapper;


function flattenMessages(nestedMessages: T, prefix: string = '') {
    return Object.keys(nestedMessages).reduce((messages: T, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
}