import React, { Component, useState } from "react";
import { Route, NavLink } from "react-router-dom";
import { Switch } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import  { Breakpoint, BreakpointProvider } from 'react-socks';
import { FormattedMessage } from "react-intl";

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';

import Home from "./Home";
import Registro from "./Registro";
import Agricultor from "./Agricultor";
import Comprador from "./Comprador";
import Aso from "./Aso";
import Audios from "./Audios";
import Politica from "./Politica";
import Terminos_y_Condiciones from "./Terminos-y-Condiciones";

import Posts from "./Posts";
import Orders from "./Orders";

import PostSearch from "./PostSearch";
import OrderSearch from "./OrderSearch";
import Test from "./Test";

import Buscar from "./Static";

import Descargar from "./Descargar";
import AboutUs from "./AboutUs";
import NoMatch from "./NoMatch";

import SimpleMenu from "../components/SimpleMenu";
import Footer from "../components/footer";
import appicon from"../images/appicon.png";


class Main extends Component {

  render() {
    return (

        <div className="container">

                <NavLink exact to="/">
                    <div className="logo">
                        <div className="appIconShadow">
                            <svg width="0" height="0">
                                <defs>
                                    <clipPath id="shape">
                                            <path id="shape" className="cls-1" d="M6181.23,233.709v-1.792c0-.5-0.02-1-0.02-1.523a24.257,24.257,0,0,0-.28-3.3,11.207,11.207,0,0,0-1.04-3.132,10.683,10.683,0,0,0-1.95-2.679,10.384,10.384,0,0,0-2.68-1.943,10.806,10.806,0,0,0-3.13-1.038,19.588,19.588,0,0,0-3.3-.285c-0.5-.017-1-0.017-1.52-0.017h-22.39c-0.51,0-1.01.017-1.53,0.017a24.041,24.041,0,0,0-3.3.285,11.009,11.009,0,0,0-3.13,1.038,10.491,10.491,0,0,0-4.62,4.622,10.893,10.893,0,0,0-1.04,3.132,19.2,19.2,0,0,0-.28,3.3c-0.02.5-.02,1-0.02,1.523v22.392c0,0.5.02,1,.02,1.524a24.257,24.257,0,0,0,.28,3.3,10.9,10.9,0,0,0,1.04,3.132,10.491,10.491,0,0,0,4.62,4.622,11.04,11.04,0,0,0,3.13,1.038,19.891,19.891,0,0,0,3.3.285c0.51,0.017,1.01.017,1.53,0.017h22.39c0.5,0,1-.017,1.52-0.017a24.221,24.221,0,0,0,3.3-.285,10.836,10.836,0,0,0,3.13-1.038,10.408,10.408,0,0,0,2.68-1.943,10.683,10.683,0,0,0,1.95-2.679,11.217,11.217,0,0,0,1.04-3.132,20.257,20.257,0,0,0,.28-3.3c0.02-.5.02-1,0.02-1.524v-20.6h0Z" transform="translate(-6131 -218)"></path>
                                    </clipPath>
                                </defs>
                            </svg>
                            <img className="headerIcon" src={appicon} alt=""/>
                        </div>
                        <p className="headerName">Listo</p>
                    </div>
                </NavLink>

                <nav>
                <BreakpointProvider>
                    <Breakpoint customQuery="(min-width: 768px)">

                            <div className="nav">
                                <NavLink to="/publicaciones">
                                    <FormattedMessage id="nav.buy" defaultMessage="Buy"/>
                                </NavLink>
                                <NavLink to="/pedidos">
                                    <FormattedMessage id="nav.sell" defaultMessage="Sell"/>
                                </NavLink>
                                <NavLink to="/explorar">
                                    <FormattedMessage id="nav.explore" defaultMessage="Explore"/>
                                </NavLink>
                                
                                <SimpleMenu/>
                                
                                <NavLink to="/equipo">
                                    <FormattedMessage id="nav.team" defaultMessage="Team"/>
                                </NavLink>
                                <Link to="/#faq">
                                    <FormattedMessage id="nav.help" defaultMessage="Help"/>
                                </Link>
                            </div>
                        
                            {/* <ul>
                                <li><NavLink to="/publicaciones">Comprar</NavLink></li>
                                <li><NavLink to="/pedidos">Vender</NavLink></li>
                                <li><NavLink to="/explorar">Explorar</NavLink></li>
                                <li><SimpleMenu/></li>
                                <li><NavLink to="/equipo">Equipo</NavLink></li>
                                <li><Link to="/#faq">Ayuda</Link></li>
                            </ul> */}
                        
                    </Breakpoint>
                    <Breakpoint customQuery="(max-width: 767px)">
                        <div className="mobileNav">
                            <TemporaryDrawer/>
                        </div>
                    </Breakpoint>
                </BreakpointProvider>
                </nav>

                <Switch>

                    <Route exact path="/" component={Home}/>

                    <Route path="/registro" component={Registro}/>
                    <Route path="/agricultor" component={Agricultor}/>
                    <Route path="/comprador" component={Comprador}/>
                    <Route path="/aso" component={Aso}/>
                    <Route path="/audios" component={Audios}/>
                    <Route path="/privacidad" component={Politica}/>
                    <Route path="/terminos-y-condiciones" component={Terminos_y_Condiciones}/>
                    
                    <Route path="/privacy" component={Politica}/>

                    <Route path="/publicacion" component={Posts}/>
                    <Route path="/pedido" component={Orders}/>

                    <Route path="/publicaciones" component={PostSearch}/>
                    <Route path="/pedidos" component={OrderSearch}/>

                    <Route path="/explorar" component={Buscar}/>

                    <Route path="/descargar" component={Descargar}/>
                    <Route path="/equipo" component={AboutUs}/>

                    <Route path="/123test123" component={Test}/>

                    <Route component={NoMatch}/>

                </Switch>
                
            <div className="footer">                
                <Footer></Footer>
            </div>

        </div>
    );
  }
}

export default Main;


const useStyles = makeStyles({
    list: {
        width: 250,
        '& a': {
            color: '#666'
        },
        '& a.active li.MuiListItem-root': {
            color: 'black'
        }
    },
});

function TemporaryDrawer() {
    const classes = useStyles();

    const [state, setState] = useState(false);
    
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent,) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
    
        setState(open);
    };
    
    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <NavLink to="/publicaciones">
                    <ListItem>
                        <FormattedMessage id="nav.buy" defaultMessage="Buy"/>
                    </ListItem>
                </NavLink>
                <NavLink to="/pedidos">
                    <ListItem>
                        <FormattedMessage id="nav.sell" defaultMessage="Sell"/>
                    </ListItem>
                </NavLink>
                <NavLink to="/explorar">
                    <ListItem>
                        <FormattedMessage id="nav.explore" defaultMessage="Explore"/>
                    </ListItem>
                </NavLink>
            </List>
            <Divider />
            <List>
                <NavLink to="/registro">
                    <ListItem>
                        Registro
                    </ListItem>
                </NavLink>
                <NavLink to="/agricultor">
                    <ListItem>
                        Soy agricultor
                    </ListItem>
                </NavLink>
                <NavLink to="/comprador">
                    <ListItem>
                        Soy comprador
                    </ListItem>
                </NavLink>
                <NavLink to="/aso">
                    <ListItem>
                        Asociación
                    </ListItem>
                </NavLink>
                <NavLink to="/audios">
                    <ListItem>
                        Audios
                    </ListItem>
                </NavLink>
            </List>
            <Divider />
            <List>
                <NavLink to="/equipo">
                    <ListItem>
                        <FormattedMessage id="nav.team" defaultMessage="Team"/>
                    </ListItem>
                </NavLink>
                <Link to="/#faq">
                    <ListItem>
                        <FormattedMessage id="nav.help" defaultMessage="Help"/>
                    </ListItem>
                </Link>
            </List>
        </div>
        );
    
        return (
        <div>
            <React.Fragment key={'right'}>
                <button onClick={toggleDrawer(true)}>
                    <MenuIcon/>
                </button>
                <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                    {list()}
                </Drawer>
            </React.Fragment>
        </div>
    );
}