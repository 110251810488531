import React from 'react';
import ReactGA from 'react-ga';

export default function Politica() {

  return (
    <div className="body">

    {ReactGA.pageview(window.location.pathname)}
    
    {/*<section>*/}
    <div className="answers politica">

    <p>(English version below.)</p>

<h1 id="política-de-privacidad-de-nuestra-empresa-español">Política de Privacidad de Nuestra Empresa (Español)</h1>

<p><strong>LISTO PLATFORM LIMITED</strong> identificada con: <strong>14542525</strong> y domiciliada en el Reino Unido, es una sociedad que tiene como objeto social principal el mejoramiento del acceso al mercado y del proceso de toma de decisiones en la cadena de suministro para agricultores y compradores. Las principales actividades de la compañía incluyen: (i) Participar en actividades para promover el uso de plataformas digitales, corno por ejemplo y sin limitarse a entrenamiento y publicidad. (ii) Desarrollo de tecnologías de software para conectar vendedores, compradores y transportadores en la cadena de suministro agrícola y en la economía rural. Para efectos del presente documento se denominará como “EL RESPONSABLE DEL TRATAMIENTO”</p>

<p>En cumplimiento de la legislación vigente en materia de Protección de Datos Personales en Colombia presente en la Ley 1581 de 2012 de Protección de Datos Personales Reglamentada parcialmente por el Decreto 1377 de 2013 y Decreto 886 de 2014, normas compiladas en el Decreto Único Nacional del Sector Comercio, Industria y Turismo 1074 de 2015 (Artículos 2.2.2.25.1.1. al 2.2.2.26.3.4) LISTO PLATFORM, reglamenta el manejo de los datos personales de sus usuarios, clientes, contactos web, proveedores,  contratistas y cualquier otra persona natural,  a los cuales se le da tratamiento.</p>

<p>Al entregar cualquier tipo de información personal, el titular ha aceptado que dicha información sea utilizada de acuerdo con la presente Política de Privacidad, información que no será utilizada en ningún momento para propósitos diferentes a los aquí establecidos. En el evento que dicha información personal vaya a ser utilizada para otros propósitos, ésta deberá estar amparada dentro de las excepciones que prevé la normatividad, o deberá contar con una autorización expresa de su titular, según sea el caso.</p>

<p>La presente Política se efectúa con el propósito de dar cumplimiento a lo exigido en la ley Colombiana Ley 1581 de 2012 y proteger el derecho constitucional del Habeas Data que tienen todas las personas naturales para conocer, actualizar y rectificar la información personal almacenada, y  que se le da tratamiento en por parte de LISTO PLATFORM, los demás derechos, libertades y garantías constitucionales que surgen, en cabeza del titular del dato que se desprenden del tratamiento de sus datos personales.</p>

<p>El usuario que se conecte, acceda o utilice la aplicación y/o plataforma web LISTO reconoce que  está celebrando un contrato legal, vinculante y exigible con LISTO PLATFORM, quien está comprometido con proteger sus datos personales. El usuario que usa y disfruta de LISTO debe conocer y aceptar las presentes Políticas de Privacidad (“Políticas de Privacidad”).</p>

<p><strong>LISTO PLATFORM</strong> creó la aplicación Listo como una aplicación Gratuita. Este servicio es proporcionado por LISTO PLATFORM sin costo y está diseñado para su uso tal como está.</p>

<p>Esta política de privacidad explicará cómo nuestra Empresa utiliza los datos personales que recopilamos de usted cuando utiliza nuestros servicios y plataforma.</p>

<h2 id="temas">Temas:</h2>

<ul>
  <li>¿Qué datos recopilamos?</li>
  <li>¿Cómo recopilamos sus datos?</li>
  <li>¿Cómo y por qué usaremos sus datos?</li>
  <li>¿Cómo almacenamos sus datos?</li>
  <li>Marketing</li>
  <li>¿Cuáles son sus derechos de protección de datos?</li>
  <li>Políticas de privacidad de otros sitios web</li>
  <li>Cambios en nuestra política de privacidad</li>
  <li>Cómo contactarnos</li>
</ul>

<h2 id="qué-datos-recopilamos">¿Qué datos recopilamos?</h2>

<p>Recopilamos sus datos personales cuando utiliza nuestros Servicios, crea una nueva cuenta de Listo, nos proporciona información a través de un formulario web, agrega o actualiza información en su cuenta de Listo, participa en discusiones de la comunidad en línea o interactúa con nosotros.</p>

<p>Los datos personales que proporciona al utilizar nuestros Servicios o al crear una cuenta de Listo:</p>
<ul>
  <li>Datos que lo identifican, como su nombre, su foto de perfil, dirección, números de teléfono o direcciones de correo electrónico, género, su nombre de usuario, su número de identificación que proporciona al configurar su cuenta en Listo o en una fecha posterior, y datos de identificación adicionales y números de identificación fiscal que proporciona al usar nuestros servicios de pago.</li>
  <li>Datos relativos a las ofertas, compras o ventas que proporciona en una transacción, como los artículos que se venderán o comprarán, los importes de las transacciones, la hora y la ubicación de las transacciones y los métodos de pago.</li>
  <li>Contenido que comparte con otros usuarios a través de nuestras herramientas de mensajería, como la difusión y la mensajería en la aplicación.</li>
  <li>Información financiera en relación con una transacción.</li>
  <li>Envío, facturación y otra información que proporcione en relación con la compra o el envío de un artículo, así como información de envío relevante (tales como números de envío e información de seguimiento) si se envía a través de programas de logística.</li>
  <li>Datos relativos a todas las demás interacciones con nuestros Servicios, sus preferencias publicitarias y su comunicación con nosotros.</li>
  <li>Datos de ubicación, incluidos los datos de ubicación de su dispositivo móvil. Tenga en cuenta que para la mayoría de los dispositivos móviles, puede administrar o deshabilitar el uso de los servicios de localización para todas las aplicaciones en el menú de configuración de su dispositivo móvil.</li>
</ul>

<h2 id="cómo-recopilamos-sus-datos">¿Cómo recopilamos sus datos?</h2>

<p>Recopilamos y procesamos datos cuando usted:</p>
<ul>
  <li>Se registra en línea para crear una cuenta en Listo.</li>
  <li>Completa voluntariamente una encuesta al cliente o proporciona comentarios sobre cualquiera de nuestros tableros de mensajes o por correo electrónico.</li>
  <li>Utiliza o ve nuestro sitio web a través de las cookies de su navegador.</li>
  <li>Utiliza nuestros Servicios o la plataforma Listo como parte de sus transacciones o actividades de búsqueda.</li>
</ul>

<h2 id="cómo-y-por-qué-usaremos-sus-datos">¿Cómo y por qué usaremos sus datos?</h2>

<h3 id="nuestra-empresa-recopila-sus-datos-para-que-podamos-proporcionarle-nuestros-servicios">Nuestra Empresa recopila sus datos para que podamos proporcionarle nuestros Servicios.</h3>

<p>Esto incluye:</p>
<ul>
  <li>Administrar su cuenta de Listo.</li>
  <li>Procesar su transacción, incluidas, entre otras, habilitar y realizar transacciones con otros usuarios (incluida la transmisión de sus datos personales a otros usuarios cuando sea necesario para realizar la transacción, incluso en casos de transacciones terminadas, fallidas o anuladas posteriormente), proporcionar y mejorar funciones como el procesamiento de pagos, calificaciones y proporcionar otros servicios que pueda usar (como se describe en relación con dichos servicios), y asegurar la funcionalidad de nuestros Servicios.</li>
  <li>Ponerse en contacto con usted con ofertas especiales sobre otros productos y servicios que creemos que le pueden interesar, de acuerdo con las preferencias de comunicación en su cuenta de Listo.</li>
  <li>Proporcionarle orientación y apoyo al usar Listo.</li>
  <li>Llevar a cabo un análisis de datos agregados de la comercialización a través de la plataforma (por ejemplo, precios promedio en una determinada ubicación y hora) y devolver estos resultados agregados a los usuarios de Listo. Estos análisis de datos no revelarán su información personal.</li>
  <li>Procesamiento de datos generales de ubicación (como la dirección IP, coordenadas o el código postal) para proporcionarle servicios basados en la ubicación (como el área de búsqueda y otro contenido que se personalice en función de sus datos generales de ubicación).</li>
  <li>Permitir la entrega de artículos comprados por proveedores de servicios de logística/entrega, incluidas las notificaciones relacionadas con la entrega (como información de seguimiento), esta última en la medida en que lo permita la ley aplicable sin su consentimiento.</li>
</ul>

<p><strong>En caso necesario, transmitimos sus datos personales a los procesadores y a los siguientes destinatarios para uno o varios de los fines descritos anteriormente:</strong></p>
<ul>
  <li>Proveedores de servicios externos, organizaciones asociadas (por ejemplo, el Ministerio de Agricultura) y empresas de entrega.</li>
  <li>Proveedores de servicios de pago (incluidos, entre otros, MercadoPago).</li>
</ul>

<p><strong>Cuando procesamos su pedido, sus datos pueden ser enviados a, y también utilizar la información resultante de, agencias de referencia de crédito, para evitar compras fraudulentas.</strong></p>

<h3 id="tratamos-sus-datos-personales-con-el-fin-de-cumplir-con-las-obligaciones-legales-a-las-que-estamos-sujetos">Tratamos sus datos personales con el fin de cumplir con las obligaciones legales a las que estamos sujetos.</h3>
<p>Esto incluye los siguientes propósitos:</p>
<ul>
  <li>Participación en procedimientos (incluidos los procedimientos judiciales) llevados a cabo por autoridades públicas u organismos gubernamentales, en particular, con el fin de detectar, investigar y enjuiciar actos ilegales.</li>
  <li>Prevención, detección y mitigación de actividades ilegales (por ejemplo, fraude, lavado de dinero y financiación del terrorismo).</li>
  <li>Garantizar la seguridad de la información de nuestros Servicios.</li>
  <li>Retención y almacenamiento de sus datos personales para cumplir con los requisitos legales de retención específicos (para obtener más información sobre el almacenamiento de sus datos por parte de Listo, consulte ¿Cómo almacenamos sus datos?).</li>
</ul>

<p><strong>En caso necesario, transmitimos sus datos personales a los procesadores y a los siguientes destinatarios para uno o varios de los fines descritos anteriormente:</strong></p>
<ul>
  <li>Organismos del orden público, tribunales, organismos gubernamentales o autoridades públicas, organismos intergubernamentales o supranacionales.</li>
  <li>Proveedores de servicios de terceros.</li>
  <li>Terceros que están involucrados en procedimientos judiciales, en particular, si nos presentan una orden legal, orden judicial u orden legal equivalente.</li>
  <li>Agencias de crédito, oficinas o asociaciones, si así lo exige la ley aplicable (por ejemplo, información sobre retrasos en los pagos, incumplimientos en los pagos u otras irregularidades que puedan ser relevantes para su informe crediticio).</li>
</ul>

<h2 id="cómo-almacenamos-sus-datos">¿Cómo almacenamos sus datos?</h2>

<p>Sus datos personales serán almacenados de forma segura por nosotros y nuestros proveedores de servicios de acuerdo con las leyes de protección de datos aplicables. Eliminaremos sus datos personales o tomaremos medidas para que los datos sean correctamente anónimos, a menos que estemos legalmente obligados a conservar sus datos personales por más tiempo (por ejemplo, con fines fiscales, contables o de auditoría). En Europa, los períodos de retención suelen oscilar entre 6 y 10 años.</p>

<p>En la medida en que sea legalmente permitido o requerido, restringimos el procesamiento de sus datos en lugar de eliminarlos (por ejemplo, restringiendo el acceso a ellos). Esto se aplica en particular a los casos en los que aún necesitemos los datos para la ejecución del contrato o para la afirmación o defensa contra reclamos legales. En estos casos, la duración de la restricción del tratamiento depende de los respectivos períodos de limitación o retención legales. Los datos se eliminarán después de que hayan expirado los períodos de limitación o retención pertinentes.</p>

<h2 id="marketing">Marketing</h2>

<p>Nuestra empresa desea enviarle información sobre nuestros productos y servicios que creemos que le pueden interesar.</p>

<p>Si ha aceptado recibir información sobre marketing, siempre puede optar por no recibirla en una fecha posterior.</p>

<p>Usted tiene el derecho en cualquier momento de impedir que Nuestra Empresa se ponga en contacto con usted con fines de marketing o ceder sus datos a otras organizaciones asociadas.</p>

<p>Si ya no desea ser contactado con fines de marketing, los correos electrónicos de marketing incluirán un enlace para cancelar la suscripción.</p>

<h2 id="cuáles-son-sus-derechos-de-protección-de-datos">¿Cuáles son sus derechos de protección de datos?</h2>

<p>Nuestra Empresa desea asegurarse de que usted es plenamente consciente de todos sus derechos de protección de datos. Cada usuario tiene derecho a lo siguiente:</p>

<ul>
  <li>
    <p><strong>El derecho de acceso</strong> - Usted tiene derecho a solicitar a Nuestra Empresa copias de sus datos personales. Podemos cobrarle una pequeña tarifa por este servicio.</p>
  </li>
  <li>
    <p><strong>El derecho de rectificación</strong> - Usted tiene el derecho de solicitar que Nuestra Empresa corrija cualquier información que considere incorrecta. También tiene derecho a solicitar a Nuestra Empresa que complete la información que cree que está incompleta. 
El derecho de borrado - Usted tiene el derecho de solicitar que Nuestra Empresa borre sus datos personales, bajo ciertas condiciones.</p>
  </li>
  <li>
    <p><strong>El derecho a restringir el procesamiento</strong> - Usted tiene el derecho de solicitar que Nuestra Empresa restrinja el procesamiento de sus datos personales, bajo ciertas condiciones.</p>
  </li>
  <li>
    <p><strong>El derecho a oponerse al procesamiento</strong> - Usted tiene el derecho de oponerse al procesamiento de sus datos personales por parte de Nuestra Empresa, bajo ciertas condiciones.</p>
  </li>
  <li>
    <p><strong>El derecho a la portabilidad de los datos</strong> - Usted tiene derecho a solicitar que Nuestra Empresa transfiera los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones.</p>
  </li>
</ul>

<p>Si realiza una solicitud, tenemos un mes para responderle.</p>

<p>Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros en nuestro correo electrónico: info@listoapp.co</p>

<h2 id="políticas-de-privacidad-de-otros-sitios-web">Políticas de privacidad de otros sitios web</h2>
<p>El sitio web de Nuestra Empresa contiene enlaces a otros sitios web. Nuestra política de privacidad se aplica solo a nuestro sitio web, por lo que si hace clic en un enlace a otro sitio web, debe leer su política de privacidad.</p>

<p>Enlace a la política de privacidad de proveedores de servicios externos utilizados por Listo:</p>
<ul>
  <li><a href="https://policies.google.com/privacy">Google Play Services</a></li>
  <li><a href="https://firebase.google.com/policies/analytics">Firebase Analytics</a></li>
  <li><a href="http://try.crashlytics.com/terms/privacy-policy.pdf">Crashlytics</a></li>
</ul>

<h2 id="cambios-en-nuestra-política-de-privacidad">Cambios en nuestra política de privacidad</h2>
<p>Nuestra Empresa mantiene su política de privacidad bajo revisión periódica y coloca cualquier actualización en esta página web. Esta política de privacidad se actualizó por última vez el 28 de agosto de 2020.</p>

<h2 id="cómo-contactarnos">Cómo contactarnos</h2>
<p>Si tiene alguna pregunta sobre la política de privacidad de Nuestra Empresa, los datos que tenemos sobre usted o si desea ejercer uno de sus derechos de protección de datos, no dude en ponerse en contacto con nosotros.</p>

<br/>
<p>Envíenos un correo electrónico a: info@listoapp.co</p>
<br/>
<p>Correo Electrónico: info@listoapp.co<br />
Última Actualización:	14 de abril de 2024.</p>

<blockquote>
  <h2 id="consulte-el-sitio-web-de-la-superintendencia-de-industria-y-comercio-para-obtener-más-información-sobre-la-protección-de-los-derechos-del-consumidor-httpswwwsicgovco">Consulte el sitio web de la Superintendencia de Industria y Comercio para obtener más información sobre la protección de los derechos del consumidor: <a href="https://www.sic.gov.co/">https://www.sic.gov.co/</a>.</h2>
</blockquote>

<br/>
<h1 id="our-company-privacy-policy-english">Our Company Privacy Policy (English)</h1>

<p><strong>LISTO PLATFORM</strong> identified with company number: 14542525 and located in the United Kingdom (hereinafter <strong>LISTO PLATFORM</strong>), is a company whose main corporate purpose is to improve market access and decision-making in the supply chain for farmers and buyers. The company’s main activities include: (i) participating in activities to promote the use of digital platforms, including but not limited to training and advertising. (ii) Development of software technologies to connect sellers, buyers, and transporters in the agricultural supply chain and in the rural economy. For the purposes of this document it shall be referred to as “THE PROCESSOR”.</p>

<p>In compliance with the current legislation regarding the Protection of Personal Data in Colombia present in the Law 1581 of 2012 on Personal Data Protection Regulated in part by Decree 1377 of 2013 and Decree 886 of 2014, rules compiled in the Single National Decree of the Commerce, Industry and Tourism Sector 1074 of 2015 (Articles 2.2.2.25.1.1. to 2.2.2.26.3.4) LISTO PLATFORM, regulates the management of the personal data of its users, customers, web contacts, suppliers, contractors and any other natural person, who are treated.</p>

<p>By submitting any type of personal information, the holder has agreed that such information will be used in accordance with this Privacy Policy, information that will not be used at any time for purposes other than those set forth herein. In the event that said personal information is to be used for other purposes, it must be protected within the exceptions provided for by the regulations, or it must have an express authorization from its holder, as the case may be.</p>

<p>This Policy is carried out with the purpose of complying with what is required by Colombian law Law 1581 of 2012 and to protect the constitutional right of Habeas Data that all natural persons have in order to know, update and rectify the personal information stored, and that is treated by LISTO PLATFORM, other rights, freedoms and constitutional guarantees that arise, in the head of the data holder that arise from the processing of their personal data.</p>

<p>The user who connects, accesses or uses the app and/or LISTO web platform acknowledges that he is entering into a legal, binding and enforceable contract with LISTO PLATFORM, who is committed to protecting his personal data. The user who uses and enjoys LISTO must know and accept these Privacy Policies (“Privacy Policies”).</p>

<p><strong>LISTO PLATFORM</strong> built the Listo app as a Free app. This Service is provided by LISTO PLATFORM at no cost and is intended for use as is.</p>

<p>This privacy policy will explain how our Company uses the personal data we collect from you when you use our platform and services.</p>

<h2 id="topics">Topics:</h2>
<ul>
  <li>What data do we collect?</li>
  <li>How do we collect your data?</li>
  <li>How and why will we use your data?</li>
  <li>How do we store your data?</li>
  <li>Marketing</li>
  <li>What are your data protection rights?</li>
  <li>Privacy policies of other websites</li>
  <li>Changes to our privacy policy</li>
  <li>How to contact us</li>
</ul>

<h2 id="what-data-do-we-collect">What data do we collect?</h2>

<p>We collect your personal data when you use our Services, create a new Listo account, provide us with information via a web form, add or update information in your Listo account, participate in online community discussions or otherwise interact with us.</p>

<p>The personal data you provide when using our Services or creating a Listo account:</p>
<ul>
  <li>Data that identifies you, such as your name, your profile picture, address/location, telephone numbers or email addresses, gender, your user name, your identification number that you provide when setting up your Listo account or at a later date, and additional identification data and tax identification numbers that you provide when using our payment services.</li>
  <li>Data regarding offers, purchases, or sales that you provide in a transaction, such as items to be sold or purchased, transaction amounts, time and location of transactions and payment methods.</li>
  <li>Content that you share with other users through our messaging tools, such as broadcasting and in-app messaging.</li>
  <li>Financial information in connection with a transaction.</li>
  <li>Shipping, billing, and other information you provide in connection with the purchase or shipping of an item, as well as relevant shipping information (such as shipment numbers and tracking information) if shipped through logistics programs.</li>
  <li>Data regarding all other interactions with our Services, your advertising preferences, and your communications with us.</li>
  <li>Location data, including the location data of your mobile device. Please note that for most mobile devices, you can manage or disable the use of location services for all applications in the settings menu of your mobile device.</li>
</ul>

<h2 id="how-do-we-collect-your-data">How do we collect your data?</h2>

<p>We collect data and process data when you:</p>
<ul>
  <li>Register online to create a Listo account.</li>
  <li>Voluntarily complete a customer survey or provide feedback on any of our message  boards or via email.</li>
  <li>Use or view our website via your browser’s cookies.</li>
  <li>Use our Services or the Listo platform as part of your transactions or search activities.</li>
</ul>

<h2 id="how-and-why-will-we-use-your-data">How and why will we use your data?</h2>

<h3 id="our-company-collects-your-data-so-that-we-can-provide-you-with-our-services">Our Company collects your data so that we can provide you with our Services.</h3>
<p>This includes:</p>
<ul>
  <li>Managing your Listo account.</li>
  <li>Processing your transaction, including but not limited to enabling and performing transactions with other users (including the transmission of your personal data to other users where necessary to perform the transaction, including in cases of terminated, failed or subsequently voided transactions), providing and enhancing features such as payment processing, ratings, and providing other services you may use (as described in connection with such services), and ensuring the functionality of our Services.</li>
  <li>Contacting you with special offers on other products and services we think you might like,  in accordance with the communication preferences in your Listo account.</li>
  <li>Providing you with guidance and support when using Listo.</li>
  <li>Carrying out aggregate data analysis of trading through the platform (for example, average prices in a certain location and time) and return these aggregate results to users of Listo. These data analytics will not reveal your personal information.</li>
  <li>Processing general location data (such as IP address / coordinates / postcode) in order to provide you with location-based services (such as radius search and other content that is personalised on the basis of your general location data). You may choose to disable the use of location services, but we will not be able to provide you with location-based services.</li>
  <li>Enabling the delivery of purchased items by logistics/delivery service providers including notifications in connection with the delivery (such as tracking information), the latter to the extent permitted by applicable law without your consent.</li>
</ul>

<p><strong>Where necessary, we transmit your personal data to processors and the following recipients for one or several of the purposes described above:</strong></p>
<ul>
  <li>External service providers, partner organisations (e.g. Ministry of Agriculture) and delivery companies.</li>
  <li>Payment service providers (including but not limited to MercadoPago).</li>
</ul>

<p><strong>When we process your order, it may send your data to, and also use the resulting information from, credit reference agencies, to prevent fraudulent purchases.</strong></p>

<h3 id="we-process-your-personal-data-in-order-to-comply-with-legal-obligations-to-which-we-are-subject">We process your personal data in order to comply with legal obligations to which we are subject.</h3>
<ul>
  <li>This includes the following purposes:</li>
  <li>Participation in proceedings (including judicial proceedings) conducted by public authorities or government agencies, in particular, for the purpose of detecting, investigating and prosecuting illegal acts.</li>
  <li>Prevention, detection and mitigation of illegal activities (e.g. fraud, money laundering and terrorist financing).</li>
  <li>Ensuring the information security of our Services.</li>
  <li>Retention and storage of your personal data to comply with specific legal retention requirements (for more information on Listo’s storage of your data, see How do we store your data).</li>
</ul>

<p><strong>Where necessary, we transmit your personal data to processors and the following recipients for one or several of the purposes described above:</strong></p>
<ul>
  <li>Law enforcement agencies, courts, government agencies or public authorities, intergovernmental or supranational bodies.</li>
  <li>Third-party service providers</li>
  <li>Third parties who are involved in judicial proceedings, in particular, if they submit a legal order, court order or equivalent legal order to us</li>
  <li>Credit agencies, bureaus or associations, if required by applicable law (e.g. information on payment delays, payment defaults or other irregularities that may be relevant to your credit report).</li>
</ul>

<h2 id="how-do-we-store-your-data">How do we store your data?</h2>

<p>Your personal data will be securely stored by us and our service providers in accordance with applicable data protection laws. We will delete your personal data or take steps to properly render the data anonymous, unless we are legally obliged to keep your personal data longer (e.g. for tax, accounting or auditing purposes). In Europe, the retention periods are generally between 6 and 10 years.</p>

<p>As far as legally permissible or required, we restrict the processing of your data instead of deleting it (e.g. by restricting access to it). This applies in particular to cases where we may still need the data for the execution of the contract or for the assertion of or defense against legal claims. In these cases, the duration of the restriction of processing depends on the respective statutory limitation or retention periods. The data will be deleted after the relevant limitation or retention periods have expired.</p>

<h2 id="marketing-1">Marketing</h2>

<p>Our Company would like to send you information about products and services of ours that we think you might like.</p>

<p>If you have agreed to receive marketing, you may always opt out at a later date.</p>

<p>You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other partner organisations.</p>

<p>If you no longer wish to be contacted for marketing purposes, marketing emails will include an unsubscribe link.</p>

<h2 id="what-are-your-data-protection-rights">What are your data protection rights?</h2>

<p>Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>

<ul>
  <li>
    <p><strong>The right to access</strong> - You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.</p>
  </li>
  <li>
    <p><strong>The right to rectification</strong> - You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete information you believe is incomplete.</p>
  </li>
  <li>
    <p><strong>The right to erasure</strong> - You have the right to request that Our Company erase your personal data, under certain conditions.</p>
  </li>
  <li>
    <p><strong>The right to restrict processing</strong> - You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.</p>
  </li>
  <li>
    <p><strong>The right to object to processing</strong> - You have the right to object to Our Company’s processing of your personal data, under certain conditions.</p>
  </li>
  <li>
    <p><strong>The right to data portability</strong> - You have the right to request that Our Company transfer the data that we have collected to another organisation, or directly to you, under certain conditions.</p>
  </li>
</ul>

<p>If you make a request, we have one month to respond to you.<br />
If you would like to exercise any of these rights, please contact us at our email: info@listoapp.co</p>

<h2 id="privacy-policies-of-other-websites">Privacy policies of other websites</h2>

<p>The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>

<p>Link to privacy policy of third party service providers used by Listo:</p>
<ul>
  <li><a href="https://policies.google.com/privacy">Google Play Services</a></li>
  <li><a href="https://firebase.google.com/policies/analytics">Firebase Analytics</a></li>
  <li><a href="http://try.crashlytics.com/terms/privacy-policy.pdf">Crashlytics</a></li>
</ul>

<h2 id="changes-to-our-privacy-policy">Changes to our privacy policy</h2>

<p>Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 14 April 2024.</p>

<h2 id="how-to-contact-us">How to contact us</h2>

<p>If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>

<br/>
<p>Email us at: info@listoapp.co<br />
Last update: 14 April 2024.</p>

     {/*</section>*/}

     </div>
    </div>
  );
}
