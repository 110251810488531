import React from 'react';
import ReactGA from 'react-ga';

export default function Terminos_y_Condiciones() {

  return (
    <div className="body">

        {ReactGA.pageview(window.location.pathname)}

        <div className="answers terminos-y-condiciones">


<h1 id="términos-y-condiciones-de-uso-de-la-plataforma-listo">Términos y Condiciones de uso de la plataforma Listo</h1>

<blockquote>
  <p>Por favor lea cuidadosamente estas condiciones del servicio, ya que contienen condiciones de uso de la plataforma web, así como las obligaciones de cumplimiento dictadas por la normatividad legal.</p>
</blockquote>

<p><strong>LISTO PLATFORM LIMITED</strong> identificada con: <strong>14542525</strong> y domiciliada en el Reino Unido, es una sociedad que tiene como objeto social principal el mejoramiento del acceso al mercado y del proceso de toma de decisiones en la cadena de suministro para agricultores y compradores. Las principales actividades de la compañía incluyen: (i) Participar en actividades para promover el uso de plataformas digitales, corno por ejemplo y sin limitarse a entrenamiento y publicidad. (ii) Desarrollo de tecnologías de software para conectar vendedores, compradores y transportadores en la cadena de suministro agrícola y en la economía rural. Para efectos del presente documento se denominará como “EL OPERADOR”.</p>

<p>Bienvenido(a) a <strong>LISTO</strong>, un mercado digital social que crea conexiones para que los agricultores vendan sus productos disponibles y los compradores cumplan con las demandas crecientes, conectando las comunidades rurales a través de medios accesibles desde cualquier dispositivo como una Plataforma Web, un Aplicativo Móvil y un menú de voz y de SMS (en adelante la “Plataforma”) que ofrece sus servicios de “marketplace digital”, proporcionando herramientas para comerciar sus productos agrícolas, a través del sitio web <a href="https://www.soylisto.app">https://www.soylisto.app</a> y de aplicaciones para los sistemas operativos iOS y Android.</p>

<p>El usuario que accede a <strong>LISTO</strong> debe conocer y aceptar los siguientes Términos y Condiciones de Uso (en adelante “<strong>Términos y Condiciones</strong>”) y las Políticas de Privacidad presentes en la página web https://www.soylisto.app/privacidad. El Operador de la Plataforma Web es <strong>LISTO PLATFORM LIMITED</strong> sociedad comercial identificada con 14542525 (en adelante <strong>LISTO</strong> o “El Operador”).</p>

<p><strong>Listo Platform</strong> está comprometida con proteger la información del Usuario de acuerdo con las leyes, disposiciones legales y demás normas nacionales que regulan la protección de datos, la privacidad o manejo de información y demás normas que son concordantes con el orden público.</p>

<h2 id="definiciones">Definiciones</h2>

<ol>
  <li>
    <p><strong>Operador de la Plataforma</strong>: Es el encargado de administrar operativa y funcionalmente la Plataforma, representado para los efectos de los presentes términos y condiciones por <strong>Listo Platform</strong>, o por la persona natural o jurídica que esta designe.</p>
  </li>
  <li>
    <p><strong>Plataforma</strong>: Es un conjunto de herramientas digitales disponibles en línea, cuyo nombre al público se denomina LISTO, y que permite la conexión y  gestión de la relación entre los Compradores y Agricultores.</p>
  </li>
  <li>
    <p><strong>Objetivo de la Plataforma</strong>: Ofrecer el servicio de “marketplace digital” para conectar Compradores y Agricultores, ofreciéndoles herramientas para comerciar sus productos, a través del sitio web https://www.soylisto.app y aplicación para los sistemas operativos iOS y Android.</p>
  </li>
  <li>
    <p><strong>Comprador</strong>: Persona natural que utiliza la plataforma para comprar productos directamente a los agricultores sin intermediarios.</p>
  </li>
  <li>
    <p><strong>Agricultor</strong>: Persona natural que ofrece sus productos a través de la Plataforma para ser comprados directamente por los compradores.</p>
  </li>
  <li>
    <p><strong>Transportador</strong>: Persona natural que ofrece sus servicios de transporte a nombre y riesgo propio directamente a los Compradores o Agricultores, a través de la Plataforma.</p>
  </li>
  <li>
    <p><strong>Administradores de Asociación</strong>: Persona natural que administra la cuenta de una Asociación registrada en la Plataforma, para coordinar la compra o despacho de órdenes de productos conjuntamente con otros usuarios de la Plataforma.</p>
  </li>
  <li>
    <p><strong>Mensaje de datos</strong>: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo electrónico, el telegrama, el télex o el telefax.</p>
  </li>
  <li>
    <p><strong>Usuario</strong>: Se refiere a cualquier persona natural que hace uso de la Plataforma para acceder a los servicios que esta ofrece, en cualquiera de las funciones disponibles (Agricultor, Comprador, Transportador, Administrador de Asociación).</p>
  </li>
  <li>
    <p><strong>Datos personales</strong>: Es toda información que permite identificar a una persona natural.</p>
  </li>
  <li>
    <p><strong>Publicidad</strong>: Es toda forma de comunicación realizada por parte del Operador de la Plataforma con el fin de brindar información sobre los productos, actividades comerciales o comunicar campañas promocionales sobre los productos gastronómicos ofrecidos.</p>
  </li>
  <li>
    <p><strong>Pasarela de pagos</strong>: Servicio que permite la realización de pagos por parte de los Compradores directamente al Operador de la Plataforma a través de medios electrónicos utilizando plataformas tecnológicas sean propias del Operador u operadas por un tercero. Para el funcionamiento de la Plataforma <strong>LISTO</strong> se utilizarán los servicios de <strong>MERCADOPAGO</strong>.</p>
  </li>
  <li>
    <p><strong>Pop - Ups</strong>: Ventana o aviso de Internet que emerge de manera automática en cualquier momento cuando el Usuario utiliza la Plataforma.</p>
  </li>
</ol>

<h2 id="objeto">Objeto</h2>

<p>Los presentes <strong>Términos y Condiciones</strong> regulan la autorización de uso que brinda <strong>Listo Platform</strong> a los usuarios de la Plataforma <strong>LISTO</strong> para que accedan a la misma, publiquen o busquen información acerca de los productos o bienes que ofrecen o que son de su interés, indiquen y conozcan la información completa acerca de los mismos y puedan gestionar la celebración de un contrato de compraventa con los demás usuarios que hacen uso de la plataforma.</p>

<p>El Operador de la Plataforma para facilitar esta relación presta los presentes servicios:</p>

<p>1) Elabora una selección de los diferentes productos o bienes de servicio ofrecidos y publicados por los Agricultores de  forma publicitaria para que los Compradores puedan conocer las diferentes ofertas a su disposición; 2) Promueve la conexión entre Compradores y Agricultores para la celebración de los contratos correspondientes 3) Promueve la conexión entre Compradores o Agricultores y Transportadores para la celebración de contratos de transporte directamente entre las partes 4) Permite gestionar la relación dineraria del contrato entre las partes a través de una pasarela de pago 5) Opera como medio de comunicación entre los usuarios de la Plataforma a través del aplicativo de mensajería desplegado en la Plataforma, donde se pueden compartir textos, imágenes o mensajes de audio.</p>

<h2 id="1-aceptación-de-los-términos-y-condiciones">1. Aceptación de los Términos y Condiciones</h2>
<p>En el momento en que el usuario inicia sesión, se conecta, accede o usa la Plataforma, reconoce que ha leído y entendido los <strong>Términos y Condiciones</strong> incluyendo la <strong>Política de Privacidad</strong> de <strong>Listo Platform</strong> y acepta a estar obligado a cumplir dichas disposiciones y todas las leyes y regulaciones con relación al uso, acceso y conectividad de la Plataforma <strong>LISTO</strong>.</p>

<h4 id="si-el-mismo-no-acepta-cualquiera-de-los-términos-y-condiciones-no-debe-conectarse-acceder-o-usar-la-plataforma-listo">Si el mismo no acepta cualquiera de los Términos y Condiciones, no debe conectarse, acceder o usar la Plataforma LISTO.</h4>

<p>Los <strong>Términos y Condiciones</strong> y la <strong>Política de Privacidad</strong> los puede modificar el Operador de la Plataforma  a su discreción, en cualquier momento, con o sin previo aviso.</p>

<p><strong>Listo Platform</strong> actualizará la fecha de la última revisión, la cual aparecerá en la parte inferior de este documento y del documento de Política de Privacidad.</p>

<p>El usuario tiene la obligación de cumplir todas las leyes, reglamentos, o cualquier otra obligación relacionada con el servicio. <strong>Listo Platform</strong> recomienda que el usuario revise con atención los <strong>Términos y Condiciones</strong> previo a realizar cualquier actividad en la Plataforma Web.</p>

<h4 id="el-usuario-que-inicie-sesión-se-conecte-accede-o-utilice-listo-reconoce-que-las-presentes-condiciones-constituyen-un-contrato-legal-vinculante-y-exigible">El usuario que inicie sesión, se conecte, accede o utilice LISTO, reconoce que las presentes condiciones constituyen un contrato legal vinculante y exigible.</h4>

<h2 id="2-servicio-y-funcionamiento-de-listo">2. Servicio y Funcionamiento de LISTO.</h2>
<p>El Operador de la Plataforma a través de la misma elabora una selección de los diferentes productos o bienes de servicio ofrecidos y publicados por los Agricultores, de  forma publicitaria para que los Compradores puedan conocer las diferentes ofertas a su disposición, conozcan la información completa acerca de los mismos y puedan celebrar contratos de compraventa para la adquisición de los productos. Adicionalmente permite el contacto entre Agricultores o Compradores y Transportadores para la celebración de contratos de transporte de los productos adquiridos a través de la Plataforma.</p>

<p>Para acceder al servicio el usuario puede realizar los siguientes pasos:</p>

<ol>
  <li>Descargar e instalar la aplicación <strong>LISTO</strong> a través de Google Play Store para dispositivos Android o App Store para dispositivos IOS.</li>
  <li>Ingresar a la plataforma a través del enlace: <a href="https://soylisto.app/">https://soylisto.app/</a></li>
  <li>Para hacer el registro del usuario se debe iniciar sesión con el dispositivo e indicar el número de teléfono del usuario, al cual <strong>LISTO</strong> enviará un SMS con un código de confirmación, el cual deberá ser ingresado para proceder con la creación de la cuenta de usuario. Este registro también podrá ser adelantado a través de a través de la cuenta de email del usuario, a la cual se enviará un correo electrónico de confirmación, o a través de la cuenta de usuario de los servicios de Facebook y Google.</li>
  <li>Durante el proceso de registro, el usuario de la Plataforma debe seleccionar una foto de perfil para su cuenta de usuario.</li>
  <li>El usuario debe completar la información de su perfil de usuario con sus nombres, apellidos, número de identificación, municipio de domicilio y género.</li>
  <li>El usuario podrá escoger entre varios tipos de funciones o roles en la cadena de suministro, entre las cuales se encuentran: Agricultor, Comprador, Administrador de Asociación o Transportador, o podrá crear otro tipo de función.</li>
  <li>El usuario Agricultor y/o  Comprador podrá seleccionar qué producto está interesado en comprar y vender de una lista de varias frutas, verduras, nueces y productos para untar.</li>
  <li>Para crear un perfil de Asociación, es necesario crear primero una cuenta de usuario personal.</li>
  <li>El Agricultor podrá ofrecer sus productos indicando el tipo de producto, su cantidad, su ubicación aproximada,  aportar una breve descripción del producto y adjuntar fotos del producto.</li>
  <li>El Comprador podrá navegar por la Plataforma y seleccionar uno o varios productos para conocer sus características, precio, disponibilidad y una estimación aproximada de la distancia entre los usuarios. Teniendo esta información podrá hacer una oferta al Agricultor, para acordar un precio de compra por los productos.</li>
  <li>Por medio de las Asociaciones, los Administradores podrán coordinar la compra o despacho de órdenes de productos conjuntamente entre dos o más usuarios de la Plataforma.</li>
  <li>Confirmación de la orden. Una vez el Comprador haya seleccionado los productos, deberá realizar la confirmación de su orden. Al realizar este paso, el Comprador expresa su voluntad de solicitar la celebración a través de la Plataforma un contrato de Compraventa para adquirir los productos ofrecidos por el Agricultor.</li>
  <li>Confirmación del monto a pagar.  Una vez se ha confirmado la orden la Plataforma indica al Comprador un aproximado del monto a pagar por la orden.</li>
  <li>Los Compradores y/o Agricultores podrán celebrar a través de la Plataforma contratos de transporte con los Transportadores para entregar o recibir los productos ofrecidos o adquiridos a través de la Plataforma.</li>
  <li>Procesamiento del pago. El Comprador podrá seleccionar los diferentes medios de pago habilitados, para realizar el pago por el producto directamente al Agricultor bien sea en efectivo o a través de las plataforma de pagos que el Operador de la Plataforma ha dispuesto para este fin.  El Usuario que utilice los medios de pago habilitados por la Plataforma y  operados por terceros se someterá a los Términos y Condiciones y Política de Privacidad establecidos por los mismos.</li>
</ol>

<p>El Operador a través de su Plataforma permite el encuentro entre los diferentes tipos de usuarios, sin que bajo ningún escenario obre como miembro de la cadena de comercialización de los productos o servicios ofrecidos en la Plataforma. En virtud de lo anterior, para efectos de cualquier reclamación respecto de los productos o servicios adquiridos por medio de la Plataforma, deberá ser dirigida directamente al usuario con quien se presenta el inconveniente.</p>

<h2 id="3-medios-de-pago-de-la-plataforma">3. Medios de Pago de la Plataforma.</h2>

<p>El Comprador tendrá la opción de realizar el pago de los productos adquiridos en la Plataforma por medio de una pasarela de pagos denominada MERCADOPAGO, por lo cual estará igualmente sujeto a los Términos y Condiciones de la misma, los cuales podrá encontrar en el siguiente link: <a href="https://www.mercadopago.com.co/ayuda/terminos-y-condiciones_299">https://www.mercadopago.com.co/ayuda/terminos-y-condiciones_299</a>.</p>

<p>Al momento de realizar la compra, la Plataforma arrojará un desglose de los valores aproximados a pagar por parte del Comprador, indicando también el valor aproximado a recibir por parte del agricultor. <strong>Listo Platform</strong> informa a los usuarios que estos valores pueden variar teniendo en cuenta los impuestos o retenciones aplicables a la transacción, así como las tarifas cobradas por la Plataforma y la Pasarela de Pagos, por lo que este desglose cumple una función meramente informativa y <strong>Listo Platform</strong> no se hace responsable de cualquier cambio o variación que pueda ocurrir. En consecuencia, los valores definitivos de cada transacción serán calculados y determinados por la Pasarela de Pagos.</p>

<p>Todos los aspectos relacionados con el uso del medio de pago, como su fecha de emisión, caducidad, cupo, bloqueos, etc., se sujetarán a lo pactado entre el Comprador y la Entidad emisora financiera o bancaria.</p>

<p>El pago se entiende que se ha efectuado al Operador de la Plataforma, y los pagos incluyen los impuestos aplicables que se requiera por ley.</p>

<p>Los pagos son definitivos y no serán reembolsables al menos que el Operador de la Plataforma, lo considere procedentes en atención con los presentes Términos y Condiciones.</p>

<p>El Operador podrá a su discreción y criterio aumentar, disminuir o modificar las tarifas por los servicios obtenidos. Igualmente, los cargos modificables aplicarán para las operaciones siguientes al momento de la modificación y pueden variar a criterio de <strong>Listo Platform</strong>, en todo caso el mismo  deberá  informar al usuario la tarifa aplicable antes de utilizar el servicio.</p>

<h2 id="4-registro-de-la-cuenta-de-usuario">4. Registro de la cuenta de usuario.</h2>

<p>Durante el proceso de registro de la cuenta de usuario se le pedirá al mismo  información acerca del nombre completo, número de identificación, foto de perfil, género, número de teléfono, municipio de domicilio y su rol en la cadena de suministro. Sus datos de acceso y contraseñas deberán ser manejadas de manera confidencial en todo momento.</p>

<p>El usuario se compromete a no divulgar su nombre de usuario y contraseña a terceros, además autoriza el acceso de <strong>LISTO</strong> a sus cuentas personales de Google y/o Facebook, para acceder a través de estas cuentas de usuario a la Plataforma.</p>

<p>Al registrarse el usuario debe proporcionar la información de manera completa, fidedigna y exacta.</p>

<p>Una vez el usuario complete el proceso de registro podrá acceder a las funcionalidades disponibles en la Plataforma y a configurar su cuenta de acuerdo a sus preferencias.</p>

<p>Al registrarse en la Plataforma, el usuario afirma que acepta los presentes <strong>Términos y Condiciones</strong>.</p>

<h2 id="5--obligaciones-del-usuario">5.  Obligaciones del Usuario:</h2>

<p>El Usuario al aceptar los presentes <strong>Términos y Condiciones</strong> de forma voluntaria adquiere las siguientes obligaciones:</p>

<ol>
  <li>Entregar la información de manera completa, fidedigna y exacta en la Plataforma al momento de crear la cuenta de usuario.</li>
  <li>No divulgar su cuenta de usuario y contraseña en la Plataforma.</li>
  <li>No utilizar la Plataforma para usos diferentes a los aquí establecidos, ni para actos que vayan en contra del orden público, la moral, las buenas costumbres o que puedan afectar al Operador de la Plataforma, los mandatarios o los terceros.</li>
  <li>Pagar oportunamente la remuneración económica al Agricultor por el encargo realizado.</li>
  <li>Entregar oportunamente los productos ofrecidos por medio de la Plataforma al Comprador una vez se haya realizado la transacción.</li>
  <li>Informar al Operador de la Plataforma de cualquier novedad correspondiente a su cuenta de usuario.</li>
  <li>Proveer una información adecuada y veraz de las condiciones de los productos ofrecidos a través de la Plataforma.</li>
  <li>Informarse adecuadamente de las condiciones y características de los productos solicitados a través de la Plataforma.</li>
  <li>Abstenerse de realizar conductas que puedan atentar contra la integridad de la Plataforma y/o de su correcto funcionamiento.</li>
  <li>Realizar diligentemente todas aquellas conductas necesarias para la celebración y perfeccionamiento de las transacciones realizadas a través de la Plataforma.</li>
</ol>

<h2 id="6-obligaciones-del-operador-de-la-plataforma">6. Obligaciones del Operador de la Plataforma</h2>

<p>Mediante el presente documento el Operador de la Plataforma se obliga a:</p>

<ol>
  <li>Elaborar y mantener una selección de los diferentes productos o bienes de servicio disponibles de  forma publicitaria para que los usuarios puedan conocer las diferentes ofertas a su disposición y conozcan la información completa, suficiente y fidedigna acerca de los mismos.</li>
  <li>Informar adecuadamente sobre todas las condiciones específicas para la celebración del contrato de compraventa entre los usuarios de la Plataforma.</li>
  <li>Facilitar el contacto entre los usuarios a través del servicio de mensajería incluido en la Plataforma, e informar a los usuarios los datos relevantes para la facturación de las transacciones realizadas por medio de la Plataforma.</li>
  <li>Mantener a disposición de los Usuarios los Términos y Condiciones de uso de Plataforma debidamente actualizados y vigentes.</li>
  <li>Utilizar la información entregada por los usuarios de acuerdo con las Políticas de Privacidad de Listo Platform.</li>
</ol>

<h2 id="7-restricciones-de-uso">7. Restricciones de Uso</h2>
<p>El Usuario, Agricultor, Comprador, Transportador, Administrador de Asociación y en general cualquier persona natural o jurídica que haga uso de la Plataforma no puede, directamente o por medio de cualquier persona, realizar las siguientes actividades:</p>

<ol>
  <li>Usar la Plataforma para fines ilícitos, ilegales o no autorizados.</li>
  <li>Usar la Plataforma en cualquier forma de spam, correo no solicitado o conducta similar.</li>
  <li>Permitir el acceso a la cuenta en la Plataforma por usuarios no autorizados. El usuario de la cuenta asume toda la responsabilidad por el uso no autorizado de la Plataforma.</li>
  <li>Usar la Plataforma con fines no autorizados por el Operador.</li>
  <li>Usar el servicio de mensajería incluido en la Plataforma para fines diferentes al objeto de la Plataforma, o para intercambiar información de contacto, con el fin de realizar transacciones comerciales por fuera de la Plataforma.</li>
  <li>Interferir o interrumpir el funcionamiento de la Plataforma o los servidores o redes que alojan LISTO, o desobedecer las leyes o reglamentos. requisitos, procedimientos, políticas o regulaciones de dichos servidores o redes.</li>
  <li>Publicar o poner a disposición a través de la Plataforma cualquier contenido, texto o información que sea abusivo, amenazante, obsceno, difamatorio, calumnioso, o discriminatorio  en temas raciales, sexuales, religiosos o de otra manera desagradable u ofensivo.</li>
  <li>Hacerse pasar por otra persona o proporcionar información falsa en la Plataforma, ya sea directa o indirectamente.</li>
  <li>Declarar falsamente o tergiversar su afiliación con cualquier persona o entidad, ni publicar información falsa o inexacta en la Plataforma.</li>
  <li>Transmitir, distribuir, mostrar o hacer disponible a través de o en conexión con la Plataforma cualquier contenido, incluyendo contenido generado por el usuario, que pueda atentar contra los derechos de terceros, incluyendo los derechos de propiedad intelectual y los derechos de privacidad, o que pueda contener cualquier contenido ilegal.</li>
  <li>Hacer capturas de pantalla, linking, hotlinking, enlace, indexación o cualquier redireccionamiento a otras páginas web o Aplicaciones; así como impresiones, reproducciones, distribuciones o publicaciones de contenidos alojados en LISTO, tales como, imágenes de cualquier tipo, logotipos, textos, recomendaciones, comentarios y cualquier otro elemento contenido en la Plataforma.</li>
  <li>Tomar cualquier acción que imponga o pueda imponer, a criterio de LISTO, una carga irrazonable o desproporcionada en la infraestructura de su Plataforma.
Infringir las medidas de seguridad física o informática que <strong>LISTO</strong> disponga, a los fines de evitar o restringir el acceso a LISTO.</li>
  <li>Copiar, modificar, alterar, adaptar, poner a disposición, traducir, realizar ingeniería inversa, descompilar o desensamblar cualquier parte de la Plataforma.</li>
  <li>Crear un browser, marco (framing), o interfaz gráfica de usuario (GUI) alrededor de la Plataforma  sin autorización del Operador de la Plataforma</li>
  <li>Realizar cualquier actividad que sea contraria al orden público y las normas de estricto cumplimiento que regulan este tipo de servicios en internet.</li>
  <li><strong>LISTO</strong> procurará no realizar ningún tratamiento sobre datos de niños, niñas y adolescentes menores de 18 años. El uso de la plataforma solo es para mayores de edad. Sin embargo, en caso de que los menores de 18 años con capacidad jurídica quieran usar el servicio deberán contar con la autorización expresa por parte de su representante legal. En todo caso, corresponderá a los representantes legales de los niños, niñas y adolescentes otorgar la autorización para proceder con el Tratamiento de los datos personales de los menores, si no se cuenta con la autorización del representante no se hará tratamiento de los mismos y no se le podrá prestar el servicio al menor. <strong>Listo Platform</strong> se reserva el derecho de cancelar la cuenta de cualquier usuario que no cumpla con las condiciones mencionadas.</li>
</ol>

<p>En caso de incumplimiento de las disposiciones establecidas en los presentes Términos y Condiciones, el Operador de la Plataforma cancelará la cuenta de usuario y podrá iniciar acciones legales por responsabilidad civil contractual o extracontractual y/o penal.</p>

<p>Como condición para el uso la Plataforma de propiedad de <strong>LISTO</strong>, el Agricultor, Comprador y/o usuario registrado acepta y garantiza que:</p>

<ul>
  <li>No infringirá o violará los presentes Términos y Condiciones.</li>
  <li>Posee la capacidad legal para aceptar estos Términos y Condiciones y por consiguiente, al aceptarlos manifiesta un consentimiento libre de vicios, que lo vincula obligatoriamente.</li>
  <li>Se compromete a utilizar la Plataforma de conformidad con los presentes Términos y Condiciones, y cumplir plenamente con sus obligaciones.</li>
  <li><strong>LISTO</strong>, sus funcionarios, directores, empleados, agentes y contratistas son independientes y  no tienen ninguna  vinculación laboral con ninguno de los mencionados.</li>
  <li>Reconoce que en virtud de la normatividad sobre mensajes de datos en Colombia consagrada en la Ley 527 de 1999, al hacer uso de la Plataforma puede intervenir como parte en la celebración de negocios jurídicos que pueden dar lugar al nacimiento, modificación y extinción de obligaciones, específicamente respecto al Contrato de Compraventa realizado a través de medios virtuales, el cual posee toda la validez legal.</li>
  <li>El usuario reconoce que el Operador no es productor, proveedor, expendedor, agente, distribuidor y en general ningún tipo de comercializador de los productos que exhibe, ya que opera solo como una plataforma tecnológica que permite el encuentro de Compradores y Agricultores para la gestión de encargos.</li>
  <li>El Operador no es responsable de la calidad o precisión de los productos publicados o intercambiados en la plataforma.</li>
</ul>

<h2 id="8---enlaces-con-otras-plataformas-web-e-información-que-se-compartirá">8.   Enlaces con otras Plataformas Web e información que se compartirá</h2>

<p>El Operador de la Plataforma se reserva el derecho de poder realizar conexiones y/o alianzas con otras Plataformas Web o empresas, en las que se puede utilizar la información proporcionada por el usuario para fines de mostrar anuncios publicitarios a través de la Plataforma de conformidad con los Términos y Condiciones.</p>

<h2 id="9--política-de-privacidad">9.  Política de Privacidad.</h2>
<p><strong>Listo Platform</strong> respeta la privacidad de los usuarios y se compromete a proteger la información que incorpora a la Plataforma. El usuario tiene acceso a conocer las Políticas de Privacidad sobre el manejo de datos personales, las cuales pueden ser consultadas en la página <a href="https://soylisto.app/privacidad">https://soylisto.app/privacidad</a>.</p>

<p>El tipo de información del usuario y su manejo se describe en detalle en <strong>la Política de Privacidad de Listo Platform</strong>, que hace parte integral de este documento. Se entiende que el usuario conoce y acepta las <strong>Políticas de Privacidad</strong> una vez registrado en la Plataforma.</p>

<h2 id="10-marcas-y-nombres-comerciales">10. Marcas y nombres comerciales</h2>
<p>La marca, logo, lema comercial  y todos los otros identificadores relacionados con <strong>LISTO</strong> son de propiedad de LISTO PLATFORM LIMITED estén o no registrados. Otras marcas registradas, marcas de servicio, nombres comerciales, logotipos, o cualquier información susceptible de propiedad intelectual que aparezca en <strong>LISTO</strong> pertenece a sus respectivos propietarios (“<strong>Marcas de Terceros</strong>”) y son utilizados legítimamente por el Operador de la Plataforma sea por autorización expresa.</p>

<p>Ningún derecho, licencia o interés para las marcas de <strong>LISTO</strong> o las Marcas de Terceros son otorgados en este documento, y el usuario acepta que no existe derecho, licencia o interés con respecto a las marcas de <strong>LISTO</strong> o las Marcas de Terceros.</p>

<p>En virtud de lo anterior, el usuario evitará usar cualquiera de esas marcas sin autorización. Las Marcas relacionadas con <strong>LISTO</strong> y las Marcas de Terceros están reguladas por las normas y regulación de propiedad intelectual y demás disposiciones legales.</p>

<h2 id="11--enlaces-a-sitios-web-de-terceros">11.  Enlaces a sitios web de terceros</h2>

<p>La Plataforma puede contener enlaces a sitios web de terceros (“Sitios de Terceros”), que pueden ser sugeridos por <strong>LISTO</strong>. El usuario reconoce que <strong>Listo Platform</strong> no tiene control sobre tales <strong>Sitios Web de Terceros</strong> y reconoce y acepta que el mismo no es responsable por la disponibilidad de los Sitios de Terceros ni por los servicios, contenidos, anuncios, productos o cualquier material disponible en ellos.</p>

<p>El usuario también reconoce y acepta que <strong>Listo Platform</strong> no será responsable, directa o indirectamente, por cualquier daño o pérdida que sea causada por el uso de cualquier servicio, contenido, producto u otros materiales disponibles a través de los Sitios Web de Terceros. Los Sitios de Terceros se rigen por sus correspondientes Términos y Condiciones de Uso y Política de Privacidad, y por tanto, el usuario debe conocer y aceptar tales documentos antes de acceder a los Sitios de Terceros.</p>

<h2 id="12--anuncios">12.  Anuncios</h2>
<p>La Plataforma puede integrar anuncios o enlaces de patrocinio. Si un usuario hace clic en los anuncios puede ser transferido a un Sitio Web de un anunciante así como recibir mensajes, información y ofertas.</p>

<p>Toda la información contenida en los anuncios pertenece exclusivamente a los anunciantes. <strong>LISTO</strong> no tiene ninguna representación en dichos anuncios, ni tiene control sobre los mismos.</p>

<h2 id="13-la-disponibilidad-y-funcionalidad">13. La disponibilidad y funcionalidad</h2>
<p>La disponibilidad y funcionalidad de la Plataforma depende de diversos factores, tales como redes de comunicación, software, hardware y proveedores de servicios y contratistas. El Operador de la Plataforma no garantiza  que la Plataforma funcionará en todo momento sin interrupción, o que sea inmune de accesos no autorizados o libre de errores.</p>

<p><strong>Listo Platform</strong> hará todo lo posible en todo momento para procurar que la disponibilidad y funcionamiento de la Plataforma se encuentre en óptimas condiciones y que los usuarios puedan acceder sin ningún inconveniente.</p>

<p>En caso que la Plataforma no se llegara a encontrar disponible mientras está vigente una promoción y/o oferta, <strong>LISTO</strong> se compromete a volver a publicar la oferta y/o promoción en la Plataforma una vez se encuentre disponible y se den las condiciones para dichos efectos.</p>

<h2 id="14-cambios-y-cancelación-de-registro">14. Cambios y cancelación de registro</h2>
<p><strong>LISTO</strong> se reserva el derecho a permitir o negar el registro de cualquier usuario y puede cancelar en cualquier momento su cuenta de usuario en la Plataforma, en caso de no cumplir con las restricciones de uso presentes en este documento.</p>

<h2 id="15---licencia-de-la-plataforma">15.   Licencia de la Plataforma.</h2>
<p>La Plataforma de propiedad de <strong>Listo Platform</strong> y su contenido son de propiedad de <strong>Listo Platform</strong> o sus licenciatarios.</p>

<p>Se reitera que <strong>Listo Platform</strong> no le otorga una licencia al usuario por hacer uso de la Plataforma. Por lo anterior, no se entiende en ningún momento que <strong>Listo Platform</strong> haga parte de una relación licenciante - licenciatario,  en cualquier interacción que pueda tener el usuario con la plataforma.</p>

<h2 id="16--general">16.  General</h2>
<ul>
  <li>Estos Términos y Condiciones no deben interpretarse como una relación laboral empleado - empleador, agencia o relación franquiciador - franquiciado entre <strong>Listo Platform</strong> y sus usuarios, Compradores, Agricultores, o cualquier persona en general.</li>
  <li>Cualquier reclamación relativa a <strong>LISTO</strong> o a su uso respecto de los presentes Términos y Condiciones se regirá por las leyes vigentes en el territorio colombiano, decisiones, tratados, entre otros documentos y disposiciones legales internacionales relacionados con propiedad intelectual, protección de datos y otros materiales relacionados con servicios o aplicaciones en Internet.</li>
  <li>En caso de cualquier controversia entre el usuario y el Operador de la Plataforma derivada de actos realizados en la Plataforma que tienen implicaciones legales según lo contenido en los presentes Términos y Condiciones, las partes se comprometen a dirimir las diferencias ante la Jurisdicción Ordinaria y aceptan que sea dicha Jurisdicción la competente para la resolución de dichas controversias.</li>
  <li>Si cualquier disposición en estos Términos y Condiciones es ilegal, nula o ineficaz, dicha disposición se considerará separable de este documento y no afectará la validez y exigibilidad de las disposiciones restantes.</li>
  <li>Este documento constituye y establece los Términos y Condiciones vigentes y reemplaza cualquier otro acuerdo anterior verbal o escrito entre el Operador de la Plataforma y cualquier usuario.</li>
</ul>

<h2 id="17-inquietudes-o-dudas-del-usuario">17. Inquietudes o dudas del usuario.</h2>
<p>Si el usuario tiene alguna pregunta o comentario acerca de los Términos y Condiciones de Uso y/o Política de Privacidad, el Expendedor puede enviar un correo electrónico a la siguiente dirección: info@listoapp.co</p>

<p>El Operador de la Plataforma responderá en el menor plazo posible.</p>

<h2 id="18--notificación-y-datos-de-contacto-de-farming-data">18.  Notificación y Datos de Contacto de LISTO.</h2>
<p>LISTO PLATFORM LIMITED. se encuentra ubicado en la siguiente dirección y los datos de contacto son los siguientes, lo anterior para efectos de ser notificado de cualquier acto judicial o de cualquier tipo.</p>

<br/>
<p>Dirección: 			        69 Spetchley Road, Worcester, WR5 2LR, United Kingdom<br />
Número de la empresa: 		14542525<br />
Teléfono 			        +57 322 304 3172 <br />
Correo Electrónico: 		<a href="mailto: info@listoapp.co">info@listoapp.co</a><br />
Última Actualización:		10 de febrero de 2021.</p>


        </div>
    </div>
  );
}
