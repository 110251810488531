import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { FormattedMessage, FormattedNumber } from "react-intl";
import clsx from 'clsx';


interface Props {
	remainingVolume: number
	targetVolumeUnits: string
	remainingVolumeKg: number

	singleSellerBool: boolean
	minimumOfferVolume: number | null
	minimumOfferVolumeKg: number | null
}


const useStyles = makeStyles({
	table: {
		marginTop: "5px",
		marginBottom: "5px"
	},
	rows: {
		fontFamily: '"Helvetica Neue",sans-serif',
		fontSize: "20px",
		lineHeight: "1.2",
		borderBottom: "none",
		paddingLeft: "0px",
		paddingTop: "0px",
		paddingBottom: "2px",
		'@media only screen and (max-width: 768px)': {
		fontSize: "15px"
		}
	},
	rowValues: {
		fontWeight: 500,
	},
});

export default function SimpleTable(props: Props) {
	const classes = useStyles();

	return (
		<TableContainer>
		<Table className={classes.table} size="small" aria-label="a dense table">
			<TableBody>
			
				<TableRow key="remainingVolume">
					<TableCell component="th" scope="row" className={classes.rows}>
						<FormattedMessage id="order.remaining_volume" defaultMessage="Remaining volume"/>
					</TableCell>
					<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
						<FormattedNumber value={props.remainingVolume} style="decimal"/> {props.targetVolumeUnits} {(props.targetVolumeUnits !== "KG") && <>(<FormattedNumber value={props.remainingVolumeKg} style="decimal"/>  KG)</>} {/* eslint-disable-line react/style-prop-object */}
					</TableCell>
				</TableRow>

				<TableRow key="processed">
					<TableCell component="th" scope="row" className={classes.rows}>
						<FormattedMessage id="order.minimum_offer_volume" defaultMessage="Mimimum offer volume"/>
					</TableCell>
					<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
						{
							(props.singleSellerBool === true) ? 
							<FormattedMessage id="order.contribute_full_amount" defaultMessage="You must contribute the full amount"/> :
							<>
								<FormattedNumber value={props.minimumOfferVolume as number} style="decimal"/> {props.targetVolumeUnits} {/* eslint-disable-line react/style-prop-object */}
								{(props.targetVolumeUnits !== "KG") && <>(<FormattedNumber value={props.minimumOfferVolumeKg as number} style="decimal"/> KG)</>} {/* eslint-disable-line react/style-prop-object */}
							</> 
						}
					</TableCell>
				</TableRow>
			
			
			</TableBody>
		</Table>
		</TableContainer>
	);
}