import React, { useState, useRef, useEffect, useContext } from "react";
import { HashLink as Link } from 'react-router-hash-link';

import GoogleMapReact/* , { fitBounds } */ from 'google-map-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import distance from "@turf/distance";
import { FormattedMessage } from "react-intl";
import FilterListIcon from '@material-ui/icons/FilterList';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { Button, CircularProgress } from '@material-ui/core';

import ProfileUserDialog from "../components/ProfileUserDialog";
import ProfileAssocDialog from "../components/ProfileAssocDialog";
import FilterDialog from "../components/FilterDialog";

import { Context } from "../components/Wrapper";
import { RE } from "../utils";
import functions from "./../firebase";

import assoc from "../images/icons/icon_association_50px.png";
import cafe from "../images/icons/icon_cafe_50px.png";
import distributor from "../images/icons/icon_distributor_50px.png";
import farmer from "../images/icons/icon_farmer_50px.png";
import farmer_jeep from "../images/icons/icon_farmer-jeep_50px.png";
import farmer_trader from "../images/icons/icon_farmer-trader_50px.png";
import fruteria from "../images/icons/icon_fruteria_50px.png";
import jeep from "../images/icons/icon_jeep_50px.png";
import restaurant from "../images/icons/icon_restaurant_50px.png";
import shop from "../images/icons/icon_shop_50px.png";
import supermarket from "../images/icons/icon_supermarket_50px.png";
import trader from "../images/icons/icon_trader_50px.png";
import trader_jeep from "../images/icons/icon_trader-jeep_50px.png";
import trio from "../images/icons/icon_trio_50px.png";

import {
    CF,
    FlatDataInterface,
    FilterMapInterface,
    StaticUserMapInterface,
    StaticAssocInterface,
    PublicProfileUserInterface,
    PublicProfileAssocInterface,
    Position
} from "../interfaces";

interface PlacesAutocompleteChildrenIProps { 
    getInputProps: any
    suggestions: [], 
    getSuggestionItemProps: any
    loading: boolean
}

interface responseGeoQuery {
    filter: FilterMapInterface
    static: FlatDataInterface
}

interface Result<T> {
    errorBool: boolean,
    errorKey: null | string
    response:  T
}

interface bnds {
    ne: latLng
    se: latLng
    sw: latLng
    nw: latLng
}

interface latLng {
    lat: number | null
    lng: number | null
}

const bndsInit: bnds = {
    ne: {lat: 5.202154,lng: -73.523462},
    se: {lat: 4.219474,lng: -73.523462},
    sw: {lat: 4.219474,lng: -74.620721},
    nw: {lat: 5.202154,lng: -74.620721}
}

const CF_WEB_SL_geoQuery_onCall = functions.httpsCallable('CF_WEB_SL_geoQuery_onCall');
const CF_WEB_G_getPublicProfileUser_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileUser_onCall');
const CF_WEB_G_getPublicProfileAssoc_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileAssoc_onCall');

export default function Static() {

    const context = useContext(Context);

    const [address, setAddress] = useState("");
    //const [searchPlacesAuto, setSearchPlacesAuto] = useState<boolean>(true);

    const [center, setCenter] = useState({lat: 4.710463, lng: -74.072092});
    const [zoom, setZoom] = useState(6);

    const [searchRadius, setSearchRadius] = useState<number | null>(null);
    const [searchCenter, setSearchCenter] = useState<{lat: number, lng: number}>({lat: 4.710463, lng: -74.072092});

    const [myLocCenter, setMyLocCenter] = useState<{lat: number, lng: number} | null>(null);

    const ctr = useRef<{lat: number, lng: number}>({lat: 4.710463, lng: -74.072092});
    //const zm = useRef<number>(6);
    const bnds = useRef<bnds>(bndsInit);

    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const [staticData, setStaticData] = useState<FlatDataInterface | null>(null);
    const [filterData, setFilterData] = useState<FilterMapInterface | null>(null);

    const [filterRolesSuper, setFilterRolesSuper] = useState<string[]>([]);
    const [filterItemsSuper, setFilterItemsSuper] = useState<string[]>([]);
    const [checkedSwitchSuper, setCheckedSwitchSuper] = useState<boolean>(false);

    const [filterIds, setFilterIds] = useState<string[]>([]);

    const [uid, setUid] = useState<string | null>(null);
    const [assocId, setAssocId] = useState<string | null>(null);

    const [publicProfileUser, setPublicProfileUser] = useState<PublicProfileUserInterface | null>(null);
    const [openProfileUser, setOpenProfileUser] = useState(false); //const [open, setOpen] = useState(false);

    const [publicProfileAssoc, setPublicProfileAssoc] = useState<PublicProfileAssocInterface | null>(null);
    const [openProfileAssoc, setOpenProfileAssoc] = useState(false);

    const [openFilter, setOpenFilter] = useState(false);

    const onSelectPlacesAuto = async (value: string) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(value);
        ctr.current = latLng; //setCoords(latLng);

        setCenter(latLng);
        setZoom(10); //setZoom(9);

        handleClickSearch();
    }

    function getCurrentPosition(options = {timeout: 5000}): Promise<Position> | any {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    const fetchCoordinates = async () => {
        try {
            const { coords } = await getCurrentPosition();
            ctr.current = {lat: coords.latitude, lng: coords.longitude};
            setCenter({lat: coords.latitude, lng: coords.longitude});
            setMyLocCenter({lat: coords.latitude, lng: coords.longitude});
            setZoom(10); //setZoom(9);

            if (RE(context.locale)) {
                setAddress('Ubicación actual');
            } else {
                setAddress('My location');
            }

            //setSearchPlacesAuto(false);
            handleClickSearch();

        } catch (error) {
            console.error(`Error code: ${error.code}; error msg: ${error.message}`);

            if (RE(context.locale)) {
                window.alert('Error en la solicitud de ubicación. Asegúrese de haber permitido la ubicación en la configuración de su navegador y sistema operativo O BIEN Busque con "Buscar ubicación".');
            } else {
                window.alert('Location request failed. Please make sure you have allowed location in your browser and operating system settings OR Search with "Search location".');
            }
        }
    };

    const handleClickOpenProfileUser = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        if (event.currentTarget.id === uid) {
            setOpenProfileUser(true);
        } else {
            setPublicProfileUser(null);
            setOpenProfileUser(true);
            fetchPublicProfileUserData(event.currentTarget.id);
            setUid(event.currentTarget.id);
        }
    };

    const handleCloseProfileUser = () => {
        setOpenProfileUser(false);
    };

    const handleClickOpenProfileAssoc = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        if (event.currentTarget.id === assocId) {
            setOpenProfileAssoc(true);
        } else {
            setPublicProfileAssoc(null);
            setOpenProfileAssoc(true);
            fetchPublicProfileAssocData(event.currentTarget.id);
            setAssocId(event.currentTarget.id);
        }
    };

    const handleCloseProfileAssoc = () => {
        setOpenProfileAssoc(false);
    };

    const fetchPublicProfileUserData = async (uid: string) => {
        try {
            const result: CF<Result<PublicProfileUserInterface>> = await CF_WEB_G_getPublicProfileUser_onCall({publicUid: uid});

            setPublicProfileUser(result.data.response);

        } catch (error) {
            console.log("error")
        }
    }

    const fetchPublicProfileAssocData = async (assocId: string) => {
        try {
            const result: CF<Result<PublicProfileAssocInterface>> = await CF_WEB_G_getPublicProfileAssoc_onCall({assocId: assocId});

            setPublicProfileAssoc(result.data.response);
        } catch (error) {
            console.log("error")
        }
    }

    const calcDist = () => {
        const distLat = distance([bnds.current.ne.lng, bnds.current.ne.lat], [bnds.current.se.lng, bnds.current.se.lat]);
        const distLng = distance([bnds.current.se.lng, bnds.current.se.lat], [bnds.current.sw.lng, bnds.current.sw.lat]);

        let dist: number;
        distLat < distLng ? dist = distLat : dist = distLng;

        if (dist < 5) {
            dist = 5;
        } else if (dist > 25) {
            dist = 25;
        } else {
            dist = Math.round(dist);
        }

        return dist
    }


    useEffect(() => {
        let array: string[] = [];

        if (checkedSwitchSuper === true) {

            if (filterItemsSuper.length === 0 && filterRolesSuper.length === 0) {
                for (let item in filterData?.item.live) {
                    array = array.concat(filterData?.item.live[item] as string[])
                }
                for (let role in filterData?.role.live) {
                    array = array.concat(filterData?.role.live[role] as string[])
                }
            } else {
                filterItemsSuper.forEach((value) => {
                    array = array.concat(filterData?.item.live[value] as string[])
                })
    
                filterRolesSuper.forEach((value) => {
                    array = array.concat(filterData?.role.live[value] as string[])
                })
            }
        } else {
            filterItemsSuper.forEach((value) => {
                array = array.concat(filterData?.item.all[value] as string[])
            })

            filterRolesSuper.forEach((value) => {
                array = array.concat(filterData?.role.all[value] as string[])
            })
        }

        let uniqueIds = [...Array.from(new Set(array))]

        setFilterIds(uniqueIds);

    }, [filterData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClickSearch = async () => {

        document.getElementById("googleMapContainer")?.focus();

        setSearchLoading(true);
        const radius = calcDist();

        setSearchRadius(radius);
        setSearchCenter({lat: ctr.current.lat, lng: ctr.current.lng});
        setCenter({lat: ctr.current.lat, lng: ctr.current.lng});
        //setZoom(zm.current);

        try {
            const result: CF<Result<responseGeoQuery>> = await CF_WEB_SL_geoQuery_onCall({lat: ctr.current.lat, lng: ctr.current.lng, distance: radius});

            setStaticData(result.data.response.static);
            setFilterData(result.data.response.filter);
            setSearchLoading(false);
        } catch (error) {
            console.log(`Error code: ${error.code}; error msg: ${error.message}`);
        }
    }

    const handleClickFilter = async () => {
        setOpenFilter(true);
    }

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };


    const handleChange = ({ center, zoom, bounds }: { center: {lat: number, lng: number}, zoom: number, bounds: bnds }) => {
        ctr.current = center;
        //zm.current = zoom;
        setZoom(zoom);
        bnds.current = bounds;
    }

    return (
        <div className="body">

            <div className="banner">
                <Link to="/explorar#descargar">
                    <FormattedMessage id="downloadBanner" defaultMessage=""/>
                </Link>
            </div>

        <div className="buscar">

            <div className="headerLocation-search">
                <h1 className="header">
                    <FormattedMessage id="explore.explore" defaultMessage="Explore"/>
                </h1>

                <PlacesAutocomplete value={address} onChange={setAddress} onSelect={onSelectPlacesAuto}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteChildrenIProps) => (
                        <div className="location-search">

                                <div className="inputs">
                                    <div>
                                        <FormattedMessage id="search.search_location" defaultMessage="Ubicación de búsqueda">
                                            {(msg: string) => <input {...getInputProps({placeholder: msg, className: 'location-search-input'})}/>}
                                        </FormattedMessage>

                                        <span className="location">
                                            <Button onClick={fetchCoordinates}><MyLocationIcon/></Button>
                                        </span>
                                    </div>
                            
                                    {/* <span className="search">
                                        <Button onClick={handleClickSearch} disabled={searchPlacesAuto || searchLoading}>
                                            <FormattedMessage id="search.search" defaultMessage="Search"/>
                                        </Button>
                                    </span> */}
                                </div>
                            

                                {loading &&
                                    <div className="location-search-suggestions">
                                        <div>
                                            <FormattedMessage id="search.loading" defaultMessage="loading..."/>
                                        </div>
                                    </div>
                                }
                                {suggestions.length > 0 &&
                                    <div className="location-search-suggestions">
                                        {suggestions.map((suggestion: any, i: number) => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            
                                            const style = suggestion.active
                                                ? { backgroundColor: "lightgray", cursor: 'pointer' }
                                                : { backgroundColor: "#ffffff", cursor: 'pointer' };

                                            return (
                                                <div key={i} {...getSuggestionItemProps(suggestion, {className, style})}>
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
            
            <div className="buscarMapContainer">
                {staticData &&
                    <div className="filterButton">
                        <button onClick={handleClickFilter}>
                            <FilterListIcon/>
                        </button>
                        {(filterIds.length > 0 || checkedSwitchSuper === true)&&
                            <div className="indicator">
                            </div>
                        }
                    </div>
                }
                <div className="searchButton">
                    <button onClick={handleClickSearch} disabled={searchLoading}>
                        <FormattedMessage id="explore.search_this_area" defaultMessage="Search this area"/>
                    </button>
                </div>

                {searchLoading && 
                    <div className="loadingScreen">
                        <CircularProgress className="circularProgress" size={50}/>
                    </div>
                }

                <div className="crossHair">
                    <MyLocationIcon/>
                </div>
                

                <FilterDialog
                    filterMap={filterData}
                    setFilterIds={setFilterIds}
                    open={openFilter}
                    handleClose={handleCloseFilter}
                    filterItemsSuper={filterItemsSuper}
                    setFilterItemsSuper={setFilterItemsSuper}
                    filterRolesSuper={filterRolesSuper}
                    setFilterRolesSuper={setFilterRolesSuper}
                    checkedSwitchSuper={checkedSwitchSuper}
                    setCheckedSwitchSuper={setCheckedSwitchSuper}
                />

                <div style={{ height: '90vh', width: '100%' }} tabIndex={0} id="googleMapContainer">
                    <GoogleMapReact
                        center={center}
                        zoom={zoom}
                        options={{maxZoom: 14, clickableIcons: false, fullscreenControl: false}}
                        onChange={handleChange}
                    >
                        {myLocCenter &&
                            <CircleMyLocation
                                lat={myLocCenter.lat}
                                lng={myLocCenter.lng}
                            />
                        }

                        {searchRadius &&
                            <CircleSearchArea
                                lat={searchCenter.lat}
                                lng={searchCenter.lng}
                                radius={searchRadius}
                                zoom={zoom}
                            />
                        }

                        {staticData &&
                            (filterIds.length > 0 ?
                                Array.from(Object.keys(staticData)).map((key, index) => {
                                    if (filterIds.includes(key)) {
                                        const assocBool = staticData[key].assocBool;
                                        if (assocBool === true) {
                                            const x = staticData[key] as StaticAssocInterface;

                                            return (
                                                <IconAssoc
                                                    lat={x.coordinates._latitude}
                                                    lng={x.coordinates._longitude}
                                                    key={index}
                                                    id={x.assocId as string}
                                                    onClick={handleClickOpenProfileAssoc}
                                                    role={x.orgType}
                                                    live={x.postsLiveNum > 0}
                                                />
                                            );
                                        } else {
                                            const x = staticData[key] as StaticUserMapInterface;

                                            return (
                                                <IconUser
                                                    lat={x.coordinatesOffset._latitude}
                                                    lng={x.coordinatesOffset._longitude}
                                                    key={index}
                                                    id={x.uid}
                                                    onClick={handleClickOpenProfileUser}
                                                    role={x.roleKey}
                                                    live={x.postsLiveNum > 0}
                                                />
                                            );
                                        }
                                    } else {
                                        return null
                                    }

                                }) :
                                Array.from(Object.keys(staticData)).map((key, index) => {
                                    
                                    const assocBool = staticData[key].assocBool;
                                    if (assocBool === true) {
                                        const x = staticData[key] as StaticAssocInterface;

                                        return (
                                            <IconAssoc
                                                lat={x.coordinates._latitude}
                                                lng={x.coordinates._longitude}
                                                key={index}
                                                id={x.assocId as string}
                                                onClick={handleClickOpenProfileAssoc}
                                                role={x.orgType}
                                                live={x.postsLiveNum > 0}
                                            />
                                        );
                                    } else {
                                        const x = staticData[key] as StaticUserMapInterface;

                                        return (
                                            <IconUser
                                                lat={x.coordinatesOffset._latitude}
                                                lng={x.coordinatesOffset._longitude}
                                                key={index}
                                                id={x.uid}
                                                onClick={handleClickOpenProfileUser}
                                                role={x.roleKey}
                                                live={x.postsLiveNum > 0}
                                            />
                                        );
                                    }
                                })
                            )
                        }
                    </GoogleMapReact>
                </div>

                <ProfileUserDialog
                    publicProfileUser={publicProfileUser}
                    open={openProfileUser}
                    handleClose={handleCloseProfileUser}
                />
                <ProfileAssocDialog
                    publicProfileAssoc={publicProfileAssoc}
                    open={openProfileAssoc}
                    handleClose={handleCloseProfileAssoc}
                />
            </div>
        
        </div>

        <div id="descargar"></div>
        </div>
    )
}


const CircleMyLocation = ({ lat, lng }: latLng) => {
    return <div className="circle"/>
}

const CircleSearchArea = ({ lat, lng, radius, zoom }: { lat: number, lng: number, radius: number, zoom: number }) => {

    const metersPerPx = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom)
    const Px = Math.round((radius * 1000)/metersPerPx);

    return <div className="circleSearchArea" style={{height: Px*2, width: Px*2, left: -Px, top: -Px}}/>
}

interface interfaceIcon {
    lat: number
    lng: number
    key?: number
    id?: string
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    role: string
    live: boolean
}


const IconUser = ({ lat, lng, key, id, onClick, role, live }: interfaceIcon) => {
    switch(role) {
        case "farmer":
            return (
                <>
                    <img src={farmer} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "traderBuyer" || "traderSeller" || "traderBuyer_traderSeller":
            return (
                <>
                    <img src={trader} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "transporter":
            return (
                <>
                    <img src={jeep} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "farmer_traderBuyer" || "farmer_traderSeller" || "farmer_traderBuyer_traderSeller":
            return (
                <>
                    <img src={farmer_trader} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "farmer_transporter":
            return (
                <>
                    <img src={farmer_jeep} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "traderBuyer_transporter" || "traderSeller_transporter" || "traderBuyer_traderSeller_transporter":
            return (
                <>
                    <img src={trader_jeep} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "farmer_traderBuyer_transporter" || "farmer_traderSeller_transporter" || "farmer_traderBuyer_traderSeller_transporter":
            return (
                <>
                    <img src={trio} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        default:
            return null
    }
}

const IconAssoc = ({ lat, lng, key, id, onClick, role, live }: interfaceIcon) => {
    switch(role) {
        case "orgAssoc":
            return (
                <>
                    <img src={assoc} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgDistributor":
            return (
                <>
                    <img src={distributor} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgShop":
            return (
                <>
                    <img src={shop} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgRestaurant":
            return (
                <>
                    <img src={restaurant} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgSupermarket":
            return (
                <>
                    <img src={supermarket} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgCafe":
            return (
                <>
                    <img src={cafe} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgFruteria":
            return (
                <>
                    <img src={fruteria} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        case "orgOther":
            return (
                <>
                    <img src={assoc} alt="" className="iconImgContainer" id={id} onClick={onClick}/>
                    {live && <div className="liveIndicator"/>}
                </>
            );
        default:
            return null
    }
}
