import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { NavLink } from "react-router-dom";
//import Leaderboard from "../components/Leaderboard";

import functions from "./../firebase";
import { Button } from "@material-ui/core";

//import CircularIntermediate from "../components/CircularIntermediate";
import CircularProgress from '@material-ui/core/CircularProgress';
import iPhone from "../images/screenshots/iPhone11_postBerenjenaSummary_copy.png";

interface CloudResult {
  data: Result;
}

interface Result {
  errorBool: boolean
  errorKey: string | null
  response: null
}


var CF_WEB_G_sendDownloadSms_onCall = functions.httpsCallable('CF_WEB_G_sendDownloadSms_onCall');


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        fontFamily: '"Helvetica Neue",sans-serif',
        fontSize: '30px',
        color: 'white',
        backgroundColor: '#14233c',
        padding: '20px',
      '& > p': {
        fontWeight: '600',
      },
      '& > span': {
        fontSize: '20px',
      },
      '& > .privacy': {
        fontSize: '15px',
        fontWeight: '400',
      },
      '& > .MuiButton-root': {
        fontSize: '15px',
        color: 'white',
        textTransform: 'none',
        width: '150px',
        marginTop: '10px',
        marginBottom:'10px',
        //marginRight: '0.5rem',
        backgroundColor: '#2991cc',
      },
      '& > .MuiButton-contained:hover.Mui-disabled, .MuiButton-root:hover.Mui-disabled': {
        backgroundColor: '#2991cc',
      },
      '& > .Mui-disabled': {
        backgroundColor: 'grey',
      },
    },
    textField: {
        backgroundColor: 'white',
        fontSize: '15px',
        width: '150px',
        marginTop: '10px',
        marginBottom:'0px',
        marginRight: '10px',
        '& > *': {
            padding: '10px',
            color: 'grey',
            
        },
    },
    loading: {
      position: 'absolute',
    }
  }),
);

function DescargarButton(props: {submitted: boolean, loading: boolean}) {

  const classes = useStyles();

  if (props.loading) {
    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={true}
    >
        Enviar
        <CircularProgress className={classes.loading} size={30}/>
    </Button>
    );
  }

  return (
    <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={props.submitted}
    >
        {(props.submitted) ? <>Enviado</> : <>Enviar</>}
    </Button>
  );
}


const Descargar: React.FC = () => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const {register, handleSubmit, errors, clearErrors} = useForm();

    const onSubmit = (data: {phoneNumber: string}) => {
        console.log(`+57${data.phoneNumber}`)
        setLoading(true);
        CF_WEB_G_sendDownloadSms_onCall({phoneNumber: `+57${data.phoneNumber}`}).then(function(result: CloudResult) {
          if (result.data.errorBool) {
            window.alert(result.data.errorKey)
          } else {
            setSubmitted(true);
            setLoading(false);
          }
        }).catch(function(error: {code: string}) {
          window.alert(error.code)
        })
    }

    return (
        <div className="body">
        <div className="answers">

            <div className="descargarContainer">

              <div className="androidPreview">
                  <img className="android" src={iPhone} alt=""/>
              </div>

                <form className={classes.root} autoComplete="off" onSubmit={handleSubmit(onSubmit) }>
                    <p>¿No estás en tu teléfono? Agrega un número de teléfono de Colombia válido y te enviaremos un mensaje de texto con un enlace para descargar Listo</p>
                    <TextField
                        id="outlined-basic"
                        placeholder="3120000000"
                        name="phoneNumber"
                        onChange={() => clearErrors("phoneNumber")}
                        inputRef={register({required: true, pattern: RegExp('^[0-9]{10}$')})}
                        InputProps={{className: classes.textField}}
                        variant="outlined"
                        type="input"
                    />

                    {/*
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={submitted}
                        >
                            {(submitted) ? <>Submitted</> : <>Submit</>}
                        </Button>
                    */}
                    <DescargarButton submitted={submitted} loading={loading}/>

                    <br/>
                    <span>
                        {(errors.phoneNumber) ? <p>No válido</p> : <p><br/></p>}
                        {(submitted) ? <p>Enviado, por favor verifica tu teléfono</p> : <p><br/></p>}
                    </span>
                    <p className="privacy">Ver nuestro <NavLink to="/privacidad"><strong>Aviso de Privacidad</strong></NavLink></p>
                </form>

              <div className="backgroundDescargar"/>
  
            </div>

            {/*<Leaderboard/>*/}

        </div>
        </div>
    );
}

export default Descargar;