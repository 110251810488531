import firebase from "firebase/app";
import "firebase/functions";
import config from "./firebase-config.json";

firebase.initializeApp(config.result.sdkConfig);

const functions = firebase.functions();

export default functions;

/* 
const CF_WEB_B_getPostPublic_onCall = functions.httpsCallable('CF_WEB_B_getPostPublic_onCall'); //PostPublic
const CF_WEB_B_getOrderPublic_onCall = functions.httpsCallable('CF_WEB_B_getOrderPublic_onCall'); //OrderPublic

const CF_WEB_G_getPublicProfileUser_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileUser_onCall'); //UserPublic
const CF_WEB_G_getPublicProfileAssoc_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileAssoc_onCall'); //AssocPublic

const CF_WEB_U_spatialPosts_onCall = functions.httpsCallable('CF_WEB_U_spatialPosts_onCall'); //PostsCarousel
const CF_WEB_U_spatialOrders_onCall = functions.httpsCallable('CF_WEB_U_spatialOrders_onCall'); //OrdersCarousel

const CF_WEB_G_sendDownloadSms_onCall = functions.httpsCallable('CF_WEB_G_sendDownloadSms_onCall'); //DownloadSMS

const CF_WEB_B_geoQuery_v1_onCall = functions.httpsCallable('CF_WEB_B_geoQuery_v1_onCall'); //PostSearch
const CF_WEB_O_orderSearch_v1_onCall = functions.httpsCallable('CF_WEB_O_orderSearch_v1_onCall'); //OrderSearch

const CF_WEB_SL_geoQuery_onCall = functions.httpsCallable('CF_WEB_SL_geoQuery_onCall'); //Explore

 */
