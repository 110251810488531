import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "10px 20px 10px 20px",
      width: "100%",
      backgroundColor: "#f9f9f9"
    },
  }),
);

export default function SimplePaper(props: any) {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={clsx(classes.root, props.className)}>
        {props.children}
    </Paper>
  );
}
