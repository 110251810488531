import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer4 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-compradores-cómo-realizar-los-intercambios">Para compradores, ¿cómo realizar los intercambios?</h1>
            
            <p>Si usted ha enviado una oferta a una publicación individual y esta ha sido aceptada por el otro usuario; o si ha aceptado una solicitud de contribución a su pedido, ahora podrá continuar con el intercambio.</p>
            
            <br/>
            <ul>
              <li><Link to="#aceptar-una-solicitud-de-un-vendedor">Aceptar una solicitud de un vendedor</Link></li>
              <li><Link to="#ingresar-el-código-de-intercambio">Ingresar el código de intercambio</Link></li>
            </ul>
            <br/>
            
            <p>En la aplicación, <strong>las ofertas aceptadas</strong> y <strong>las solicitudes recibidas</strong> aparecerán:</p>
            <ul>
              <li>en <strong>la campana de notificaciones</strong> ubicada en <strong>la página de inicio</strong>;</li>
              <li>en la pestaña <strong>Intercambio</strong>, en la ventana <strong>Comprar</strong>, en las secciones <strong>Pedidos activos</strong> y <strong>Ofertas activas</strong>.</li>
            </ul>

            <blockquote>
              <p>Para acceder a una oferta que ha sido aceptada, <strong>haga clic sobre la oferta</strong>, esto lo llevará a una nueva ventana donde verá <strong>las casillas de intercambio</strong>.</p>
            </blockquote>

            <br/>

            <h2 id="aceptar-una-solicitud-de-un-vendedor">Aceptar una solicitud de un vendedor</h2>
            <p>Para aceptar una solicitud:</p>

            <ol>
              <li>
                <strong>Haga clic sobre la solicitud</strong> y esto lo llevará a una nueva ventana donde podrá ver <strong>el resumen de la solicitud</strong>.
              </li>
              <li>
                Oprima <strong>el botón Aceptar</strong> y seguidamente <strong>el botón Confirmar</strong>.
                <blockquote>
                  <p>Puede llamar al otro usuario para discutir los detalles de la solicitud, el número de contacto estará en la información de la solicitud.</p>
                </blockquote>
              </li>
            </ol>

            <br/>
            <br/>
            <h2 id="ingresar-el-código-de-intercambio">Ingresar el código de intercambio</h2>
            <p>Cuando haya llegado la fecha de intercambio, usted debe <strong>ingresar el código de intercambio</strong> para verificar que el otro usuario sí sea con quien negoció en Listo.</p>

            <ol>
              <li>
                Diríjase a la publicación del producto que va intercambiar.
              </li>
              <li>
                <strong>Haga clic en la publicación</strong> y podrá observar <strong>cuatro casillas</strong>.
              </li>
              <li>Allí debe <strong>ingresar el código de intercambio del otro usuario</strong>.
                <blockquote>
                  <p>Para esto es necesario que <strong>le solicite al otro usuario</strong> su código de intercambio personal.</p>
                </blockquote>
              </li>
              <li>
                <strong>Escriba el código</strong> en estas casillas.
              </li>
              <li><strong>Oprima el botón Enviar código</strong> y seguidamente <strong>el botón Confirmar</strong>.</li>
            </ol>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer4;