import React, { useEffect, useState, useContext } from "react";
import { HashLink as Link } from 'react-router-hash-link';

import { FormattedMessage } from "react-intl";
import InfiniteScroll from 'react-infinite-scroll-component';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Button, AppBar, Tabs, Tab, Box, makeStyles } from '@material-ui/core';
import MyLocationSharpIcon from '@material-ui/icons/MyLocationSharp';
import MapIcon from '@material-ui/icons/Map';
import ReorderIcon from '@material-ui/icons/Reorder';
import  { Breakpoint, BreakpointProvider } from 'react-socks';

import ItemOption from "../components/options/Item";
import VarietyOption from "../components/options/Variety";
import ProcessedOption from "../components/options/Processed";
import VolumeOption from "../components/options/Volume";
import SortByOption from "../components/options/SortBy";
import DistanceOption from "../components/options/Distance";
import DeliveryOption from "../components/options/Delivery";
//import PriceOption from "../components/options/Price";

import OrderSearchItem from "../components/OrderSearchItem";

import GoogleMapReactOrder from "../components/GoogleMapReactOrder";

import { Context } from "../components/Wrapper";
import CircularIntermediate from "../components/CircularIntermediate";
import functions from "./../firebase";

import { RE } from "../utils";

import {
    CF,
    OrderSearchParamsInterface, //BuySearchParamsInterface,
    BuySearchSortOptions,
    OrderModelPublicInterfaceDistance, //PostDataMinimalInterface,
    Position
} from "../interfaces";


interface Result {
    errorBool: boolean,
    errorKey: null | string
    response: {
        radiusKeySearch: number | null,
        orderDataArrayBatch: OrderModelPublicInterfaceDistance[] //postDataArray: PostDataMinimalInterface[]
    }
}

interface PlacesAutocompleteChildrenIProps { 
    getInputProps: any //({placeholder, className}: {placeholder: string, className: string}) => {}, 
    suggestions: [], 
    getSuggestionItemProps: any //(suggestion: any, {}) => {}, 
    loading: boolean 
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {/* <Typography>{children}</Typography> */}
                    {children}
                </Box>
            )}
        </div>
    );
}
  
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((/* theme: Theme */) => ({
    root: {
        //flexGrow: 1,
        //color: 'white',
        //theme.palette.background.paper,
        '& .MuiAppBar-root': {
            width: '50px',
            height: '50px',
            borderRadius: '100%',
            //color: 'white',
            backgroundColor: 'rgb(60, 206, 105)',
            fontSize: '16px',
            opacity: '1',
            //bottom: '10px',
            //rigth: '10px',
            
        },
        '& .MuiAppBar-positionFixed': {
            top: 'auto',
            bottom: '30px',
            right: '30px',
        },
        '& .MuiBox-root': {
            padding: '0px 0px 0px 0px',
        },
        '& .MuiTabs-root': {
            //width: '50px'
        },
        '& .MuiTab-root': {
            position: 'absolute',
            width: '50px',
            minWidth: '0px',
        },
        '& .MuiTab-textColorInherit': {
            opactity: 1
        },
        '& .PrivateTabIndicator-colorSecondary-7, & .PrivateTabIndicator-colorSecondary-26': {
            opacity: '0'
        },
        '& .PrivateTabIndicator-root': {
            backgroundColor: 'transparent'
        },
        '& .info': {
            fontSize: 'smaller',
            //color: 'white',
            listStylePosition: 'inside'
        },
        '& .map': {
            position: 'relative',
            width: '100%',
            '& > *': {
                margin: '0px'
            }
        }
    },
    rootSharpIcon: {
        marginLeft: "5px"
    }
}));


//var CF_WEB_B_geoQuery_v1_onCall = functions.httpsCallable('CF_WEB_B_geoQuery_v1_onCall');
var CF_WEB_O_orderSearch_v1_onCall = functions.httpsCallable('CF_WEB_O_orderSearch_v1_onCall');


/* const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        margin: "10px",
        width: "200px",
      },
    },
  }),
);
*/

/* const style = {
    height: 160,
    border: "1px solid green",
    margin: 6,
    padding: 8
};  */


const OrderSearch: React.FC = () => {

    const context = useContext(Context);

    //Google Places

    const [address, setAddress] = useState("");
    const [coords, setCoords] = useState({lat: 0, lng: 0}); //const [coords, setCoords] = useState({lat: null, lng: null});
    //const [coords, setCoords] = useState<{lat: number | null, lng: number | null}>({lat: null, lng: null});

    const [hasMore, setHasMore] = useState(true);

    const [hover, setHover] = useState("");
    //const hoverRef = useRef("");

    //CF

    //const [postDataArray, setPostDataArray] = useState([] as PostDataMinimalInterface[]);
    const [orderDataArray, setOrderDataArray] = useState<OrderModelPublicInterfaceDistance[]>([]);
    //const [postDataArray, setPostDataArray] = useState(postDataArrayConst);
    const [radius, setRadius] = useState<number | null>(null);
    const [lastDocId, setLastDocId] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState(true);

    const [epi, setEpi] = useState(true);
    const [noResults, setNoResults] = useState(false);
    const [errorCF, setErrorCF] = useState<string | null>(null);

    //options

    const [sortBy, setSortBy] = useState<BuySearchSortOptions>(BuySearchSortOptions.sortByDistanceLowHigh);
    const [itemSelect, setItemSelect] = useState<string | null>(null);
    const [distanceMax, setDistanceMax] = useState<number | null>(400); // ** is this supposed to be the same as radius? No...
    const [variety, setVariety] = useState<string | null>(null);
    const [processed, setProcessed] = useState<string | null>(null);
    const [collectionBool, setCollectionBool] = useState(false); //const [deliveryBool, setDeliveryBool] = useState(false);
    //const [price, setPrice] = useState<{min: number | null, max: number | null}>({min: null, max: null});
    const [volumeUnits, setVolumeUnits] = useState<string | null>(null);
    const [volume, setVolume] = useState<{min: number | null, max: number | null}>({min: null, max: null});

    //tab menu

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        window.scrollTo(0, 232);
    };

    const handleChangeTabMap = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        window.scrollTo(0, 392);
    };

    // Option handles

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setState:  React.Dispatch<React.SetStateAction<string>>) => {
        setState(event.target.value);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, setState:  React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
        setState(event.currentTarget);
    };
    const handleClose = (setState:  React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
        setState(null);
    };

    /* const onSearch = () => {
        setSearch(true);
    } */

    const onReset = () => {
        setSortBy(BuySearchSortOptions.sortByDistanceLowHigh);
        setItemSelect(null);
        setDistanceMax(400);
        setVariety(null);
        setProcessed(null);
        setCollectionBool(false);
        //setPrice({min: null, max: null});
        setVolumeUnits(null);
        setVolume({min: null, max: null});

        setNoResults(false);
    }

    const fetchMoreData = async () => {

        let R: number | null;

        if (sortBy === BuySearchSortOptions.sortByDistanceLowHigh) {
            R = radius;
        } else if (distanceMax === null) {
            R = radius;
        } else {
            R = distanceMax
        }

        try {

            let OrderSearchParamsInterface: OrderSearchParamsInterface = {
                sortOption: sortBy,
                lat: coords.lat,
                lng: coords.lng,
                radius: R,
                lastDocId: lastDocId,
                itemOrCatKey: itemSelect,
                variety: variety,
                processed: processed,
                collectionBool: collectionBool,
                volUnits: volumeUnits,
                volMin: volume.min,
                volMax: volume.max
            }

            console.log(OrderSearchParamsInterface);

            //const result: CloudResultGeoQuery = await CF_WEB_B_geoQuery_v1_onCall(BuySearchParamsInterface);
            const result: CF<Result> = await CF_WEB_O_orderSearch_v1_onCall(OrderSearchParamsInterface);

            if (result.data.errorBool === true) {
                //does hasMore need to be set? Depends on how you want to proceed with errors beyond showing a message
                setIsLoading(false);
                setErrorCF(`Error: ${result.data.errorKey}`);
                return;
            }

            //if (result.data.response.orderDataArray.length < 20) {
            if (result.data.response.orderDataArrayBatch.length < 20) {
                setHasMore(false);
            }
            setRadius(result.data.response.radiusKeySearch);

            //if (result.data.response.orderDataArray.length === 0) {
            if (result.data.response.orderDataArrayBatch.length === 0) {
                return;
            }

            //setLastDocId(result.data.response.orderDataArray.slice(-1)[0].id)
            setLastDocId(result.data.response.orderDataArrayBatch.slice(-1)[0].id)

            //setOrderDataArray(orderDataArray.concat(result.data.response.orderDataArray));
            setOrderDataArray(orderDataArray.concat(result.data.response.orderDataArrayBatch));
        } catch (error) {
            console.log(`Error code: ${error.code}; error msg: ${error.message}`);
            setErrorCF(`Error code: ${error.code}; error msg: ${error.message}`);
        }
    };

    //const fetchData = async (value: string) => {
    const fetchData = async () => {

        if (orderDataArray.length > 0) {
            setOrderDataArray([]);
            setHasMore(true);
        }

        if (noResults === true) {
            setNoResults(false);
        }

        if (epi) {setEpi(false);}
        setSearch(true);
        setIsLoading(true);

        /* const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(value);
        setCoords(latLng); */

        let R: number | null;

        if (sortBy === BuySearchSortOptions.sortByDistanceLowHigh) {
            R = null;
        } else {
            R = distanceMax;
        }

        try {
            let OrderSearchParamsInterface: OrderSearchParamsInterface = {
                sortOption: sortBy,
                lat: coords.lat,
                lng: coords.lng,
                radius: R,
                lastDocId: null,
                itemOrCatKey: itemSelect,
                variety: variety,
                processed: processed,
                collectionBool: collectionBool,
                volUnits: volumeUnits,
                volMin: volume.min,
                volMax: volume.max
            }

            console.log(OrderSearchParamsInterface);

            const result: CF<Result> = await CF_WEB_O_orderSearch_v1_onCall(OrderSearchParamsInterface);

            console.log(result)

            if (result.data.errorBool === true) {
                //does hasMore need to be set? Depends on how you want to proceed with errors beyond showing a message
                setIsLoading(false);
                setErrorCF(`Error: ${result.data.errorKey}`);
                return;
            }

            //if (result.data.response.orderDataArray.length === 0) {
            if (result.data.response.orderDataArrayBatch.length === 0) {
                setHasMore(false);
                setIsLoading(false);
                setNoResults(true);
                return;
            }

            //if (result.data.response.orderDataArray.length < 20) {
            if (result.data.response.orderDataArrayBatch.length < 20) {
                setHasMore(false);
            }

            setRadius(result.data.response.radiusKeySearch);
            //setLastDocId(result.data.response.orderDataArray.slice(-1)[0].id)
            setLastDocId(result.data.response.orderDataArrayBatch.slice(-1)[0].id)
            //setOrderDataArray(result.data.response.orderDataArray);
            setOrderDataArray(result.data.response.orderDataArrayBatch);

            setIsLoading(false);
            
        } catch (error) {
            console.log(`Error code: ${error.code}; error msg: ${error.message}`);
            setErrorCF(`Error code: ${error.code}; error msg: ${error.message}`);
        }
    }

    const onSelect = async (value: string) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(value);
        setCoords(latLng);
    }

    useEffect(() => {
        //a way to test if useEffect is being run for the first time, so as to ensure that the CF is not run without coords, but not strictly necessary...
        if (coords.lat === 0 && coords.lng === 0) { //if (coords === {lat: 0, lng: 0}) {
            //console.log('useEffect firstRun');
            //pass
        } else {
            setSearch(false);
        }
    },[coords, sortBy, itemSelect, distanceMax, variety, processed, collectionBool, volumeUnits, volume]);


    function getCurrentPosition(options = {timeout: 5000}): Promise<Position> | any {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    const fetchCoordinates = async () => {
        try {
            const { coords } = await getCurrentPosition();
            setCoords({lat: coords.latitude, lng: coords.longitude});

            if (RE(context.locale)) {
                setAddress('Ubicación actual');
            } else {
                setAddress('My location');
            }

        } catch (error) {
            console.error(`Error code: ${error.code}; error msg: ${error.message}`);
            //window.alert("Allow location OR search by location");

            if (RE(context.locale)) {
                window.alert('Error en la solicitud de ubicación. Asegúrese de haber permitido la ubicación en la configuración de su navegador y sistema operativo O BIEN Busque con "Buscar ubicación".');
            } else {
                window.alert('Location request failed. Please make sure you have allowed location in your browser and operating system settings OR Search with "Search location".');
            }
        }
    };

    const onHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        //console.log(event.currentTarget.id);

        setHover(event.currentTarget.id);
        //hoverRef.current = event.currentTarget.id;
        //console.log(hoverRef.current);
    }
    
    return (
        <div className="body">

            <div className="banner">
                <Link to="/pedidos#descargar">
                    <FormattedMessage id="downloadBanner" defaultMessage=""/>
                </Link>
            </div>

        <div className="searchContainer">

            <BreakpointProvider>
            <Breakpoint customQuery="(min-width: 768px)">

            <div className="search-results-map">

                <div className="search-results">

                    <h2 className="header">
                        <FormattedMessage id="search.search_for_orders" defaultMessage="Search for orders"/>
                    </h2>

                    <div className="options"> 

                        <div className="primary">
                            <SortByOption
                                itemSelect={itemSelect}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                handleClick={handleClick}
                                handleChange={handleChange}
                                handleClose={handleClose}
                            />

                            <ItemOption
                                itemSelect={itemSelect}
                                setItemSelect={setItemSelect}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                setVariety={setVariety}
                                setProcessed={setProcessed}
                                setVolume={setVolume}
                                setVolumeUnits={setVolumeUnits}
                                handleClick={handleClick}
                            />

                            <DeliveryOption
                                deliveryBool={collectionBool}
                                setDeliveryBool={setCollectionBool}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            />
                        </div>

                        <div className="secondary">
                            <VarietyOption
                                item={itemSelect}
                                variety={variety}
                                setVariety={setVariety}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            />
                            
                            <ProcessedOption
                                item={itemSelect}
                                processed={processed}
                                setProcessed={setProcessed}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            />

                            <DistanceOption
                                sortBy={sortBy}
                                distanceMax={distanceMax}
                                setDistanceMax={setDistanceMax}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            />

                            <VolumeOption
                                item={itemSelect}
                                volumeUnits={volumeUnits}
                                setVolumeUnits={setVolumeUnits}
                                volume={volume}
                                setVolume={setVolume}
                                handleClose={handleClose}
                                order
                            />
                        </div> 
                    </div>
                

                    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={onSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteChildrenIProps) => (
                            <div className="location-search">

                                    <FormattedMessage id="search.search_location" defaultMessage="Ubicación de búsqueda">
                                        {(msg: string) => <input {...getInputProps({placeholder: msg, className: 'location-search-input'})}/>}
                                    </FormattedMessage>
                                    {/* <input {...getInputProps({placeholder: "Ubicación de búsqueda", className: 'location-search-input'})}/> */}

                                    <span className="location">
                                        <Button onClick={fetchCoordinates}><MyLocationSharpIcon/></Button>
                                    </span>
                            
                                    <span className="search">
                                        <Button onClick={fetchData} disabled={search}>
                                            <FormattedMessage id="search.search" defaultMessage="Search"/>
                                        </Button>
                                    </span>
                                    <span className="reset">
                                        <Button onClick={onReset}>
                                            <FormattedMessage id="search.clear" defaultMessage="Clear"/>
                                        </Button>
                                    </span>
                             

                                    {loading &&
                                        <div className="location-search-suggestions">
                                            <div>
                                                <FormattedMessage id="search.loading" defaultMessage="loading..."/>
                                            </div>
                                        </div>
                                    }
                                    {suggestions.length > 0 &&
                                        <div className="location-search-suggestions">
                                            {suggestions.map((suggestion: any, i: number) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                
                                                const style = suggestion.active
                                                    ? { backgroundColor: "lightgray", cursor: 'pointer' }
                                                    : { backgroundColor: "#ffffff", cursor: 'pointer' };

                                                return (
                                                    <div key={i} {...getSuggestionItemProps(suggestion, {className, style})}>
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <div className="infiniteScrollContainer">

                        {isLoading && <LoadingSpinner/>}

                        {(orderDataArray.length > 0)
                            ? 
                            <InfiniteScroll
                                dataLength={orderDataArray.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                //loader={<h4>Loading...</h4>}
                                loader={<LoadingSpinner/>}
                                //scrollableTarget="scrollableDiv"
                                //height={400}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                    <b><br/></b>
                                    </p>
                                }
                            >
                                {orderDataArray.map((order) => (
                                    <div key={order.id} id={order.id} /* style={style} */ onMouseEnter={onHover}>
                                        <OrderSearchItem orderData={order}/>
                                    </div>
                                ))}
                            </InfiniteScroll>
                            : null
                        } 
                    </div>

                    <div className="info">
                        {epi && <div className="epi">
                            <ol>
                                <li>
                                    <FormattedMessage id="search.enter_location" defaultMessage='Enter location: "Search location" or '/>
                                    <MyLocationSharpIcon className={classes.rootSharpIcon}/>
                                </li>
                                <li>
                                    <FormattedMessage id="search.click_search" defaultMessage='Click "Search"'/>
                                </li>
                                <li>
                                    <FormattedMessage id="search.refine_search" defaultMessage="Refine search with options"/>
                                </li>
                            </ol>
                        </div>}
                        {noResults &&
                        <p>
                            <FormattedMessage id="search.no_results" defaultMessage="No results"/>
                        </p>}
                        {errorCF && 
                        <p>
                            <FormattedMessage id="search.error" defaultMessage="Error"/>
                        </p>}
                    </div>

                </div>

                <div className="map">
                        {/* <SearchMap postDataArray={postDataArray} hover={hover} /> */}
                        <GoogleMapReactOrder orderDataArray={orderDataArray} hover={hover}/>
                </div>

            </div>
            </Breakpoint>

            
            <Breakpoint customQuery="(max-width: 767px)">
                <div className={classes.root}>
                {/* <AppBar position="sticky">
                    {value == 1 ?
                        <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
                            <Tab label="Se" {...a11yProps(0)} className="visibleTab"/>
                            <Tab label="Ma" {...a11yProps(1)} className="hiddenTab"/>
                        </Tabs> :
                        <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
                            <Tab label="Se" {...a11yProps(0)} className="hiddenTab"/>
                            <Tab label="Ma" {...a11yProps(1)} className="visibleTab"/>
                        </Tabs>
                    }
                </AppBar> */}
                <TabPanel value={value} index={0}>
                    <div className="search-results">

                        <h2 className="header">
                            <FormattedMessage id="search.search_for_orders" defaultMessage="Search for orders"/>
                        </h2>

                        <div className="options"> 

                            <div className="primary">
                                <SortByOption
                                    itemSelect={itemSelect}
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    handleClick={handleClick}
                                    handleChange={handleChange}
                                    handleClose={handleClose}
                                />

                                <ItemOption
                                    itemSelect={itemSelect}
                                    setItemSelect={setItemSelect}
                                    sortBy={sortBy}
                                    setSortBy={setSortBy}
                                    setVariety={setVariety}
                                    setProcessed={setProcessed}
                                    setVolume={setVolume}
                                    setVolumeUnits={setVolumeUnits}
                                    handleClick={handleClick}
                                />

                                <DeliveryOption
                                    deliveryBool={collectionBool}
                                    setDeliveryBool={setCollectionBool}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                />
                            </div>

                            <div className="secondary">
                                <VarietyOption
                                    item={itemSelect}
                                    variety={variety}
                                    setVariety={setVariety}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                />
                                
                                <ProcessedOption
                                    item={itemSelect}
                                    processed={processed}
                                    setProcessed={setProcessed}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                />

                                <DistanceOption
                                    sortBy={sortBy}
                                    distanceMax={distanceMax}
                                    setDistanceMax={setDistanceMax}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                />

                                <VolumeOption
                                    item={itemSelect}
                                    volumeUnits={volumeUnits}
                                    setVolumeUnits={setVolumeUnits}
                                    volume={volume}
                                    setVolume={setVolume}
                                    handleClose={handleClose}
                                    order
                                />
                            </div> 
                        </div>


                        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={onSelect}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteChildrenIProps) => (
                                <div className="location-search">

                                    <FormattedMessage id="search.search_location" defaultMessage="Ubicación de búsqueda">
                                        {(msg: string) => <input {...getInputProps({placeholder: msg, className: 'location-search-input'})}/>}
                                    </FormattedMessage>
                                    {/* <input {...getInputProps({placeholder: "Ubicación de búsqueda", className: 'location-search-input'})}/> */}

                                    <span className="location">
                                        <Button onClick={fetchCoordinates}><MyLocationSharpIcon/></Button>
                                    </span>
                            
                                    <span className="search">
                                        <Button onClick={fetchData} disabled={search}>
                                            <FormattedMessage id="search.search" defaultMessage="Search"/>
                                        </Button>
                                    </span>
                                    <span className="reset">
                                        <Button onClick={onReset}>
                                            <FormattedMessage id="search.clear" defaultMessage="Clear"/>
                                        </Button>
                                    </span>
                                    

                                    {loading &&
                                        <div className="location-search-suggestions">
                                            <div>
                                                <FormattedMessage id="search.loading" defaultMessage="loading..."/>
                                            </div>
                                        </div>
                                    }
                                    {suggestions.length > 0 &&
                                        <div className="location-search-suggestions">
                                            {suggestions.map((suggestion: any, i: number) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                
                                                const style = suggestion.active
                                                    ? { backgroundColor: "lightgray", cursor: 'pointer' }
                                                    : { backgroundColor: "#ffffff", cursor: 'pointer' };

                                                return (
                                                    <div key={i} {...getSuggestionItemProps(suggestion, {className, style})}>
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                </div>
                            )}
                        </PlacesAutocomplete>

                        <div className="infiniteScrollContainer">

                            {isLoading && <LoadingSpinner/>}

                            {(orderDataArray.length > 0)
                                ? 
                                <InfiniteScroll
                                    dataLength={orderDataArray.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    //loader={<h4>Loading...</h4>}
                                    loader={<LoadingSpinner/>}
                                    //scrollableTarget="scrollableDiv"
                                    //height={400}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                        <b><br/></b>
                                        </p>
                                    }
                                >
                                    {orderDataArray.map((order) => (
                                        <div key={order.id} id={order.id} /* style={style} */ /* onMouseEnter={onHover} */>
                                            <OrderSearchItem orderData={order}/>
                                        </div>
                                    ))}
                                </InfiniteScroll>
                                : null
                            } 
                        </div>

                        <div className="info">
                            {epi && <div className="epi">
                                <ol>
                                    <li>
                                        <FormattedMessage id="search.enter_location" defaultMessage='Enter location: "Search location" or '/>
                                        <span className="sharpIcon">
                                            <MyLocationSharpIcon/>
                                        </span>
                                    </li>
                                    <li>
                                        <FormattedMessage id="search.click_search" defaultMessage='Click "Search"'/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="search.refine_search" defaultMessage="Refine search with options"/>
                                    </li>
                                </ol>
                            </div>}
                            {noResults &&
                            <p>
                                <FormattedMessage id="search.no_results" defaultMessage="No results"/>
                            </p>}
                            {errorCF && 
                            <p>
                                <FormattedMessage id="search.error" defaultMessage="Error"/>
                            </p>}
                        </div>

                        </div>
                </TabPanel>
                <TabPanel value={value} index={1}>

                    <h2 className="header">
                        <FormattedMessage id="search.search_for_orders" defaultMessage="Search for orders"/>
                    </h2>

                    <div className="map">
                        {/* <SearchMap postDataArray={postDataArray} hover={hover} /> */}
                        <GoogleMapReactOrder orderDataArray={orderDataArray} mobile={true}/* hover={hover} *//>
                    </div>
                </TabPanel>
            
                {orderDataArray.length > 0 &&
                    <AppBar position="fixed">
                        {value === 1 ?
                            <Tabs value={value} onChange={handleChangeTabMap} aria-label="simple tabs example">
                                <Tab label={<ReorderIcon fontSize="large"/>} {...a11yProps(0)} className="visibleTab"/>
                                <Tab label={""} {...a11yProps(1)} className="hiddenTab"/>
                            </Tabs> :
                            <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
                                <Tab label={""} {...a11yProps(0)} className="hiddenTab"/>
                                <Tab label={<MapIcon fontSize="large"/>} {...a11yProps(1)} className="visibleTab"/>
                            </Tabs>
                        }
                    </AppBar>
                }
                </div>
            </Breakpoint>


            </BreakpointProvider>

        </div>

        <div id="descargar"></div>
        </div>
    );
}

export default OrderSearch;

function LoadingSpinner() {
    return (
        <div className="spinnerContainer">
            {<CircularIntermediate/>}
        </div>
    );
}
