import React, { Component } from "react";
 
class Answer6 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-asociaciones-cómo-participar-en-una-solicitud-de-comprador">Para asociaciones, ¿cómo participar en una solicitud de comprador?</h1>
            
            <p>Uno de los mecanismos de comercialización de Listo es <strong>la búsqueda de pedidos creados por compradores</strong>. Al buscar estos pedidos usted podrá <strong>solicitar participar</strong> en ellos, para así vender sus productos. <strong>Los pedidos de los compradores</strong> pueden ser <strong>grupales</strong> o <strong>individuales</strong>.</p>

            <br/>
            <h2 id="en-la-aplicación">En la aplicación</h2>
            <ol>
              <li>
                En <strong>la página de inicio</strong>, diríjase a <strong>la sección Compra</strong>.
              </li>
              <li>
                <strong>Oprima el botón Buscar</strong> ubicado justo al lado del nombre de la sección.
              </li>
              <li>Se desplegará la lista de productos y podrá <strong>seleccionar el producto</strong> que desea vender
                <blockquote>
                  <p><strong>Si hay pedidos activos</strong> de ese producto, los resultados aparecerán en <strong>una lista</strong> y también podrá verlos en el mapa para conocer la ubicación desde la cual un comprador está realizando la solicitud de pedido.</p>
                </blockquote>
              </li>
              <li>
                Si encuentra un pedido que sea de su interés, <strong>lea los requisitos del comprador</strong>, tales como <strong>volumen</strong>; <strong>precio total</strong> y <strong>precio unitario</strong>; <strong>método de entrega</strong> y <strong>ubicación de entrega</strong>; entre otros.
              </li>
              <li>Para participar en un pedido, <strong>verifique que cumpla con los requisitos</strong> anteriormente mencionados, al final de la pantalla verá <strong>el botón Contribuir</strong>, haga clic sobre este si desea <strong>enviar una solicitud de participación</strong>.
                <blockquote>
                  <p>Si es <strong>un pedido individual</strong>, usted debe <strong>contribuir con la cantidad total</strong> pedida por el comprador.<br />
            Por el contrario, si es <strong>un pedido grupal</strong>, usted podrá:</p>
                  <ul>
                    <li><strong>ingresar el volumen</strong> con el que puede contribuir (tenga en cuenta que debe ser <strong>igual o mayor al volumen de oferta mínimo</strong> establecido por el comprador);</li>
                    <li>cambiar el precio unitario;</li>
                    <li>ingresar la fecha de intercambio;</li>
                    <li>observar el método de entrega;</li>
                    <li>establecer una tarifa de entrega.</li>
                  </ul>
                </blockquote>
              </li>
              <li>
                <strong>Agregue una descripción</strong> (con un máximo de 1000 caracteres) y también <strong>hasta 6 fotos del producto</strong> que está ofreciendo.
              </li>
              <li>
                <strong>Oprima el botón Ver resumen</strong> y verifique que toda la información anteriormente ingresada sea correcta.
              </li>
              <li>Si todo está bien <strong>oprima el botón Enviar</strong> y seguidamente el botón <strong>Confirmar</strong>.
                <blockquote>
                  <p>Si desea <strong>cambiar algo</strong> oprima el botón <strong>Editar</strong>.</p>
                </blockquote>
              </li>
            </ol>

            <p>Una vez enviada esta solicitud recibirá una respuesta del comprador.</p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer6;