import React from "react";
import ReactPlayer from 'react-player/soundcloud';
import ReactGA from 'react-ga';


export default function Audios() {

  return (
    <div className="body">
      
      {ReactGA.pageview(window.location.pathname)}
      
          <div className="audios">

              <h1>Audios</h1>

              <br/>
              <p>Listo, presentado por Listo Platform.</p>
              <br/>
              <p>El propósito de estos audios es presentarte el concepto de Listo y guiarte paso a paso sobre cómo funcionan las diversas herramientas dentro de esta plataforma. ¡Comencemos!</p>
              <br/><br/>
              <p>Parte A. Introducción</p>
              <br/>
              <ReactPlayer url='https://soundcloud.com/jacqui-poon/parte-a-introduccio-n?in=jacqui-poon/sets/introduccion-sobre-listo-el-mercado-digital-agricola' width='100%' height='150px' className='audio' config={{soundcloud: {options: {show_user: false}}}}/>
              <br/><br/>
              <p>Parte B. Instalación</p>
              <br/>
              <ReactPlayer url='https://soundcloud.com/jacqui-poon/parte-b-instalacio-n?in=jacqui-poon/sets/introduccion-sobre-listo-el-mercado-digital-agricola' width='100%' height='150px' className='audio' config={{soundcloud: {options: {show_user: false}}}}/>
              <br/><br/>
              <p>Parte C. Exploración</p>
              <br/>
              <ReactPlayer url='https://soundcloud.com/jacqui-poon/parte-c-exploracion?in=jacqui-poon/sets/introduccion-sobre-listo-el-mercado-digital-agricola' width='100%' height='150px' className='audio' config={{soundcloud: {options: {show_user: false}}}}/>

              <br/><br/><br/>
          </div>
    </div>
  );
}
