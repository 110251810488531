import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ReactGA from 'react-ga';
 
class Registro extends Component {
  render() {
    return (
      <div className="body">
        
        {ReactGA.pageview(window.location.pathname)}
        
        <div className="questions">
        <div className="answers">
            <h1>Registro</h1>
        

            <ul>
              <li><Link to="#registro-personal">Registro personal</Link></li>
              <li><Link to="#registro-de-otra-persona">Registro de otra persona</Link></li>
            </ul>

            <br/>

            <h2 id="registro-personal">Registro personal</h2>

            <br/>

            <h3 id="registro">Registro</h3>
            <ol>
            <li>
                Haga clic en <strong>Iniciar sesión con tu teléfono</strong>.
            </li>
            <li>
                Seguidamente debe <strong>ingresar el número de teléfono celular</strong> de quien se está registrando y se hace clic en <strong>Iniciar Sesión</strong>.
            </li>
            <li>Recibirá un <strong>código de verificación</strong> que llega a través de <strong>un mensaje de texto</strong> a ese teléfono celular y ese código será automáticamente leído por la aplicación.
                <blockquote>
                <p>Después de esto ingresará a <strong>la página de registro</strong>.</p>
                </blockquote>
            </li>
            <li>
                En <strong>la parte superior de la página</strong>, agregue una <strong>foto de perfil</strong>, puede <strong>tomar una al instante</strong> o <strong>seleccionar una imagen</strong> desde la galería del teléfono.
            </li>
            <li>Continúe completando la información necesaria, incluido el <strong>nombre</strong>, <strong>apellido</strong>, número de <strong>cédula</strong> y <strong>género</strong>, por último haga clic en <strong>Siguiente</strong>.</li>
            </ol>

            <br/>

            <h3 id="agregar-una-ubicación-al-perfil">Agregar una ubicación al perfil</h3>
            <blockquote>
            <p>Debe <strong>registrar una ubicación</strong> en Listo, esto con el objetivo de brindar una mayor trazabilidad a sus transacciones en la plataforma, la ubicación exacta será <strong>privada</strong> y solo se podrá <strong>ver una aproximación de esa ubicación</strong> en el mapa de Listo.</p>
            </blockquote>

            <ol>
            <li>
                Haga clic en <strong>Permitir</strong> cuando la aplicación <strong>pida permiso para usar la función GPS</strong>. Se necesita al menos <strong>una ubicación para el perfil</strong>, esta puede ser la ubicación de su <strong>finca</strong>, la <strong>oficina</strong> de su asociación o de su trabajo, su <strong>hogar</strong>, o una ubicación de <strong>uso frecuente</strong>.
            </li>
            <li>
                Presione <strong>Enviar</strong> para confirmar.
            </li>
            </ol>

            <br/>

            <h3 id="seleccionar-las-funciones-del-nuevo-usuario">Seleccionar las funciones del nuevo usuario</h3>
            <ol>
            <li>
                Seleccione de la lista <strong>las funciones</strong> que usted cumple. En Listo existen funciones como: <strong>agricultor</strong>, <strong>comerciante</strong> (vendedor o comprador), <strong>administrador de asociación</strong> y <strong>transportista</strong>. En caso de que no aplique a nada de lo anterior, puede crear una nueva función en <strong>Otro</strong>.
            </li>
            <li>Los usuarios que se registren bajo la función de <strong>agricultor</strong> o <strong>comerciante</strong>, deben <strong>seleccionar qué artículos</strong> están comprando o vendiendo.
                <blockquote>
                <p>En Listo encontrará una lista variada de <strong>frutas</strong>, <strong>verduras</strong>, <strong>hortalizas</strong>, <strong>nueces</strong>, <strong>snacks</strong> y productos para untar.</p>
                </blockquote>
            </li>
            <li>
                Una vez haya seleccionado los productos, haga clic en <strong>la marca de verificación</strong> ubicada en <strong>la esquina superior derecha</strong>.
            </li>
            <li>
                Los usuarios que se registren bajo la función de <strong>administrador de asociación</strong>, deben tener en cuenta que crear un perfil para la asociación se realiza en los siguientes pasos, después de <strong>haber creado el perfil personal</strong>.
            </li>
            <li>Para alentar a otros compradores o vendedores a que puedan ver lo que se vende o compra, recomendamos que cree <strong>un perfil público</strong>.
                <blockquote>
                <p>Pero, en caso de que <strong>no desee que su perfil sea visible</strong> para otras personas, debe hacer clic en <strong>el botón verde</strong> ubicado al final de <strong>la sección Visibilidad</strong> para que esta función esté <strong>apagada</strong>.</p>
                </blockquote>
            </li>
            <li>Ahora <strong>haga clic en Siguiente</strong>.</li>
            </ol>

            <p><strong>¡Su cuenta ha sido creada!</strong></p>

            <br/>
            <br/>

            <h2 id="registro-de-otra-persona">Registro de otra persona</h2>

            <br/>

            <p>Desde su teléfono inteligente puede <strong>ayudar a un usuario de teléfono básico</strong> a registrarse en Listo.</p>

            <blockquote>
            <p>Primero <strong>cierre su sesión</strong>, diríjase a <strong>la página de inicio</strong> y acceda a su perfil al oprimir su foto de perfil, esto lo llevará a una nueva ventana y al final de esta verá <strong>la opción Cerrar Sesión</strong>, haga clic sobre ella.</p>
            </blockquote>

            <br/>

            <h3 id="registro-1">Registro</h3>
            <ol>
            <li>
                Haga clic en <strong>Iniciar sesión con tu teléfono</strong>.
            </li>
            <li>
                Debe ingresar el número de teléfono celular del <strong>usuario de teléfono básico</strong> y haga clic en <strong>Iniciar Sesión</strong>.
            </li>
            <li>El usuario de teléfono básico <strong>recibirá un código de verificación</strong> que llega a través de un mensaje de texto <strong>a ese teléfono celular</strong>, debe <strong>ingresar ese código manualmente</strong> en la aplicación.
                <blockquote>
                <p>Después de esto ingresará a la página de registro.</p>
                </blockquote>
            </li>
            <li>
                En <strong>la parte superior de la página</strong>, agregue una <strong>foto de perfil</strong>, puede <strong>tomar una al instante</strong> o <strong>seleccionar una imagen</strong> desde la galería del teléfono.
            </li>
            <li>Continúe completando la información necesaria, incluido el <strong>nombre</strong>, <strong>apellido</strong>, número de <strong>cédula</strong> y <strong>género</strong>, por último haga clic en <strong>Siguiente</strong>.</li>
            </ol>

            <br/>

            <h3 id="agregar-una-ubicación-al-perfil-1">Agregar una ubicación al perfil</h3>
            <blockquote>
            <p>Debe <strong>registrar una ubicación</strong> en Listo, esto con el objetivo de brindar una mayor trazabilidad a sus transacciones en la plataforma, la ubicación exacta será <strong>privada</strong> y solo se podrá <strong>ver una aproximación de esa ubicación</strong> en el mapa de Listo.</p>
            </blockquote>

            <ol>
            <li>
                Haga clic en <strong>Permitir</strong> cuando la aplicación <strong>pida permiso para usar la función GPS</strong>. Se necesita al menos **    <strong>, esta puede ser la ubicación de una **finca</strong>, la <strong>oficina</strong> de la asociación o del trabajo, el <strong>hogar</strong>, o una ubicación de <strong>uso frecuente</strong>.
            </li>
            <li>
                Presione <strong>Enviar</strong> para confirmar.
            </li>
            </ol>

            <br/>

            <h3 id="seleccionar-las-funciones-del-nuevo-usuario-1">Seleccionar las funciones del nuevo usuario</h3>
            <ol>
            <li>
                Seleccione de la lista <strong>las funciones</strong> que cumple el usuario de teléfono básico. En Listo existen funciones como: <strong>agricultor</strong>, <strong>comerciante</strong> (vendedor o comprador), <strong>administrador de asociación</strong> y <strong>transportista</strong>. En caso de que no aplique a nada de lo anterior, puede crear una nueva función en <strong>Otro</strong>.
            </li>
            <li>Los usuarios que se registren bajo la función de <strong>agricultor</strong> o <strong>comerciante</strong>, deben <strong>seleccionar qué artículos</strong> están comprando o vendiendo.
                <blockquote>
                <p>En Listo encontrará una lista variada de <strong>frutas</strong>, <strong>verduras</strong>, <strong>hortalizas</strong>, <strong>nueces</strong>, <strong>snacks</strong> y productos para untar.</p>
                </blockquote>
            </li>
            <li>
                Una vez haya seleccionado los productos, haga clic en <strong>la marca de verificación</strong> ubicada en <strong>la esquina superior derecha</strong>.
            </li>
            <li>
                Los usuarios que se registren bajo la función de <strong>administrador de asociación</strong>, deben tener en cuenta que crear un perfil para la asociación se realiza en los siguientes pasos, después de <strong>haber creado el perfil personal</strong>.
            </li>
            <li>Para alentar a otros compradores o vendedores a que puedan ver lo que se vende o compra, recomendamos que cree <strong>un perfil público</strong>.
                <blockquote>
                <p>Pero, en caso de que <strong>no desee que su perfil sea visible</strong> para otras personas, debe hacer clic en <strong>el botón verde</strong> ubicado al final de <strong>la sección Visibilidad</strong> para que esta función esté <strong>apagada</strong>.</p>
                </blockquote>
            </li>
            <li>Ahora haga clic en <strong>Siguiente</strong>.</li>
            </ol>

            <p><strong>¡La cuenta del usuario de teléfono básico ha sido creada!</strong></p>
            <blockquote>
            <p>Le recordamos que <strong>cierre su sesión</strong>, diríjase a <strong>la página de inicio</strong> y acceda a su perfil al oprimir su foto de perfil, esto lo llevará a una nueva ventana y al final de esta verá <strong>la opción Cerrar Sesión</strong>, haga clic sobre ella.</p>
            </blockquote>
            
            <br/>
            
        </div>
        </div>
      </div>
    );
  }
}
 
export default Registro;