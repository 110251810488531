import React, { useState } from 'react';

import {
    Button,
    Menu,
    MenuItem,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from "react-intl";


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiTypography-root": {
                fontSize: "13px",
            }
        }
    }),
);

interface IProps {
    deliveryBool: boolean
    setDeliveryBool: React.Dispatch<React.SetStateAction<boolean>>
    handleClick: (event: React.MouseEvent<HTMLButtonElement>, setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
    handleClose: (setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
}


export default function Delivery(props: IProps) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setState:  React.Dispatch<React.SetStateAction<boolean>>) => {
        if (event.target.value === "true") {
            setState(true)
        } else {
            setState(false)
        }
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => props.handleClick(e, setAnchorEl)}>
                <p>
                    {/* <FormattedMessage id="options.option.deliveryMethod" defaultMessage="Delivery method"/>
                    <span> – </span> */}
                    <span className="option">
                        {(props.deliveryBool) ?
                            <FormattedMessage id="options.deliveryMethod.deliveryOnly" defaultMessage="Delivery only"/> :
                            <FormattedMessage id="options.deliveryMethod.collectionAndDelivery" defaultMessage="Collection and Delivery"/>
                        }
                    </span>
                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => props.handleClose(setAnchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
            >
                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="gender" name="gender1" value={props.deliveryBool} onChange={(e) => handleChange(e, props.setDeliveryBool)}>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={<FormattedMessage id="options.deliveryMethod.collectionAndDelivery" defaultMessage="Collection and Delivery"/>} //label="Collection and Delivery"
                            />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={<FormattedMessage id="options.deliveryMethod.deliveryOnly" defaultMessage="Delivery only"/>} //label="Delivery only"
                            />
                        </MenuItem>
                    </RadioGroup>
                </FormControl>
            </Menu>
        </div>
    );
}
