import React, {  useState, useContext } from 'react';

import {
    Button,
    Menu,
    MenuItem,
    FormControl,
    Select,
    TextField,
    createStyles,
    makeStyles
} from '@material-ui/core';

//import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { FormattedMessage, FormattedNumber } from "react-intl";

import { Context } from "../../components/Wrapper";

import disp from "../../JSONS/DICT_disp.json";
import cats from "../../JSONS/DICT_flat_cat.json";
import items from "../../JSONS/DICT_flat_item.json";

import { hasKey, getKeyValue, RE } from "../../utils";

//import { Context } from "../components/Wrapper";

import {
    typeItems,
    typeCats,
    typeDisp,
} from "../../interfaces";


interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    locale: string
}
  
function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'} 
            decimalSeparator={','}
            isNumericString
        />
    );
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontSize: "11px",
        }
    }),
);

interface IProps {
    item: string | null
    volumeUnits: string | null
    setVolumeUnits: React.Dispatch<React.SetStateAction<string | null>>
    volume: {min: number | null; max:  number | null;}
    setVolume: React.Dispatch<React.SetStateAction<{min: number | null; max:  number | null;}>>
    handleClose: (setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
    order?: boolean
}

export default function VolumeSelect(props: IProps) {

    const classes = useStyles();

    const {register, handleSubmit/* , errors, clearErrors */} = useForm();
    const context = useContext(Context);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [values, setValues] = useState<{min: string | null, max: string | null}>({min: null, max: null});
    const [error, setError] = useState(false);

    function ButtonDisabled() {
        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, setAnchorEl)}
                    disabled={true}
                    className="disabled"
                >
                    <FormattedMessage id="options.option.volume" defaultMessage="Volume"/>
                </Button>
            </div>
        );
    }


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, setState:  React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {

        let min: string | null;
        let max: string | null;

        if (props.volume.min) {
            min = String(props.volume.min);
        } else {
            min = null;
        }

        if (props.volume.max) {
            max = String(props.volume.max);
        } else {
            max = null;
        }

        setValues({min: min, max: max})
        
        setState(event.currentTarget);
    };

    const handleChangeZZ = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        setError(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    if (props.item === null) {
        return <ButtonDisabled/>
    }

    let getUnits: string[]

    if (hasKey(items, props.item)) {
        getUnits = getKeyValue<keyof typeItems, typeItems>(props.item)(items).units;
    } else if (hasKey(cats, props.item)) {
        getUnits = getKeyValue<keyof typeCats, typeCats>(props.item)(cats).units;
    } else {
        return <ButtonDisabled/>
    }


    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.setVolumeUnits(event.target.value as string)
    };

    const onSubmit = () => {

        if (values.min && values.max) {
            if (Number(values.min) > Number(values.max)) {

                setError(true);
                return;
            }
        }

        let min: number | null;
        let max: number | null;

        if (values.min) {
            min = Number(values.min);
        } else {
            min = null;
        }

        if (values.max) {
            max = Number(values.max);
        } else {
            max = null;
        }

        props.setVolume({
            min: min,
            max: max
        });

        setAnchorEl(null);
    }

    const onReset = () => {
        props.setVolume({
            min: null,
            max: null
        });

        setAnchorEl(null);
    }


    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, setAnchorEl)}>
                <p>
                    {(!props.volume.min && !props.volume.max) && <FormattedMessage id="options.option.volume" defaultMessage="Volume"/>}

                    <span className="option">
                        {(props.volume.min && !props.volume.max) && 
                            <span>
                                <FormattedMessage id="options.above" defaultMessage="Above"/> <FormattedNumber value={props.volume.min} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={2}/>
                            </span>
                        } {/* eslint-disable-line react/style-prop-object */}
                        {(!props.volume.min && props.volume.max) && 
                            <span>
                                <FormattedMessage id="options.below" defaultMessage="Below"/> <FormattedNumber value={props.volume.max} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={2}/></span>
                        } {/* eslint-disable-line react/style-prop-object */}
                        {(props.volume.min && props.volume.max) && <span><FormattedNumber value={props.volume.min} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={2}/> – <FormattedNumber value={props.volume.max} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={2}/></span>} {/* eslint-disable-line react/style-prop-object */}

                        {(props.volume.min || props.volume.max) ?
                            (RE(context.locale)) ?
                                getKeyValue<keyof typeDisp, typeDisp>(props.volumeUnits as string)(disp).disp_es :
                                getKeyValue<keyof typeDisp, typeDisp>(props.volumeUnits as string)(disp).disp_en
                        : null}
                    </span>
                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => props.handleClose(setAnchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                PaperProps={props.order ? 
                    {style: {padding: '5px 10px 10px 10px', width: '220px'}} : 
                    {style: {padding: '5px 10px 10px 10px'}
                }}
            >
                <FormControl>
                    <Select
                        className={classes.root}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.volumeUnits}
                        onChange={handleChange}
                        MenuProps={{
                            getContentAnchorEl:null,
                            anchorOrigin:{vertical: 'bottom', horizontal: 'left'},
                            transformOrigin:{vertical: 'top', horizontal: 'left'}
                        }}
                    >
                        {getUnits.map((value, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={value}
                                >
                                    {(RE(context.locale)) ?
                                        getKeyValue<keyof typeDisp, typeDisp>(value)(disp).disp_es :
                                        getKeyValue<keyof typeDisp, typeDisp>(value)(disp).disp_en
                                    }
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <form autoComplete="off" onSubmit={handleSubmit(onSubmit) }>
                    <div className={props.order ? "optionInputsOrder" : "optionInputs"}>
                        <TextField
                            //label={props.order ? "The minimum volume you will sell" : "Min."} //label="Min." //label="The minimum volume you will sell" 
                            label={props.order ? <FormattedMessage id="search.order_vol_min" defaultMessage="The minimum volume you will sell"/> : "Min."} //label="Min." //label="The minimum volume you will sell" 
                            value={values.min}
                            onChange={handleChangeZZ}
                            inputRef={register({required: false})}
                            name="min"
                            
                            id="formatted-numberformat-min-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                        <TextField
                            //label={props.order ? "How much are you selling" : "Max."} //label="Max." //label="How much are you selling"
                            label={props.order ? <FormattedMessage id="search.order_vol_max" defaultMessage="How much are you selling"/> : "Max."} //label="Max." //label="How much are you selling"
                            value={values.max}
                            onChange={handleChangeZZ}
                            inputRef={register({required: false})} //
                            name="max"
                            id="formatted-numberformat-max-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </div>
                    {error && <div className="error">
                        <FormattedMessage id="options.errorMsg" defaultMessage="Error with minimum/maximum"/>
                    </div>}
                    <div className="volumeOptionButtons">
                        {/* <input type="button" onClick={onReset} value="Reset"/>
                        <input type="submit" value="Aplicar"/> */}
                        <FormattedMessage id="search.clear" defaultMessage="Clear">
                            {(msg: string) => <input type="button" onClick={onReset} value = {msg} />}
                        </FormattedMessage>
                        <FormattedMessage id="search.apply" defaultMessage="Apply">
                            {(msg: string) => <input type="submit" value = {msg} />}
                        </FormattedMessage>
                    </div>
                </form>
            </Menu>
        </div>
    );
}


/* 
<FormattedMessage id="search.clear" defaultMessage="Clear">
                            {(msg: string) => <input type="text" placeholder = {msg} />}
                        </FormattedMessage>
                        <FormattedMessage id="search.apply" defaultMessage="Apply">
                            {(msg: string) => <input type="text" placeholder = {msg} />}
                        </FormattedMessage> */

