import React, { useContext, useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import distanceTurf from "@turf/distance";
import Typography from '@material-ui/core/Typography';

import { Context } from "../components/Wrapper";
import CircularIntermediate from "../components/CircularIntermediate";
import functions from "./../firebase";
import MapContainer from "../components/MapContainer";
import Paper from "../components/Paper";
import PaperMap from "../components/PaperMap";
import StarRating from "../components/StarRating";
//import Leaderboard from "../components/Leaderboard";
import TableItemInfo from "../components/TableItemInfo";
import Table from "../components/Table";

import ProfileUserDialog from "../components/ProfileUserDialog";
import ProfileAssocDialog from "../components/ProfileAssocDialog";

import {
    CF,
    CloudResultOrder,
    OrderModelPublicWebInterface,
    OrderPublic,  // eslint-disable-line
    PublicProfileUserInterface,
    PublicProfileAssocInterface,
} from '../interfaces';

interface ResultUser {
    errorBool: boolean,
    errorKey: null | string
    response:  PublicProfileUserInterface
}

interface ResultAssoc {
    errorBool: boolean,
    errorKey: null | string
    response:  PublicProfileAssocInterface
}

var CF_WEB_B_getOrderPublic_onCall = functions.httpsCallable('CF_WEB_B_getOrderPublic_onCall');
var CF_WEB_G_getPublicProfileUser_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileUser_onCall');
var CF_WEB_G_getPublicProfileAssoc_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileAssoc_onCall');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function toDate(date: string): string {
    let dd = new Date(date);
    return dd.toLocaleDateString();
}

let O:OrderModelPublicWebInterface;


const Orders: React.FC = () => {

    const context = useContext(Context);
    const [distance, setDistance] = useState(0);

    const [publicProfileUser, setPublicProfileUser] = useState<PublicProfileUserInterface | null>(null);
    const [openProfileUser, setOpenProfileUser] = useState(false);

    const [publicProfileAssoc, setPublicProfileAssoc] = useState<PublicProfileAssocInterface | null>(null);
    const [openProfileAssoc, setOpenProfileAssoc] = useState(false);

    //const [assocId, setAssocId] = useState<string | null>("jJwubwUKx0mq8QVRrC49");

    const handleClickOpenProfileUser = () => {
        if (publicProfileUser === null) {
            fetchPublicProfileUserData();
        }
        setOpenProfileUser(true);
    };
    const handleCloseProfileUser = () => {
        setOpenProfileUser(false);
    };

    const handleClickOpenProfileAssoc = () => {
        if (publicProfileAssoc === null) {
            fetchPublicProfileAssocData();
        }
        setOpenProfileAssoc(true);
    };
    const handleCloseProfileAssoc = () => {
        setOpenProfileAssoc(false);
    };

    const fetchPublicProfileUserData = async () => {
        try {
            const result: CF<ResultUser> = await CF_WEB_G_getPublicProfileUser_onCall({publicUid: order.userData.uid});

            setPublicProfileUser(result.data.response);

        } catch (error) {
            console.log("error")
        }
    }

    const fetchPublicProfileAssocData = async () => {
        try {
            const result: CF<ResultAssoc> = await CF_WEB_G_getPublicProfileAssoc_onCall({assocId: order.assocData?.assocId});

            setPublicProfileAssoc(result.data.response);

        } catch (error) {
            console.log("error")
        }
    }

    let query = useQuery();
    let orderId = query.get("orderId");
    
    let OrderPublic: OrderPublic = {
        orderId: orderId,
        language: context.locale
    }

    const [errorBool, setErrorBool] = useState(false);
    const [order, setOrder] = useState(O);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        CF_WEB_B_getOrderPublic_onCall(OrderPublic).then(function(result: CloudResultOrder) {
            if (result.data.errorBool) {
                setErrorBool(true);
                console.log(result.data.errorKey) //setErrorKey(result.data.errorKey);
            }
            else {
                setOrder(result.data.response);
            }
            setLoading(false);
            console.log('cloudFunction')
        })
        .catch(function(error: {code: string}) {
            if (error.code === undefined) {
                //pass
            }
            else {
                if (error.code === "internal") {
                    //window.alert("internal")
                }
                else {
                    window.alert(error.code) //deadline-exceeded
                }
            }
            setLoading(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (isLoading === false && errorBool === false) {
            navigator.geolocation.getCurrentPosition((position) => {
                let from = [order.coordinatesOffset._latitude, order.coordinatesOffset._longitude]
                let to = [position.coords.latitude, position.coords.longitude]
                let dist = distanceTurf(from, to);
                setDistance(Math.floor(dist));
                console.log('distanceSet')
            }, (error) => {
                console.log(`ERROR(${error.code}): ${error.message}`)
            })
        }
        else {
            //pass
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    console.log(order);
    
    if (OrderPublic.orderId == null) {
        return <Redirect to="/" />
    }
    else if (isLoading) {
        return (
            <div className="body">
            <div className="answers">
                <div className="circleContainer">
                    <CircularIntermediate/>
                </div>
            </div>
            </div>
        );
    }
    else {
        if (errorBool === true) {
            return <Redirect to="/" />
        } else {
            return (
                <div className="body">
                <div className="answers">

                {
                    (order.state === "stateOrderPosted" && order.openBool === true && order.publicBool === false) &&
                        <div className="inactive">
                            <FormattedMessage id="order.private_order" defaultMessage="Private order"/>
                        </div>
                }
                {
                    (order.state === "stateOrderPosted" && order.openBool === false && order.publicBool === true) &&
                        <div className="inactive">
                            <FormattedMessage id="order.buyer_not_accepting" defaultMessage="The buyer is not currently accepting new requests"/>
                        </div>
                }
                {
                    (order.state === "stateOrderPosted" && order.openBool === false && order.publicBool === false) &&
                        <div className="inactive">
                            <FormattedMessage id="order.private_and_not_accepting" defaultMessage="Private order. The buyer is not currently accepting new requests."/>
                        </div>
                }
                {
                    (order.state !== "stateOrderPosted") &&
                        <div className="inactive">
                            <FormattedMessage id="order.order_no_longer_active" defaultMessage="Order no longer active"/>
                        </div>
                }

                <div className="postContainer">
                    <Paper className="image">
                        <div className="imgContainer">
                            <img id="itemImg" src={order.photoUrlStock} alt=""/>
                        </div>
                    </Paper>
                    <div className="postInfo">
                        <Paper className="itemInfo">
                            <p className="title" id="item"><strong>{order.dispMap.item}</strong></p>

                            <p>
                                <strong><FormattedNumber value={order.targetPriceTotal/order.targetVolumeTotal} style="decimal"/> {/*order.dispMap.priceCurrency*/}COP / {order.dispMap.targetVolumeUnits}</strong> {/* eslint-disable-line react/style-prop-object */}
                                
                            </p>

                            <TableItemInfo 
                                remainingVolume={order.remainingVolume}
                                targetVolumeUnits={order.dispMap.targetVolumeUnits}
                                remainingVolumeKg={order.remainingVolumeKg}
                                singleSellerBool={order.singleSellerBool}
                                minimumOfferVolume={order.minimumOfferVolume}
                                minimumOfferVolumeKg={order.minimumOfferVolumeKg}
                            />

                            <div className="bottom">
                                {(distance === 0 ? <p><br/></p> : <p><FormattedNumber value={distance} style="decimal"/> km</p>)} {/* eslint-disable-line react/style-prop-object */}
                                {(order.state === "stateOrderPosted" && order.openBool === true) ? 
                                    <a href={order.shortLinkWeb}>
                                        <button>
                                            <FormattedMessage id="order.make_offer" defaultMessage="Make offer"/>
                                        </button>
                                    </a> : 
                                    <button className="inButton" disabled={true}>
                                        <FormattedMessage id="order.make_offer" defaultMessage="Make offer"/>
                                    </button>
                                }
                            </div>
                        </Paper>
                        
                        <Paper className="userInfo">
                            {order.assocData ?

                                <div  className="assocUser">
                                    <div className="assocImgName" onClick={handleClickOpenProfileAssoc}>
                                        {(order.userData.photoUrl !== null) ? 
                                            <img id="assocImg" src={order.assocData.photoUrl as string} alt="" width="60px" height="60px"/> :
                                            <img id="assocImg" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" width="60px" height="60px"/>}
                                        <div className="nameStar">
                                            <Typography className="" id="name" noWrap={true}>
                                                <strong>{order.assocData.name}</strong>
                                            </Typography>
                                            <StarRating rating={order.userData.rating}/>
                                        </div>
                                    </div>

                                    <div className="userImgName" onClick={handleClickOpenProfileUser}>
                                        {(order.userData.photoUrl !== null) ? 
                                            <img id="userImg" src={order.userData.photoUrl} alt="" width="40px" height="40px"/> : 
                                            <img id="userImg" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" width="60px" height="60px"/>
                                        }
                                        <div className="name">
                                            <Typography className="" id="name" noWrap={true}>
                                                <FormattedMessage id="contact" defaultMessage="Contact"/>
                                                {': '}
                                                {order.userData.firstName}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div className="userImgName" onClick={handleClickOpenProfileUser}>
                                    {(order.userData.photoUrl !== null) ? <img id="userImg" src={order.userData.photoUrl} alt="" width="60px" height="60px"/> : <img id="userImg" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" width="60px" height="60px"/>}
                                    <div className="nameStar">
                                        <p id="name"><strong>{order.userData.firstName} {order.userData.lastName}</strong></p>
                                        <StarRating rating={order.userData.rating}/>
                                    </div>
                                </div>
                            }

                            <ProfileUserDialog
                                publicProfileUser={publicProfileUser}
                                open={openProfileUser}
                                handleClose={handleCloseProfileUser}
                                postId={null} //postId={postId}
                                //setPostId={setPostId}
                                setLoading={setLoading}
                            />
                            <ProfileAssocDialog
                                publicProfileAssoc={publicProfileAssoc}
                                open={openProfileAssoc}
                                handleClose={handleCloseProfileAssoc}
                                //assocId={assocId}
                                //setAssocId={setAssocId}
                                setLoading={setLoading}
                            />
                            
                            <a href={order.shortLinkWeb}>
                                <button>
                                    <FormattedMessage id="order.send_message" defaultMessage="Send Message"/>
                                </button>
                            </a>
                        </Paper>
                        
                    </div>
                    <div className="postAux">
                        
                        <Paper className="postDetails">
                            <p className="title" >
                                <strong>
                                    <FormattedMessage id="order.order_details" defaultMessage="Order detials"/>
                                </strong>
                            </p>
                            <Table
                                variety={order.dispMap.variety}
                                processed={order.dispMap.processed}
                                earliestExchangeDate={toDate(order.exchangeDateStart)}
                                postEndDate={toDate(order.exchangeDateEnd)}
                                dateString={{start:"order.exchange_window_start", end: "order.exchange_window_end"}}
                                delivery={order.delivery}
                                collection={order.collection}
                                deliveryString={{delivery:"order.delivery", collection:"order.collection"}}
                                cash={order.paymentCashBool}
                                digital={order.paymentDigitalBool}
                            />
                            
                        </Paper>
                        
                    </div>
                    <PaperMap className="map">
                        <MapContainer lat={order.coordinatesOffset._latitude} lng={order.coordinatesOffset._longitude}/>
                    </PaperMap>
                </div>

                {/*<Leaderboard/>*/}

                </div>
            </div>
            );
        }
    }
}

export default Orders;
