import React, { Component } from "react";
import ReactPlayer from 'react-player/youtube';

class Answer2 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-líderes-de-asociaciones-cómo-invitar-a-los-asociados-en-listo">Para líderes de asociaciones, ¿cómo invitar a los asociados en Listo?</h1>
            
            <p>Además de poder crear un perfil de asociación en Listo, un líder o administrador podrá <strong>invitar a sus asociados a ser parte de la asociación</strong> en la aplicación.</p>

            <br/>
            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=00dyRdXdCFc&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>
            
            <h2 id="en-la-aplicación">En la aplicación</h2>

            <ol>
              <li>
                En el menú ubicado en la parte inferior de la pantalla, en la pestaña Asociaciones, estará el perfil de la asociación, haga clic sobre el nombre para acceder a este.
              </li>
              <li>Esto lo llevará a <strong>la página de inicio de la asociación</strong>, allí podrá observar <strong>cuatro botones</strong>:
                <ul>
                  <li>Añadir miembro.</li>
                  <li>Administrar miembros.</li>
                  <li>Difusiones.</li>
                  <li>Publicaciones de miembros.</li>
                </ul>
              </li>
              <li>
                Al <strong>hacer clic en el primer botón Añadir miembro</strong>, podrá invitar a sus asociados o a otros administradores a unirse como miembros de la asociación en Listo.
              </li>
              <li>
                Escriba <strong>el número de celular</strong> de la persona que desea invitar.
              </li>
              <li>Si esta persona es <strong>otro administrador</strong> de la asociación, marque <strong>la casilla Hacer administrador</strong> para identificarlo como tal en el perfil de la asociación.
                <blockquote>
                  <p>Por otro lado, si la persona que va a invitar es un asociado <strong>no marque esta casilla</strong>.</p>
                </blockquote>
              </li>
              <li>Finalmente, <strong>oprima el botón Enviar invitación</strong> y seguidamente el botón <strong>Confirmar</strong>.
                <blockquote>
                  <p>La invitación enviada como <strong>Hacer administrador</strong> le dará al usuario invitado acceso a todas las funciones de un administrador o líder de asociación.</p>
                </blockquote>
              </li>
            </ol>

            <p>Si la persona que invitó <strong>aún no se ha registrado en Listo</strong>, esta recibirá un <strong>mensaje de texto que le invitará a descargar la aplicación</strong> para poder registrarse y aceptar la invitación.</p>

            <p>Ahora solo debe esperar a que acepten su invitación.</p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer2;