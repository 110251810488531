import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";
import Leaderboard from "../components/Leaderboard";

 
class Footer extends Component {
  render() {
    return (

        <footer>
            <Leaderboard/>

            <p className="footerText">
                <a href="https://listoapp.co" rel="noopener noreferrer" target="_blank">Listo por Listo Platform</a>
                <br/>
                <ScrollToTop />
                <NavLink to="/privacidad">Política de Privacidad</NavLink>
                <br/>
                <NavLink to="/terminos-y-condiciones">Términos y Condiciones</NavLink>
            </p>
            <div className="footerIcons">

                <a href="https://facebook.com/soylistoapp" rel="noopener noreferrer" target="_blank">
                    <span className="fa-stack fa-1x">
                        <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                        <i className="socialIconTop fab fa-facebook fa-stack-1x"></i>
                    </span>
                </a>

                <a href="https://instagram.com/soylistoapp" rel="noopener noreferrer" target="_blank">
                    <span className="fa-stack fa-1x">
                        <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                        <i className="socialIconTop fab fa-instagram fa-stack-1x"></i>
                    </span>
                </a>

                <a href="https://www.youtube.com/channel/UCzTQ_AkJ0wG-IoOlP5eW5zQ" rel="noopener noreferrer" target="_blank">
                    <span className="fa-stack fa-1x">
                        <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                        <i className="socialIconTop fab fa-youtube fa-stack-1x"></i>
                    </span>
                </a>

                <a href="https://twitter.com/soylistoapp" rel="noopener noreferrer" target="_blank">
                    <span className="fa-stack fa-1x">
                        <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                        <i className="socialIconTop fab fa-twitter fa-stack-1x"></i>
                    </span>
                </a>

                <a href="https://wa.me/573223043172" rel="noopener noreferrer" target="_blank">
                    <span className="fa-stack fa-1x">
                        <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                        <i className="socialIconTop fab fa-whatsapp fa-stack-1x"></i>
                    </span>
                </a>

                <a href="mailto:info@listoapp.co" rel="noopener noreferrer" target="_blank">
                    <span className="fa-stack fa-1x">
                        <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                        <i className="socialIconTop fas fa-envelope fa-stack-1x"></i>
                    </span>
                </a>

            </div>
        </footer>      
    );
  }
}
 
export default Footer;
