import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        position: "relative",
      '& > *': {
        marginBottom: "0.5rem",
        width: "100%",
        height: "342px",
      },
    },
  }),
);

export default function PaperMap(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Paper elevation={3}>
            {props.children}
        </Paper>
    </div>
  );
}