import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

interface Props {
  	rating: null | number
}

export default function SimpleRating(props: Props) {

	return (
		<div>
		<Box component="fieldset" mb={3} borderColor="transparent" style={{marginBottom: "0px"}}>
			<Rating name="half-rating-read" value={props.rating} precision={0.1} readOnly />
		</Box>
		</div>
	);
}