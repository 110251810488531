import React from "react";
import { FormattedMessage } from "react-intl";

import david from "../images/aboutUs/david.png";
import alex from "../images/aboutUs/alex.jpeg";
import paola from "../images/aboutUs/paola.jpeg";

const AboutUs: React.FC = () => {

    return (
        <div className="body">

        <div className="aboutUsContainer">
            <div className="imgInfo">
                <div className="imgContainer">
                    <img src={david} alt=""/>
                </div>
                <div className="info">
                    <div className="nameEmail">
                        <h2>
                            David Godding PhD
                        </h2>
                        <a href="mailto:david@listoapp.co" rel="noopener noreferrer" target="_blank">
                            <span className="fa-stack fa-1x">
                                <i className="socialIconBack fas fa-circle fa-stack-2x"></i>
                                <i className="socialIconTop fas fa-envelope fa-stack-1x"></i>
                            </span>
                        </a>
                    </div>
                    <h3>
                        <FormattedMessage id="aboutUs.david_role" defaultMessage=""/>
                    </h3>
                    <p>
                        <FormattedMessage id="aboutUs.david_desc" defaultMessage=""/>
                    </p>
                </div>
            </div>

            <div className="imgInfo">
                <div className="imgContainer">
                    <img src={alex} alt=""/>
                </div>
                <div className="info">
                    <h2>
                        Alex Diaz
                    </h2>
                    <h3>
                        <FormattedMessage id="aboutUs.alex_role" defaultMessage=""/>
                    </h3>
                    <p>
                        <FormattedMessage id="aboutUs.alex_desc" defaultMessage=""/>
                    </p>
                </div>
            </div>

            <div className="imgInfo">
                <div className="imgContainer">
                    <img src={paola} alt=""/>
                </div>
                <div className="info">
                    <h2>
                        Paola Daza
                    </h2>
                    <h3>
                        <FormattedMessage id="aboutUs.paola_role" defaultMessage=""/>
                    </h3>
                    <p>
                        <FormattedMessage id="aboutUs.paola_desc" defaultMessage=""/>
                    </p>
                </div>
            </div>

        </div>
        </div>
    );
}

export default AboutUs;
