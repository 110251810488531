import React from "react";

import playstore from "../images/playstore.png";
import appstore from "../images/appstore.png";
import screenshot from "../images/screenshots/Android_phone_sandia_100dpi.png";

const Leaderboard: React.FC = () => {
    return (
        <div className="leaderboard">
            <div className="left">
                <div className="text">
                    <p className="first">¿Listo? Compra y vende fácilmente.</p>
                    <p className="second">Descarga la aplicación y pruébala.</p>
                </div>
                <div className="links">
                    <a className="playStoreLink" href="https://play.google.com/store/apps/details?id=com.farming_data.farming_data_flutter&amp;hl=es_419" rel="noopener noreferrer" target="_blank"><img className="playStore" src={playstore} alt=""/></a>
                    <a className="appStoreLink" href="https://apps.apple.com/co/app/listo-mercado-agr%C3%ADcola/id1509312528?ls=1" rel="noopener noreferrer" target="_blank"><img className="appStore" src={appstore} alt=""/></a>
                </div>
            </div>
            <div className="right">
                <img src={screenshot} alt=""/>
            </div>
        </div>
    );
}

export default Leaderboard;