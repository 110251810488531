import React, { Component } from "react";
 
class Answer5 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-compradores-cómo-enviar-calificaciones">Para compradores, ¿cómo enviar calificaciones?</h1>
            
            <p>En Listo se maneja un sistema de calificaciones a través de una puntuación de 1 a 5 estrellas. Las calificaciones son importantes porque le indican a los usuarios quienes son buenos actores dentro de la plataforma.</p>
            <blockquote>
              <p>Por ejemplo, si una persona cuenta con 4 o 5 estrellas es un muy buen usuario, pero si solo cuenta con 1 o 2 probablemente su comportamiento dentro de Listo no sea el mejor. Esto ayuda al usuario a determinar con quién hacer negocios.</p>
            </blockquote>

            <p>La calificación se puede enviar inmediatamente después de ingresar el código de intercambio.</p>

            <ol>
              <li>
                La aplicación va a llevarlo automáticamente a <strong>la ventana de calificación</strong>.
              </li>
              <li>
                Allí debe <strong>seleccionar el número de estrellas</strong> que quiera darle al otro usuario de acuerdo con su experiencia con el mismo.
              </li>
              <li>
                <strong>Oprima el botón Enviar Calificación</strong> y luego <strong>el botón Confirmar</strong>, la calificación se enviará después de esto.
              </li>
            </ol>

            <p>Esta calificación se verá reflejada en el número de estrellas que aparecen en el perfil del usuario.</p>

            <br/>

        </div>


      </div>
    );
  }
}
 
export default Answer5;