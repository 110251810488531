import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

import Paper from "../components/Paper";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { PostCarouselModelPublicWebInterface } from "../interfaces";

//import photoUrlProfile from "../images/appicon.png";

interface Props {
    postCarousel: PostCarouselModelPublicWebInterface | undefined
}

const useStyles = makeStyles({
  root: {
    fontSize: '15px',
  },
});


export default function PostPreview(props: Props) {

    const classes = useStyles();

    return (
        <>
        {(props.postCarousel !== undefined) ?
            <div className="postPreview">
            <Paper>
                <div className="imgContainer">
                    <div className="photoPostContainer">
                        <img id="photoPost" src={props.postCarousel.photoUrlPost as string} alt=""/>
                    </div>
                    {(props.postCarousel.assocBool === false) ? 
                        <img id="photoProfile" src={props.postCarousel.photoUrlProfile as string} alt = ""/> :
                        <img id="photoProfile" src={props.postCarousel.photoUrlAssoc as string} alt = ""/>
                    }
                </div>
                <div className="infoContainer">
                    <p><strong>{props.postCarousel.dispMap.item}</strong></p>
                    <Grid container justify='space-between' wrap='nowrap'>
                        <Typography className={classes.root} noWrap={true}>
                            {(props.postCarousel.singleBuyerBool === true) ?
                                <><FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</> : /* eslint-disable-line react/style-prop-object */
                                <><FormattedNumber value={props.postCarousel.minOfferVolumeKg as number} style="decimal" maximumFractionDigits={2}/> <FormattedMessage id="home.to" defaultMessage="to"/> <FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</> /* eslint-disable-line react/style-prop-object */
                            }
                        </Typography>
                        <Typography className={classes.root} noWrap={true}>
                            {(props.postCarousel.distance !== null) &&
                                <>~<FormattedNumber value={props.postCarousel.distance} style="decimal" maximumFractionDigits={0}/> <FormattedMessage id="home.km_away" defaultMessage="km away"/></> /* eslint-disable-line react/style-prop-object */
                            }
                        </Typography>
                    </Grid>
                    <p className="pricePerKg"><FormattedNumber value={props.postCarousel.priceTotal/props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> COP / KG</p> {/* eslint-disable-line react/style-prop-object */}
                </div>
            </Paper>
            </div> :

            <div className="postPreview">
                <Paper>
                    <div className="imgContainer">
                        <div className="photoPostContainer">
                            <Skeleton id="photoPost" variant="rect" animation="wave"/>
                        </div>
                    </div>
                    <div className="infoContainer">
                        <Skeleton className="skeleton" variant="text" animation="wave"><p>item_cacao_cream____</p></Skeleton><br/>
                        <Skeleton className="skeleton" variant="text" animation="wave"><p>5000 KG</p></Skeleton><br/>
                        <Skeleton className="skeleton" variant="text" animation="wave"><p>3000 COP / KG</p></Skeleton><br/>
                    </div>
                </Paper>
            </div>
        }
        </>
    );
}

/*
<Typography className={classes.root} noWrap={true}>
{(props.postCarousel.singleBuyerBool === true) ?
    <><FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</> :
    <><FormattedNumber value={props.postCarousel.minOfferVolumeKg as number} style="decimal" maximumFractionDigits={2}/> <FormattedMessage id="home.to" defaultMessage="to"/> <FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</>
}
{(props.postCarousel.distance !== null) &&
    <span>~<FormattedNumber value={props.postCarousel.distance} style="decimal" maximumFractionDigits={0}/> <FormattedMessage id="home.km_away" defaultMessage="km away"/></span>
}
</Typography>
*/

/*
{(props.postCarousel.singleBuyerBool === true) ?
    <Typography className={classes.root} display="inline" noWrap={true}><FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</Typography> :
    <Typography className={classes.root} display="inline" noWrap={true}><FormattedNumber value={props.postCarousel.minOfferVolumeKg as number} style="decimal" maximumFractionDigits={2}/> <FormattedMessage id="home.to" defaultMessage="to"/> <FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</Typography> 
}
{(props.postCarousel.distance !== null) &&
    <span><Typography className={classes.root} display="inline" noWrap={true}>~<FormattedNumber value={props.postCarousel.distance} style="decimal" maximumFractionDigits={0}/> <FormattedMessage id="home.km_away" defaultMessage="km away"/></Typography></span>
}
*/

/*
<p>
    {(props.postCarousel.singleBuyerBool === true) ?
        <><FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</> :
        <><FormattedNumber value={props.postCarousel.minOfferVolumeKg as number} style="decimal" maximumFractionDigits={2}/> <FormattedMessage id="home.to" defaultMessage="to"/> <FormattedNumber value={props.postCarousel.internalVolumeKg} style="decimal" maximumFractionDigits={2}/> KG</> 
    }
    {(props.postCarousel.distance !== null) &&
        <><span>~<FormattedNumber value={props.postCarousel.distance} style="decimal" maximumFractionDigits={0}/> <FormattedMessage id="home.km_away" defaultMessage="km away"/></span></>
    }
</p>
*/