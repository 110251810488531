import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ReactPlayer from 'react-player/youtube';
 
class Answer4 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-asociaciones-cómo-crear-pedidos">Para asociaciones, ¿cómo crear pedidos?</h1>
            
            <p>Las asociaciones podrán <strong>crear pedidos grupales</strong> para <strong>acopiar volúmenes significativos de un producto</strong> o pedidos individuales para comprar determinada cantidad a un solo usuario.</p>

            <br/>
            <ul>
              <li><Link to="#opción-1-crear-un-pedido-individual">Crear un pedido individual</Link></li>
              <li><Link to="#opción-2-crear-un-pedido-grupal">Crear un pedido grupal</Link></li>
            </ul>
            <br/>

            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=gV_upyy3P-E&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>

            <h2 id="opción-1-crear-un-pedido-individual">Opción 1. Crear un pedido individual</h2>

            <ol>
              <li>En la página de inicio, oprima el botón Pedido.
                <blockquote>
                  <p>También puede dirigirse a la pestaña de <strong>Intercambio</strong>, seleccione la ventana <strong>Comprar</strong> y dentro de ella oprima <strong>el botón Crear pedido</strong>.</p>
                </blockquote>
              </li>
              <li>
                Seleccione <strong>el nombre de la asociación</strong>.
              </li>
              <li>
                Seleccione la opción <strong>Comprar a un vendedor</strong>.
              </li>
              <li>
                <strong>Seleccione el producto que desea acopiar</strong>.
              </li>
              <li>Ingrese todas <strong>las características correspondientes</strong> al mismo, como:
                <ul>
                  <li><strong>etapa de procesamiento y variedad</strong>;</li>
                  <li><strong>volumen objetivo</strong> (el volumen total que desea acopiar);</li>
                  <li><strong>precio total</strong> o el <strong>precio unitario</strong> (valor que va a pagar por el producto);</li>
                  <li><strong>tiempo</strong> durante el cual va a acopiar;</li>
                  <li><strong>lugar de entrega</strong> y <strong>método de entrega</strong>.</li>
                </ul>
              </li>
              <li>
                Finalmente, puede seleccionar la opción de <strong>crear un pedido público</strong>, es decir, que será visible para todos los usuarios de Listo, esto le permitirá recibir solicitudes de contribución de diversos usuarios.
              </li>
              <li>Verifique que toda <strong>la información anteriormente ingresada sea correcta</strong> y oprima el botón <strong>Enviar</strong>.</li>
            </ol>

            <p>Ahora solo debe esperar las contribuciones de los usuarios.</p>

            <br/>
            <br/>
            <h2 id="opción-2-crear-un-pedido-grupal">Opción 2. Crear un pedido grupal</h2>

            <ol>
              <li>En <strong>la página de inicio</strong>, oprima <strong>el botón Pedido</strong>.
                <blockquote>
                  <p>También puede dirigirse a <strong>la pestaña de Intercambio</strong>, seleccione la ventana <strong>Comprar</strong> y dentro de ella oprima <strong>el botón Crear pedido</strong>.</p>
                </blockquote>
              </li>
              <li>
                Seleccione <strong>el nombre de la asociación</strong>.
              </li>
              <li>
                Seleccione la opción <strong>Comprar a múltiples vendedores</strong>.
              </li>
              <li>
                <strong>Seleccione el producto que desea acopiar</strong>.
              </li>
              <li>Ingrese todas <strong>las características correspondientes</strong> al mismo, como:
                <ul>
                  <li><strong>etapa de procesamiento y variedad</strong>;</li>
                  <li><strong>volumen objetivo</strong> (el volumen total que desea acopiar);</li>
                  <li><strong>volumen mínimo de contribución</strong> (el volumen mínimo con el cual un usuario puede contribuir al pedido);</li>
                  <li><strong>precio total</strong> o el <strong>precio unitario</strong> (valor que va a pagar por el producto);</li>
                  <li><strong>tiempo</strong> durante el cual va a acopiar;</li>
                  <li><strong>lugar de entrega</strong> y <strong>método de entrega</strong>.</li>
                </ul>
              </li>
              <li>
                Finalmente, puede seleccionar la opción de <strong>crear un pedido público</strong>, es decir, que será visible para todos los usuarios de Listo, esto le permitirá recibir solicitudes de contribución de diversos usuarios.
              </li>
              <li>Verifique que toda <strong>la información anteriormente ingresada sea correcta</strong> y oprima el botón <strong>Enviar</strong>.</li>
            </ol>

            <p>Ahora solo debe esperar las contribuciones de los usuarios.</p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer4;