import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';

import Ground from "./Ground";
import Wrapper from "./components/Wrapper";
import GoogleWrapper from "./components/GoogleWrapper";

//import "./index.css";
import "./style/index.scss";

ReactGA.initialize('UA-167408819-1');

ReactDOM.render(
  <GoogleWrapper><Wrapper><Ground/></Wrapper></GoogleWrapper>,
  document.getElementById("root")
);