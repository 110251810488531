import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer5 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-agricultores-cómo-enviar-calificaciones">Para agricultores, ¿cómo enviar calificaciones?</h1>
            
            <ul>
              <li><Link to="#opción-1-calificar-a-través-del-menú-de-mensajes">A través del menú de mensajes</Link></li>
              <li><Link to="#opción-2-calificar-a-través-de-la-aplicación">A través de la aplicación</Link></li>
            </ul>
            <br/>

            <blockquote>
              <p>En Listo se maneja un sistema de calificaciones a través de una puntuación de 1 a 5 estrellas. Las calificaciones son importantes porque le indican a los usuarios quienes son buenos actores dentro de la plataforma.</p>
            </blockquote>

            <blockquote>
              <p>Por ejemplo, si una persona cuenta con 4 o 5 estrellas es un muy buen usuario, pero si solo cuenta con 1 o 2 probablemente su comportamiento dentro de Listo no sea el mejor. Esto ayuda al usuario a determinar con quién hacer negocios.</p>
            </blockquote>

            <br/>
            <h2 id="opción-1-calificar-a-través-del-menú-de-mensajes">Opción 1. Calificar a través del menú de mensajes</h2>
            <blockquote>
              <p>Al ingresar el código de intercambio, usted recibirá un mensaje de texto con las instrucciones para enviar la calificación.</p>
            </blockquote>

            <ol>
              <li>Responda al número 891177 con el código <strong>LT c seguido de los tres números</strong> que indica el mensaje, estos corresponden al número de la publicación, y <strong>escriba un número del 1 al 5</strong>, este número corresponde al número de estrellas con las que quiere calificar al otro usuario.
                <blockquote>
                  <p>Por ejemplo, <strong>responda LT c 123 5</strong> si su experiencia al intercambiar fue excelente, o <strong>LT c 123 1</strong> si su experiencia no fue tan favorable.</p>
                </blockquote>
              </li>
              <li>Una vez haya respondido la calificación aparecerá en la plataforma.</li>
            </ol>

            <br/>
            <br/>
            <h2 id="opción-2-calificar-a-través-de-la-aplicación">Opción 2. Calificar a través de la aplicación</h2>
            <blockquote>
              <p>La calificación se puede enviar inmediatamente después de ingresar el código de intercambio.</p>
            </blockquote>

            <ol>
              <li>
                La aplicación va a llevarlo automáticamente a la ventana de calificación, allí debe <strong>seleccionar el número de estrellas</strong> que quiera darle al otro usuario de acuerdo con su experiencia con el mismo.
              </li>
              <li>
                <strong>Oprima el botón Enviar Calificación</strong> y luego <strong>el botón Confirmar</strong>, la calificación se enviará después de esto.
                <blockquote>
                  <p>Esta calificación se verá reflejada en el número de estrellas que aparecen en el perfil del usuario.</p>
                </blockquote>
              </li>
            </ol>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer5;