import React, { Component } from "react";
import { Route, NavLink } from "react-router-dom";
import ReactGA from 'react-ga';

import Answer1 from "./comprador/answer_1";
import Answer2 from "./comprador/answer_2";
import Answer3 from "./comprador/answer_3";
import Answer4 from "./comprador/answer_4";
import Answer5 from "./comprador/answer_5";
import Answer6 from "./comprador/answer_6";
 
class Comprador extends Component {
  render() {
    return (
      <div className="body">
        
        {ReactGA.pageview(window.location.pathname)}

        <div className="questions">
            <h1>Compradores</h1>
            
            <div>
                <ul>
                  <li><NavLink to="/comprador/buscar_las_publicaciones_y_hacer_una_oferta">¿Cómo buscar publicaciones?</NavLink></li>
                  <li><NavLink to="/comprador/crear_un_pedido_de_lo_que_está_buscando_o_quiere_acopiar">¿Cómo crear pedidos?</NavLink></li>
                  <li><NavLink to="/comprador/hacer_edicones_antes_del_intercambio">¿Cómo editar antes del intercambio?</NavLink></li>
                  <li><NavLink to="/comprador/realizar_el_intercambio">¿Cómo realizar los intercambios?</NavLink></li>
                  <li><NavLink to="/comprador/ingresar_calificaciones">¿Cómo enviar calificaciones?</NavLink></li>
                  <li><NavLink to="/comprador/crear_un_perfil_de_la_empresa_o_de_la_tienda">¿Cómo enviar ofertas?</NavLink></li>
                </ul>
            </div>

            <Route path="/comprador/buscar_las_publicaciones_y_hacer_una_oferta" component={Answer1}/>
            <Route path="/comprador/crear_un_pedido_de_lo_que_está_buscando_o_quiere_acopiar" component={Answer2}/>
            <Route path="/comprador/hacer_edicones_antes_del_intercambio" component={Answer3}/>
            <Route path="/comprador/realizar_el_intercambio" component={Answer4}/>
            <Route path="/comprador/ingresar_calificaciones" component={Answer5}/>
            <Route path="/comprador/crear_un_perfil_de_la_empresa_o_de_la_tienda" component={Answer6}/>

        </div>

      </div>
    );
  }
}
 
export default Comprador;