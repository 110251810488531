import React, { useContext, useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import distanceTurf from "@turf/distance";
import Typography from '@material-ui/core/Typography';

import { Context } from "../components/Wrapper";
import CircularIntermediate from "../components/CircularIntermediate";
import functions from "./../firebase";
import MapContainer from "../components/MapContainer";
import ImgCarousel from "../components/Carousel";
import Paper from "../components/Paper";
import PaperMap from "../components/PaperMap";
import StarRating from "../components/StarRating";
//import Leaderboard from "../components/Leaderboard";
import Table from "../components/Table";

import ProfileUserDialog from "../components/ProfileUserDialog";
import ProfileAssocDialog from "../components/ProfileAssocDialog";

import {
    CF,
    CloudResultPost,
    PostModelPublicWebInterface,
    PostPublic,  // eslint-disable-line
    //UserModelPublic,
    PublicProfileUserInterface,
    PublicProfileAssocInterface
} from '../interfaces';

/* interface ResultUser {
    errorBool: boolean,
    errorKey: null | string
    response:  PublicProfileUserInterface
}

interface ResultAssoc {
    errorBool: boolean,
    errorKey: null | string
    response:  PublicProfileAssocInterface
} */

interface Result<T> {
    errorBool: boolean,
    errorKey: null | string
    response:  T //PublicProfileUserInterface || PublicProfileAssocInterface
}

var CF_WEB_B_getPostPublic_onCall = functions.httpsCallable('CF_WEB_B_getPostPublic_onCall');
var CF_WEB_G_getPublicProfileUser_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileUser_onCall');
var CF_WEB_G_getPublicProfileAssoc_onCall = functions.httpsCallable('CF_WEB_G_getPublicProfileAssoc_onCall');

/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function toDate(date: string): string {
    let dd = new Date(date);
    return dd.toLocaleDateString();
}

let P:PostModelPublicWebInterface; // let init:PostModelPublicWebInterface;


const Posts: React.FC = () => {

    const context = useContext(Context);
    const [distance, setDistance] = useState(0);

    const [publicProfileUser, setPublicProfileUser] = useState<PublicProfileUserInterface | null>(null);
    const [openProfileUser, setOpenProfileUser] = useState(false); //const [open, setOpen] = useState(false);

    const [publicProfileAssoc, setPublicProfileAssoc] = useState<PublicProfileAssocInterface | null>(null);
    const [openProfileAssoc, setOpenProfileAssoc] = useState(false);

    const handleClickOpenProfileUser = () => {
        if (publicProfileUser === null) {
            fetchPublicProfileUserData();
        }
        setOpenProfileUser(true);
    };
    const handleCloseProfileUser = () => {
        setOpenProfileUser(false);
    };

    const handleClickOpenProfileAssoc = () => {
        if (publicProfileAssoc === null) {
            fetchPublicProfileAssocData();
        }
        setOpenProfileAssoc(true);
    };
    const handleCloseProfileAssoc = () => {
        setOpenProfileAssoc(false);
    };


    const query = useQuery();
    const _postId = query.get("postId");

    const [postId, setPostId] = useState(_postId);
    //let query = useQuery();
    //let postId = query.get("postId"); /*const Posty: PostPublic = {postId: "IyldwUtauIdQu65TtxX8"}*/

    let PostPublic: PostPublic = {
        postId: postId,
        language: context.locale
    }

    const [errorBool, setErrorBool] = useState(false);
    const [post, setPost] = useState(P);
    const [isLoading, setLoading] = useState(true);

    const fetchPublicProfileUserData = async () => {
        try {
            //const result: CF<ResultUser> = await CF_WEB_G_getPublicProfileUser_onCall({publicUid: post.userData.uid});
            const result: CF<Result<PublicProfileUserInterface>> = await CF_WEB_G_getPublicProfileUser_onCall({publicUid: post.userData.uid});

            setPublicProfileUser(result.data.response);

        } catch (error) {
            console.log("error")
        }
    }

    const fetchPublicProfileAssocData = async () => {
        try {
            //const result: CF<ResultAssoc> = await CF_WEB_G_getPublicProfileAssoc_onCall({assocId: post.assocData?.assocId});
            const result: CF<Result<PublicProfileAssocInterface>> = await CF_WEB_G_getPublicProfileAssoc_onCall({assocId: post.assocData?.assocId});

            setPublicProfileAssoc(result.data.response);

        } catch (error) {
            console.log("error")
        }
    }

    useEffect(() => {

        let PostPublic: PostPublic = {
            postId: postId,
            language: context.locale
        }

        CF_WEB_B_getPostPublic_onCall(PostPublic).then(function(result: CloudResultPost) {
            if (result.data.errorBool) {
                setErrorBool(true);
                console.log(result.data.errorKey) //setErrorKey(result.data.errorKey);
            }
            else {
                setPost(result.data.response);
                console.log(result.data.response);
            }
            setLoading(false);
            console.log('cloudFunction')
        })
        .catch(function(error: {code: string}) {
            if (error.code === undefined) {
                //pass
            }
            else {
                if (error.code === "internal") {
                    //window.alert("internal")
                }
                else {
                    window.alert(error.code) //deadline-exceeded
                }
            }
            setLoading(false);
        });
    }, [postId]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (isLoading === false && errorBool === false) {
            navigator.geolocation.getCurrentPosition((position) => {
                let from = [post.coordinatesOffset._latitude, post.coordinatesOffset._longitude]
                //let from = [10,10]
                let to = [position.coords.latitude, position.coords.longitude]
                let dist = distanceTurf(from, to);
                setDistance(Math.floor(dist));
                console.log('distanceSet')
            }, (error) => {
                console.log(`ERROR(${error.code}): ${error.message}`)
            })
        }
        else {
            //pass
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    
    if (PostPublic.postId == null) {
        return <Redirect to="/" />
    }
    else if (isLoading) {
        return (
            <div className="body">
            <div className="answers">
                <div className="circleContainer">
                    <CircularIntermediate/>
                </div>
            </div>
            </div>
        );
    }
    else {
        if (errorBool === true) {
            return <Redirect to="/" />
        } else {
            return (
                <div className="body">
                <div className="answers">

                {(post.state !== "statePostPosted") && 
                    <div className="inactive">
                        <FormattedMessage id="post.post_inactive" defaultMessage="Post inactive"/>
                    </div>
                }

                <div className="postContainer">
                    <Paper className="image">
                        <div className="imgContainer">
                            {(post.photoUrlArray !== null) ? <ImgCarousel photoUrlArray={post.photoUrlArray}/> : <img id="itemImg" src={post.photoUrlStock} alt=""/>}
                        </div>
                    </Paper>
                    <div className="postInfo">
                        <Paper className="itemInfo">
                            <p className="title" id="item"><strong>{post.dispMap.item}</strong></p>
                            {(post.singleBuyerBool === true) ?
                                <p><FormattedNumber value={post.volumeTotal} style="decimal"/> {post.dispMap.volumeUnits}</p> : /* eslint-disable-line react/style-prop-object */
                                <p><FormattedNumber value={post.minOfferVolume as number} style="decimal"/> to <FormattedNumber value={post.volumeTotal} style="decimal"/> {post.dispMap.volumeUnits}</p> /* eslint-disable-line react/style-prop-object */
                            }
                            <p><strong><FormattedNumber value={post.priceTotal} style="decimal"/> {post.dispMap.priceCurrency} (<FormattedNumber value={post.priceTotal/post.volumeTotal} style="decimal"/> {post.dispMap.priceCurrency}/{post.dispMap.volumeUnits})</strong></p> {/* eslint-disable-line react/style-prop-object */}
                            <div className="bottom">
                                {(distance === 0 ? <p><br/></p> : <p><FormattedNumber value={distance} style="decimal"/> km</p>)} {/* eslint-disable-line react/style-prop-object */}
                                {(post.state === "statePostPosted") ? 
                                    <a href={post.shortLinkWeb}>
                                        <button>
                                            <FormattedMessage id="post.make_offer" defaultMessage="Make offer"/>
                                        </button>
                                    </a> : 
                                    <button className="inButton" disabled={true}>
                                        <FormattedMessage id="post.make_offer" defaultMessage="Make offer"/>
                                    </button>
                                }
                            </div>
                        </Paper>
                        
                        <Paper className="userInfo">
                            {post.assocData ?
                                <div  className="assocUser">
                                    <div className="assocImgName" onClick={handleClickOpenProfileAssoc}>
                                        {(post.userData.photoUrl !== null) ? 
                                            <img id="assocImg" src={post.assocData.photoUrl as string} alt="" width="60px" height="60px"/> :
                                            <img id="assocImg" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" width="60px" height="60px"/>}
                                        <div className="nameStar">
                                            <Typography className="" id="name" noWrap={true}>
                                                <strong>{post.assocData.name}</strong>
                                            </Typography>
                                            <StarRating rating={post.userData.rating}/>
                                        </div>
                                    </div>

                                    <div className="userImgName" onClick={handleClickOpenProfileUser}>
                                        {(post.userData.photoUrl !== null) ? 
                                            <img id="userImg" src={post.userData.photoUrl} alt="" width="40px" height="40px"/> : 
                                            <img id="userImg" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" width="60px" height="60px"/>
                                        }
                                        <div className="name">
                                            <Typography className="" id="name" noWrap={true}>
                                                <FormattedMessage id="contact" defaultMessage="Contact"/>
                                                {': '}
                                                {post.userData.firstName}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div className="userImgName" onClick={handleClickOpenProfileUser}>
                                    {(post.userData.photoUrl !== null) ? <img id="userImg" src={post.userData.photoUrl} alt="" width="60px" height="60px"/> : <img id="userImg" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" width="60px" height="60px"/>}
                                    <div className="nameStar">
                                        <p id="name"><strong>{post.userData.firstName} {post.userData.lastName}</strong></p>
                                        <StarRating rating={post.userData.rating}/>
                                    </div>
                                </div>
                            
                            }

                            <ProfileUserDialog
                                publicProfileUser={publicProfileUser}
                                open={openProfileUser}
                                handleClose={handleCloseProfileUser}
                                postId={postId}
                                setPostId={setPostId}
                                setLoading={setLoading}
                            />
                            <ProfileAssocDialog
                                publicProfileAssoc={publicProfileAssoc}
                                open={openProfileAssoc}
                                handleClose={handleCloseProfileAssoc}
                                postId={postId}
                                setPostId={setPostId}
                                //assocId={assocId}
                                //setAssocId={setAssocId}
                                setLoading={setLoading}
                            />
                    
                            <a href={post.shortLinkWeb}>
                                <button>
                                    <FormattedMessage id="post.send_message" defaultMessage="Send Message"/>
                                </button>
                            </a>
                        </Paper>
                        
                    </div>
                    <div className="postAux">
                        
                        {(post.description !== null) && <Paper className="postDescription">
                            <p className="title" >
                                <strong>
                                    <FormattedMessage id="post.post_description" defaultMessage="Post description"/>
                                </strong>
                            </p>
                            <p className="textOverflow">{post.description}</p>
                            </Paper>
                        }
                        
                        <Paper className="postDetails">
                            <p className="title" >
                                <strong>
                                    <FormattedMessage id="post.post_details" defaultMessage="Post detials"/>
                                </strong>
                            </p>
                            <Table
                                variety={post.dispMap.variety}
                                processed={post.dispMap.processed}
                                earliestExchangeDate={toDate(post.earliestExchangeDateStr)}
                                postEndDate={toDate(post.postEndDateStr)}
                                dateString={{start:"post.earliest_exchange_date", end: "post.post_end_date"}}
                                delivery={post.delivery}
                                deliveryString={{delivery:"post.delivery", collection:"post.collection"}}
                                collection={post.collection}
                                cash={post.paymentCashBool}
                                digital={post.paymentDigitalBool}
                            />
                        </Paper>
                        
                    </div>
                    <PaperMap className="map">
                        <MapContainer lat={post.coordinatesOffset._latitude} lng={post.coordinatesOffset._longitude}/>
                    </PaperMap>
                </div>

                </div>
            </div>
            );
        }
    }
}

export default Posts;
