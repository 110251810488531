import React from 'react';

import disp from "./JSONS/DICT_disp.json";
import { typeDisp } from "./interfaces";

export interface M extends Map<string,string> {}


//checks if json dict object has key
export function hasKey<O>(obj: O, key: keyof any): key is keyof O { return key in obj };

//returns key value
export const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key];

//returns display string wrt locale
export function DisplayString(props: {item: string, locale: string}) {
        
    if (RE(props.locale)) {
        return (
            <>
                {getKeyValue<keyof typeDisp, typeDisp>(props.item)(disp).disp_es}
            </>
        );
    } else {
        return (
            <>
                {getKeyValue<keyof typeDisp, typeDisp>(props.item)(disp).disp_en}
            </>
        );
    }
}

//returns true if locale begins with 'es', ie spanish
export function RE(locale: string) {

    let regex = RegExp('es');
    return regex.test(locale);
}

//sorts string[] alphabetically wrt locale, returns Map object
export function sorting(unsorted: string[], locale: string): M {

    let sort: string[][]

    if (RE(locale)) {
        sort = unsorted.map(key => {
            const getDisp = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_es;
            return [key, getDisp];
        })
    } else {
        sort = unsorted.map(key => {
            const getDisp = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_en;
            return [key, getDisp];
        })
    }

    sort.sort(function(a, b) {
        if (a[1] > b[1]) {
            return 1;
        }
        if (a[1] < b[1]) {
            return -1;
        }
        return 0;
    })

    let rtn: M = new Map();

    sort.forEach(value => {
        rtn.set(value[0], value[1])
    })

    return rtn
}