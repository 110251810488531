import React, { useContext } from "react";

import {
    Paper,
    createStyles,
    makeStyles
} from '@material-ui/core';

import clsx from 'clsx';
import { FormattedNumber, FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
//import Typography from '@material-ui/core/Typography';

import StarRating from "../components/StarRating";

//import disp from "../JSONS/DICT_disp.json";
import items from "../JSONS/DICT_flat_item.json";

import { Context } from "./Wrapper";
import { /* typeDisp, */ typeItems } from "../interfaces";

import { getKeyValue, DisplayString } from "../utils";

import {
    OrderModelPublicInterfaceDistance, //PostDataMinimalInterface,
} from "../interfaces";

interface Props {
    orderData: OrderModelPublicInterfaceDistance //postData: PostDataMinimalInterface
}


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '@media only screen and (min-width: 425px)': {
                marginRight: "10px",
                padding: "10px 20px 10px 20px",
            },
            backgroundColor: "#f9f9f9",
            '@media only screen and (max-width: 425px)': {
                padding: "10px 10px 10px 10px",
            }
        },
    }),
);


export default function OrderSearchItem(props: Props) {

    const context = useContext(Context);
    const classes = useStyles();

    const imgUrl = getKeyValue<keyof typeItems, typeItems>(props.orderData.item)(items).image

    return (
        
        <NavLink to={`/pedido?orderId=${props.orderData.id}`} target="_blank">
        <Paper elevation={3} className={clsx(classes.root, "postSearchItem")}>
            <div className="left">
                <div className="nameImgStars">
                    {props.orderData.assocData ?
                        <>
                            <p>{props.orderData.assocData.name}</p>
                            <img src={imgUrl} alt={""}/>
                            <StarRating rating={props.orderData.assocData.rating} />
                        </>
                        : 
                        <>
                            <p>{props.orderData.userData.firstName}</p>
                            <img src={imgUrl} alt={""}/>
                            <StarRating rating={props.orderData.userData.rating} />
                        </>
                    }
                </div>
                <div className="itemVolumePrice">
                    <div className="item">
                        <p><DisplayString item={props.orderData.item} locale={context.locale}/></p>
                    </div>
                    <div className="volume">
                        <p><span><FormattedMessage id="order.remaining_volume" defaultMessage="Remaining volume"/></span></p>
                        <p>
                            <FormattedNumber value={props.orderData.remainingVolumeKg} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                            KG
                        </p>
                        <p><span><FormattedMessage id="order.minimum_offer_volume" defaultMessage="Mimimum offer volume"/></span></p>
                        {props.orderData.minimumOfferVolumeKg ?
                            <p>
                                <FormattedNumber value={props.orderData.minimumOfferVolumeKg} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                                KG
                            </p>
                        :
                            <p>
                                <FormattedNumber value={props.orderData.remainingVolumeKg} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                                KG
                            </p>
                        }
                    </div>
                    <div className="price">
                        <p><strong><FormattedNumber value={props.orderData.targetPriceTotal/props.orderData.targetVolumeTotalKg} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={0}/> COP / KG</strong></p> {/* eslint-disable-line react/style-prop-object */}
                    </div>
                </div>
            </div>
            <div className="distance">
                <p><span><FormattedMessage id="options.option.distance" defaultMessage="Distance"/></span></p>
                <p>~<FormattedNumber value={props.orderData.distance as number} style="decimal" maximumFractionDigits={0}/> KM</p> {/* eslint-disable-line react/style-prop-object */}
            </div>
        </Paper>
        </NavLink>
        
    );
}

