import React, { useState } from 'react';

import {
    Button,
    Menu,
    TextField,
} from '@material-ui/core';

import { useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { FormattedMessage, FormattedNumber } from "react-intl";


interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    //locale: string
}
  
function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'} 
            decimalSeparator={','}
            isNumericString
            prefix="$"
        />
    );
}

interface IProps {
    price: {min: number | null; max:  number | null;}
    setPrice: React.Dispatch<React.SetStateAction<{min: number | null; max: number | null;}>>
    handleClose: (setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
}


export default function Price(props: IProps) {

    const {register, handleSubmit} = useForm();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [values, setValues] = useState<{min: string | null, max: string | null}>({min: null, max: null});
    const [error, setError] = useState(false);
    

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, setState:  React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {

        let min: string | null;
        let max: string | null;

        if (props.price.min) {
            min = String(props.price.min);
        } else {
            min = null;
        }

        if (props.price.max) {
            max = String(props.price.max);
        } else {
            max = null;
        }

        setValues({min: min, max: max})
        
        setState(event.currentTarget);
    };

    const handleChangeZZ = (event: React.ChangeEvent<HTMLInputElement>) => {

        setError(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };


    const onSubmit = () => {

        if (values.min && values.max) {
            if (Number(values.min) > Number(values.max)) {

                setError(true);
                return;
            }
        }

        let min: number | null;
        let max: number | null;

        if (values.min) {
            min = Number(values.min);
        } else {
            min = null;
        }

        if (values.max) {
            max = Number(values.max);
        } else {
            max = null;
        }

        props.setPrice({
            min: min,
            max: max
        });

        setAnchorEl(null);
    }

    const onReset = () => {
        props.setPrice({
            min: null,
            max: null
        });

        setAnchorEl(null);
    }

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, setAnchorEl)}>
                <p>
                    {/* <FormattedMessage id="options.option.price" defaultMessage="Price"/>
                    <span> – </span> */}
                    {(!props.price.min && !props.price.max) && <FormattedMessage id="options.option.price" defaultMessage="Price"/>}

                    <span className="option">
                        {(props.price.min && !props.price.max) && 
                            <span>
                                <FormattedMessage id="options.above" defaultMessage="Above"/> <FormattedNumber value={props.price.min} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={0}/> COP
                            </span>
                        } {/* eslint-disable-line react/style-prop-object */}
                        {(!props.price.min && props.price.max) && 
                            <span>
                                <FormattedMessage id="options.below" defaultMessage="Below"/> <FormattedNumber value={props.price.max} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={0}/> COP</span>
                        } {/* eslint-disable-line react/style-prop-object */}
                        {(props.price.min && props.price.max) && <span><FormattedNumber value={props.price.min} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={0}/> – <FormattedNumber value={props.price.max} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={0}/> COP</span>} {/* eslint-disable-line react/style-prop-object */}
                    </span>

                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => props.handleClose(setAnchorEl)} //onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    style: {
                        padding: '5px 10px 10px 10px'
                    }
                }}
            >
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit) }>
                    <div className="optionInputs">
                        <TextField
                            label="Min."
                            value={values.min}
                            onChange={handleChangeZZ}
                            inputRef={register({required: false})}
                            name="min"
                            id="formatted-numberformat-min-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                        <TextField
                            label="Max."
                            value={values.max}
                            onChange={handleChangeZZ}
                            inputRef={register({required: false})}
                            name="max"
                            id="formatted-numberformat-max-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any,
                            }}
                        />
                    </div>
                    {error && <div className="error">
                        <FormattedMessage id="options.errorMsg" defaultMessage="Error with minimum/maximum"/>
                    </div>}
                    <div className="priceOptionButtons">
                        {/* <input type="button" onClick={onReset} value="Reset"/>
                        <input type="submit" value="Aplicar"/> */}
                        <FormattedMessage id="search.clear" defaultMessage="Clear">
                            {(msg: string) => <input type="button" onClick={onReset} value = {msg} />}
                        </FormattedMessage>
                        <FormattedMessage id="search.apply" defaultMessage="Apply">
                            {(msg: string) => <input type="submit" value = {msg} />}
                        </FormattedMessage>
                    </div>
                    
                </form>
            </Menu>
        </div>
    );
}
