import React, { useContext, useState, useEffect } from "react";

import SliderOrder from "./SliderOrder";
import { Context } from "./Wrapper";
import functions from "../firebase";

import {
    CloudResultOrderCarousel,
    OrderCarouselModelPublicWebInterface
} from '../interfaces';


interface coords {
    latitude: number | null
    longitude: number | null
}

interface GeolocationCoordinates {
    accuracy: number
    altitude: number
    altitudeAccuracy: null | number
    heading: null | number
    latitude: number
    longitude: number
    speed: null | number
}

interface Position {
    coords: GeolocationCoordinates
}


let CF_WEB_U_spatialOrders_onCall = functions.httpsCallable('CF_WEB_U_spatialOrders_onCall');

let OrderCarouselArray:OrderCarouselModelPublicWebInterface[]; // let init:PostCarouselModelPublicWebInterface[];


const OrdersCarousel: React.FC = () => {

    const context = useContext(Context);
    const [orderCarouselArray, setOrderCarouselArray] = useState(OrderCarouselArray);
    
    const [navCoords, setNavCoords] = useState({latitude: null, longitude: null});
    const [permissionSafari, setPermissionSafari] = useState(false);

    /*let options = {
        enableHighAccuracy: true, // true; // enableHighAccuracy: false | true
        timeout: 5000, // 5000; // integer (milliseconds) - amount of time before the error callback is invoked, if 0 it will never invoke.
        maximumAge: 0 // integer (milliseconds) | infinity - maximum cached position age.
    }*/

    function getCurrentPosition(options = {timeout: 5000}): Promise<Position> | any {

        console.log("getCurrentPosition");

        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    const fetchCarousel = async (coords: coords) => {
        //console.log('fetchCarousel')
        //let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
        try {
            let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
            setOrderCarouselArray(result.data.response);
        } catch (error) {
            //console.log(result.data.errorKey)
            console.log(error)
        }
    }

    const fetchCoordinates = async () => {
        try {
            const { coords } = await getCurrentPosition();
            //setPostCarouselArray(PostCarouselArray);
            setNavCoords(coords);
            setPermissionSafari(true);
        } catch (error) {
            console.error(`Error code: ${error.code}; error msg: ${error.message}`)
        }
    };

    const methodThree = async () => {

        console.log("methodThree");

        let permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
    
        if (permissionStatus.state === "granted") {
            
            console.log("granted!")
            
            try {

                console.log("pre-coords");
                
                const { coords } = await getCurrentPosition(); console.log(coords.latitude, coords.longitude);

                console.log("post-coords");
                console.log(coords);

                try {
                    let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
                    setOrderCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error)
                }
            } catch {
                
                console.log("error w/ getCurrentPosition()")

                try {
                    let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                    setOrderCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error)
                }
            }
        } else {
            //console.log("notGranted!")

            try {
                let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                setOrderCarouselArray(result.data.response);
            } catch (error) {
                console.log(error)
            }

            try {
                const { coords } = await getCurrentPosition(); console.log("interpromt!");

                try {
                    //console.log("setting after interprompt")
                    let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
                    //console.log(result.data.response)
                    setOrderCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error) //console.log(result.data.errorKey)
                }
            } catch {
                console.log("error w/ getCurrentPosition()")
            }
        }

        permissionStatus.onchange = async e => {
            //console.log("changed")
            setOrderCarouselArray(OrderCarouselArray);
            if (permissionStatus.state === "granted") {
                //console.log("grantedOnChange!")
                try {
                    const { coords } = await getCurrentPosition();
                    //console.log(coords.latitude, coords.longitude)
                    try {
                        let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: coords.latitude, lng: coords.longitude, radius: null, limit: 10, language: context.locale});
                        setOrderCarouselArray(result.data.response);
                    } catch (error) {
                        console.log(error)
                    }
                } catch {
                    //console.log("error w/ getCurrentPosition()")
                    try {
                        let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                        setOrderCarouselArray(result.data.response);
                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                //console.log("notGranted!")
                try {
                    let result: CloudResultOrderCarousel = await CF_WEB_U_spatialOrders_onCall({lat: null, lng: null, radius: null, limit: 10, language: context.locale});
                    setOrderCarouselArray(result.data.response);
                } catch (error) {
                    console.log(error)
                }
            }
        }
        
    }

    
    useEffect(() => {
        try {
            console.log("useEffect");
            //Chrome & firefox
            navigator.permissions.query({ name: 'geolocation' })
            methodThree();
        } catch {
            //Safari and others
            fetchCarousel(navCoords);
            fetchCoordinates();
        }
    }, [permissionSafari]); // eslint-disable-line react-hooks/exhaustive-deps


    return <SliderOrder orderCarouselArray={orderCarouselArray}/>
}

export default OrdersCarousel;
