import React, { useState } from 'react';


import {
    Button,
    Menu,
    MenuItem,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from "react-intl";

import { BuySearchSortOptions } from "../../interfaces";


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiTypography-root": {
                fontSize: "13px",
            }
        }
    }),
);

interface IProps {
    itemSelect: string | null
    sortBy: string
    setSortBy: React.Dispatch<React.SetStateAction<BuySearchSortOptions>>
    handleClick: (event: React.MouseEvent<HTMLButtonElement>, setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, setState: React.Dispatch<React.SetStateAction<string>>) => void
    handleClose: (setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
}


export default function SortBy(props: IProps) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setState:  React.Dispatch<React.SetStateAction<BuySearchSortOptions>>) => {
        setState(event.target.value as BuySearchSortOptions);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => props.handleClick(e, setAnchorEl)}>
                <p>
                    {/* <FormattedMessage id="options.option.sortBy" defaultMessage="Sort by"/>
                    <span> – </span> */}
                    <span className="option"><FormattedMessage id={`options.sortBy.${props.sortBy}`} defaultMessage={props.sortBy}/></span>
                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => props.handleClose(setAnchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
            >
                <FormControl component="fieldset" className={classes.root}>
                    <RadioGroup aria-label="gender" name="gender1" value={props.sortBy} onChange={(e) => handleChange(e, props.setSortBy)}>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value="sortByDistanceLowHigh"
                                control={<Radio />}
                                label={<FormattedMessage id="options.sortBy.sortByDistanceLowHigh" defaultMessage="Distance: low to high"/>} //label="Distance asc"
                            />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value="sortByPriceLowHigh"
                                control={<Radio />}
                                label={<FormattedMessage id="options.sortBy.sortByPriceLowHigh" defaultMessage="Price: low to high"/>} //label="Distance asc"
                            />
                        </MenuItem>
                        <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                            <FormControlLabel
                                value="sortByPriceHighLow"
                                control={<Radio />}
                                label={<FormattedMessage id="options.sortBy.sortByPriceHighLow" defaultMessage="Price: hight to low"/>} //label="Distance asc"
                            />
                        </MenuItem>

                        {(props.itemSelect !== null) && <>
                            <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                                <FormControlLabel
                                    value="sortByVolumeLowHigh"
                                    control={<Radio />}
                                    label={<FormattedMessage id="options.sortBy.sortByVolumeLowHigh" defaultMessage="Volume: low to high"/>} //label="Distance asc"
                                />
                            </MenuItem>
                            <MenuItem onClick={() => props.handleClose(setAnchorEl)}>
                                <FormControlLabel
                                    value="sortByVolumeHighLow"
                                    control={<Radio />}
                                    label={<FormattedMessage id="options.sortBy.sortByVolumeHighLow" defaultMessage="Volume: high to low"/>} //label="Distance asc"
                                />
                            </MenuItem>
                        </>}

                    </RadioGroup>
                </FormControl>
            </Menu>
        </div>
    );
}
