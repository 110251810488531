import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer4 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-agricultores-cómo-realizar-el-intercambio">Para agricultores, ¿cómo realizar el intercambio?</h1>
            

            <ul>
              <li><Link to="#opción-1-intercambiar-a-través-del-menú-de-mensajes">A través del menú de mensajes</Link></li>
              <li><Link to="#opción-2-intercambiar-a-través-de-la-aplicación">A través de la aplicación</Link></li>
            </ul>
            <br/>

            <blockquote>
              <p>Si ha realizado una publicación individual, esta será visible para los demás usuarios de Listo y empezará a recibir ofertas por parte de los compradores.</p>
            </blockquote>

            <blockquote>
              <p>Por otro lado, si ha enviado una solicitud de contribución a un pedido de asociación o de comprador y esta ha sido aceptada, podrá proceder con el intercambio.</p>
            </blockquote>

            <br/>
            <h2 id="opción-1-intercambiar-a-través-del-menú-de-mensajes">Opción 1. Intercambiar a través del menú de mensajes</h2>
            <blockquote>
              <p>Una vez reciba una oferta, Listo le notificará a través de un mensaje de texto enviado desde el número 891177. Si esta oferta es de su interés y desea aceptarla, simplemente debe seguir las instrucciones que aparecen en el mensaje de texto.</p>
            </blockquote>

            <br/>

            <h3 id="aceptar-la-oferta">Aceptar la oferta</h3>
            <ol>
              <li>
                <strong>Responda LT si</strong> (sin tilde para la palabra “sí”), escriba <strong>los 3 números únicos que aparecen en el mensaje</strong>, por ejemplo, <strong>LT si 123</strong>.
              </li>
              <li>
                <strong>Envíe esta respuesta</strong> y habrá aceptado la oferta.
              </li>
            </ol>

            <br/>

            <h3 id="ingresar-el-código-de-intercambio">Ingresar el código de intercambio</h3>
            <blockquote>
              <p>Cuando haya llegado la fecha de intercambio, usted podrá ingresar el código de intercambio del otro usuario a través del menú de mensajes.</p>
            </blockquote>

            <ol>
              <li>Escriba al número 891177 con el código <strong>LT i</strong> para indicar que es un intercambio.
                <blockquote>
                  <p>Espere el mensaje de respuesta y siga las instrucciones.</p>
                </blockquote>
              </li>
              <li>
                Responda <strong>LT i</strong> seguido del número de la publicación (<strong>una cifra de tres dígitos</strong> que aparecerá al inicio del mensaje) más <strong>los cuatro números del código de intercambio del otro usuario</strong>. Por ejemplo, <strong>LT i 123 4567</strong>.
              </li>
              <li><strong>Envíe el mensaje</strong> y recibirá una respuesta indicándole que el código de intercambio es correcto.
                <blockquote>
                  <p>Recuerde que para ingresar el código de intercambio, usted <strong>deberá pedirle al otro usuario</strong> este número.</p>
                </blockquote>
              </li>
            </ol>

            <br/>
            <br/>

            <h2 id="opción-2-intercambiar-a-través-de-la-aplicación">Opción 2. Intercambiar a través de la aplicación</h2>

            <br/>

            <h3 id="aceptar-la-oferta-1">Aceptar la oferta</h3>
            <blockquote>
              <p>A través de la aplicación, las ofertas recibidas aparecerán en <strong>la campana de notificaciones</strong> ubicada en la página de inicio; o en <strong>la pestaña de Intercambio</strong>, en <strong>la ventana Vender</strong>, en la sección <strong>Publicaciones activas</strong>.</p>
            </blockquote>

            <ol>
              <li>
                Para acceder a una oferta, haga clic sobre ella y esto lo llevará a una nueva ventana donde aparecerá el resumen de la oferta.
              </li>
              <li>
                Para aceptar dicha oferta, <strong>oprima el botón Aceptar</strong> y seguidamente <strong>el botón Confirmar</strong>.
                <blockquote>
                  <p>Puede llamar al otro usuario para discutir los detalles de la oferta, el número de contacto estará en la información de la oferta.</p>
                </blockquote>
              </li>
            </ol>

            <br/>

            <h3 id="ingresar-el-código-de-intercambio-1">Ingresar el código de intercambio</h3>
            <blockquote>
              <p>Cuando haya llegado la fecha de intercambio, usted debe ingresar el código de intercambio para verificar que el otro usuario sí sea con quien negoció en Listo.</p>
            </blockquote>

            <ol>
              <li>
                En la aplicación, diríjase a la publicación del producto que va intercambiar, haga clic en la publicación.
              </li>
              <li>Podrá <strong>observar cuatro casillas</strong>, allí debe ingresar el código de intercambio del otro usuario.
                <blockquote>
                  <p>Para esto es necesario que le solicite al otro usuario su código de intercambio personal.</p>
                </blockquote>
              </li>
              <li>
                <strong>Escriba el código en estas casillas</strong>.
              </li>
              <li><strong>Oprima el botón Enviar código</strong> y seguidamente <strong>el botón Confirmar</strong>.</li>
            </ol>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer4;