import React, { useContext, useState, useEffect } from 'react';

import { FormattedMessage } from "react-intl";
import clsx from 'clsx';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialog from '@material-ui/core/Dialog';

import { 
    createStyles, Theme, withStyles, WithStyles,
    Button,
    IconButton,
    Divider,
    Switch
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import farmer from "../images/icons/icon_farmer_50px.png";
import trader from "../images/icons/icon_trader_50px.png";
import assoc from "../images/icons/icon_association_50px.png";
import transporter from "../images/icons/icon_jeep_50px.png";

import items from "../JSONS/DICT_flat_item.json";

import { Context } from "./Wrapper";
import { sorting, DisplayString, getKeyValue } from "../utils";

import {
    typeItems,
    FilterMapInterface
} from "../interfaces";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    //children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {/* <Typography variant="h6">{children}</Typography> */}
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        //maxWidth: "600px", //maxWidth: "320px"
        minWidth: "600px",
        //'@media only screen and (max-width: 1024px)': {
        '@media only screen and (max-width: 620px)': {
            maxWidth: "90vw",
            minWidth: "90vw",
        }
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        '& .MuiButton-label': {
            color: "green",
            fontSize: "15px",
            textTransform: "none"
        }
    },
}))(MuiDialogActions);

const Dialog = withStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            margin: 'auto',
        },
    },
}))(MuiDialog);


interface IProps {
    filterMap: FilterMapInterface | null
    setFilterIds: React.Dispatch<React.SetStateAction<string[]>>
    open: boolean
    handleClose: () => void
    filterItemsSuper: string[]
    setFilterItemsSuper: React.Dispatch<React.SetStateAction<string[]>>
    filterRolesSuper: string[]
    setFilterRolesSuper: React.Dispatch<React.SetStateAction<string[]>>
    checkedSwitchSuper: boolean
    setCheckedSwitchSuper: React.Dispatch<React.SetStateAction<boolean>>
}


export default function FilterDialog(props: IProps) {

    const context = useContext(Context);

    const [filterRoles, setFilterRoles] = useState<string[]>([]);
    const [filterItems, setFilterItems] = useState<string[]>([]);

    const [checkedSwitch, setCheckedSwitch] = useState<boolean>(false);

    useEffect(() => {
        setFilterRoles(props.filterRolesSuper);
        setFilterItems(props.filterItemsSuper);
        setCheckedSwitch(props.checkedSwitchSuper);
    }, [props.open, props.filterItemsSuper, props.filterRolesSuper, props.checkedSwitchSuper]);

    const handleClickRole = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        
        const id = event.currentTarget.id;

        if (filterRoles.includes(id)) {
            setFilterRoles(prevState => {
                return prevState.filter(item => item !== id);
            })
        } else {
            setFilterRoles(prevState => {
                return prevState.concat(id);
            })
        }

        return null
    }

    const handleClickItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        
        const id = event.currentTarget.id

        if (filterItems.includes(id)) {
            setFilterItems(prevState => {
                return prevState.filter(item => item !== id);
            })
        } else {
            setFilterItems(prevState => {
                return prevState.concat(id);
            })
        }

        return null
    }

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedSwitch(event.target.checked);
    }

    const handleClickReset = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        
        setFilterRoles([]);
        setFilterItems([]);
        setCheckedSwitch(false);

        return null
    }

    const handleClickApply = () => {

        let array: string[] = [];

        if (checkedSwitch === true) {
            if (filterItems.length === 0 && filterRoles.length === 0) {
                for (let item in props.filterMap?.item.live) {
                    array = array.concat(props.filterMap?.item.live[item] as string[])
                }
                for (let role in props.filterMap?.role.live) {
                    array = array.concat(props.filterMap?.role.live[role] as string[])
                }
            } else {
                filterItems.forEach((value) => {
                    array = array.concat(props.filterMap?.item.live[value] as string[])
                })
    
                filterRoles.forEach((value) => {
                    array = array.concat(props.filterMap?.role.live[value] as string[])
                })
            }
        } else {
            filterItems.forEach((value) => {
                array = array.concat(props.filterMap?.item.all[value] as string[])
            })

            filterRoles.forEach((value) => {
                array = array.concat(props.filterMap?.role.all[value] as string[])
            })
        }

        let uniqueIds = [...Array.from(new Set(array))]

        props.setFilterRolesSuper(filterRoles);
        props.setFilterItemsSuper(filterItems);
        props.setCheckedSwitchSuper(checkedSwitch);
        props.setFilterIds(uniqueIds);
        props.handleClose();

        return null
        
    }

    //let itemsSorted: M;

    if (props.filterMap === null) {
        return null
    } else {
        const itemsUnsorted = Object.keys(props.filterMap.item.all)
        const itemsSorted = sorting(itemsUnsorted, context.locale);
        //console.log(itemsSorted);
    
        return (
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open} className="filterDialog">
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}/>
                <DialogContent>
                    <div className="filterRolesAndTitle">
                        <p className="title">
                            <FormattedMessage id="filter.roles" defaultMessage="Roles"/>
                        </p>

                        <div className="filterRoles">
                            {props.filterMap.role.all.farmer &&
                                <div className={clsx("filterRole", String(filterRoles.includes("farmer")))} id="farmer" onClick={handleClickRole}>
                                    <img src={farmer} alt=""/>
                                    <p>
                                        <FormattedMessage id="filter.farmer" defaultMessage="Farmer"/>
                                        {' '}
                                        ({props.filterMap.role.all.farmer.length})
                                    </p>
                                </div>
                            }
                            {props.filterMap.role.all.traderBuyer &&
                                <div className={clsx("filterRole", String(filterRoles.includes("traderBuyer")))} id="traderBuyer" onClick={handleClickRole}>
                                    <img src={trader} alt=""/>
                                    <p>
                                        <FormattedMessage id="filter.traderBuyer" defaultMessage="Trader (Buyer)"/>
                                        {' '}
                                        ({props.filterMap.role.all.traderBuyer.length})
                                    </p>
                                </div>
                            }
                            {props.filterMap.role.all.traderSeller &&
                                <div className={clsx("filterRole", String(filterRoles.includes("traderSeller")))} id="traderSeller" onClick={handleClickRole}>
                                    <img src={trader} alt=""/>
                                    <p>
                                        <FormattedMessage id="filter.traderSeller" defaultMessage="Trader (Seller)"/>
                                        {' '}
                                        ({props.filterMap.role.all.traderSeller.length})
                                    </p>
                                </div>
                            }
                            {props.filterMap.role.all.assoc &&
                            <div className={clsx("filterRole", String(filterRoles.includes("assoc")))} id="assoc" onClick={handleClickRole}>
                                <img src={assoc} alt=""/>
                                <p>
                                    <FormattedMessage id="filter.assoc" defaultMessage="Association"/>
                                    {' '}
                                    ({props.filterMap.role.all.assoc.length})
                                </p>
                            </div>
                            }
                            {props.filterMap.role.all.transporter &&
                                <div className={clsx("filterRole", String(filterRoles.includes("transporter")))} id="transporter" onClick={handleClickRole}>
                                    <img src={transporter} alt=""/>
                                    <p>
                                        <FormattedMessage id="filter.transporter" defaultMessage="Transporter"/>
                                        {' '}
                                        ({props.filterMap.role.all.transporter.length})
                                    </p>
                                </div>
                            }
                        </div>
                        <Divider/>
                    </div>

                   

                    <div className="filterItemsAndTitle">
                        <p className="title">
                            <FormattedMessage id="filter.items" defaultMessage="Items"/>
                        </p>

                        <div className="filterItems">
                            {Array.from(itemsSorted.keys()).map((value, index) => {
                                return (
                                    <div key={index} id={value} className={clsx("filterItem", String(filterItems.includes(value)))} onClick={handleClickItem}>
                                    {/* <div key={index} id={value} className="filterItem" onClick={handleClick}> */}
                                        <img src={getKeyValue<keyof typeItems, typeItems>(value)(items).image} alt=""/>
                                        <p>
                                            <DisplayString item={value} locale={context.locale}/>
                                            {' '}
                                            ({props.filterMap && props.filterMap.item.all[value].length})
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <Divider/>
                    </div>
                    
                    <div className="onlyLive" onClick={() => {}}>
                        <p>
                            <NewReleasesIcon/>
                            <FormattedMessage id="explore.onlyLive" defaultMessage="Only show users with live posts"/>
                        </p>
                        <Switch
                            checked={checkedSwitch}
                            onChange={handleChangeSwitch}
                            //color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        
                    </div>
                    <Divider/>

                    <div className="resetFilter">
                            <p onClick={handleClickReset}>
                                <FormattedMessage id="explore.clearFilters" defaultMessage="Clear all filters"/>
                            </p>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClickApply} /* color="primary" */>
                        <FormattedMessage id="explore.apply" defaultMessage="Apply"/>
                    </Button>
                </DialogActions>
            </Dialog>
            
        );
    }
}
