import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ReactPlayer from 'react-player/youtube';
 
class Answer5 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-asociaciones-cómo-monitorear-un-pedido-grupal">Para asociaciones, ¿cómo monitorear un pedido grupal?</h1>
            
            <p>Al crear un pedido grupal, usted podrá monitorear el desarrollo del mismo a través del <strong>Resumen del pedido</strong>.</p>

            <br/>
            <ul>
              <li><Link to="#acceder-al-resumen-del-pedido">Acceder al Resumen del pedido</Link></li>
              <li><Link to="#sobre-el-resumen-del-pedido">Sobre el Resumen del pedido</Link></li>
              <li><Link to="#aceptar-las-solicitudes-recibidas">Aceptar las solicitudes recibidas</Link></li>
              <li><Link to="#marcar-el-pedido-como-completo">Marcar el pedido como completo</Link></li>
            </ul>
            <br/>

            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=h9XMnm69txk&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>

            <h2 id="acceder-al-resumen-del-pedido">Acceder al Resumen del pedido</h2>
            <p>Puede acceder al <strong>Resumen del pedido</strong>:</p>
            <ul>
              <li>desde <strong>la pestaña de Intercambio</strong>, en la ventana <strong>Comprar</strong>, en la categoría <strong>Pedidos activos</strong>;</li>
              <li>desde <strong>el perfil de la asociación</strong> en la ventana <strong>Pedidos</strong>.</li>
            </ul>

            <br/>
            <h2 id="sobre-el-resumen-del-pedido">Sobre el Resumen del pedido</h2>
            <p>El <strong>Resumen del pedido</strong> muestra <strong>el volumen objetivo</strong> que quiere acopiar y que ha acopiado hasta el momento. También indica <strong>la cantidad de dinero</strong> que va a invertir, o que ya se ha invertido, durante el acopio de determinado volumen.</p>

            <p>El <strong>Resumen del pedido</strong> genera <strong>un gráfico circular</strong>, en el cual puede observar <strong>los porcentajes de los diferentes estados de las solicitudes recibidas</strong>.</p>
            <blockquote>
              <p>Por ejemplo:</p>
              <ul>
                <li>el color <strong>gris</strong> muestra el porcentaje <strong>vacío del pedido</strong>, es decir, el porcentaje del volumen que hace falta por acopiar;</li>
                <li>el color <strong>azul</strong> representa el porcentaje de <strong>las solicitudes hechas</strong> al pedido grupal que han sido aceptadas;</li>
                <li>el color <strong>verde</strong> indica el porcentaje de <strong>intercambios realizados</strong>;</li>
                <li>el color <strong>amarillo</strong> informa el porcentaje de <strong>interacciones calificadas</strong>;</li>
                <li>el color <strong>marrón</strong> muestra el porcentaje de <strong>solicitudes que están pendientes</strong> o a la espera de ser rechazadas o aceptadas.</li>
              </ul>
            </blockquote>

            <p>El <strong>gráfico circular</strong> se irá <strong>actualizando automáticamente</strong> de acuerdo con las solicitudes de participación recibidas y el estado de cada una de ellas. Usted puede <strong>monitorear estas solicitudes y sus estados</strong> desde el gráfico circular o desde la sección <strong>Solicitudes recibidas</strong>, donde se explican con más detalle.</p>

            <br/>
            <h2 id="aceptar-las-solicitudes-recibidas">Aceptar las solicitudes recibidas</h2>
            <p>Al <strong>tener solicitudes</strong> en el pedido grupal, usted podrá <strong>aceptarlas</strong> o <strong>rechazarlas</strong>.</p>
            <blockquote>
              <p>Es importante recordar que puede <strong>contactar al usuario</strong> a través de <strong>una llamada telefónica</strong>, antes de realizar cualquier acción, para <strong>negociar los detalles</strong> de la solicitud.</p>
            </blockquote>

            <ol>
              <li>
                Diríjase a la sección <strong>Solicitudes recibidas</strong> y <strong>haga clic en la solicitud pendiente</strong>.
              </li>
              <li>
                Allí verá el <strong>resumen de la solicitud</strong> y sus detalles. Oprima <strong>la opción Aceptar</strong>.
              </li>
            </ol>

            <br/>
            <h2 id="marcar-el-pedido-como-completo">Marcar el pedido como completo</h2>

            <p>Una vez haya alcanzado el volumen objetivo de acopio, podrá marcar el pedido como completo.</p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer5;