import React, { useContext } from "react";

import {
    Paper,
    createStyles,
    makeStyles
} from '@material-ui/core';

import clsx from 'clsx';
import { FormattedNumber, FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
//import Typography from '@material-ui/core/Typography';

import StarRating from "../components/StarRating";

//import disp from "../JSONS/DICT_disp.json";

import { Context } from "./Wrapper";
//import { typeDisp } from "../interfaces";

import { DisplayString } from "../utils";

interface Props {
    postData: PostDataMinimalInterface
}

interface PostDataMinimalInterface {
    distance: number
    id: string
    item: string
    volumeUnits: string
    volumeTotal: number
    minOfferVolume: number | null
    priceTotal: number
    postPhotoUrl: string
    userFirstName: string
    userRating: number
    assocName: string | null
    assocRating: number | null
    coordinatesOffset: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '@media only screen and (min-width: 425px)': {
                marginRight: "10px",
                padding: "10px 20px 10px 20px",
            },
            backgroundColor: "#f9f9f9",
            '@media only screen and (max-width: 425px)': {
                padding: "10px 10px 10px 10px",
            }
        },
    }),
);


export default function PostSearchItem(props: Props) {

    const context = useContext(Context);
    const classes = useStyles();

    return (
        
        <NavLink to={`/publicacion?postId=${props.postData.id}`} target="_blank">
        <Paper elevation={3} className={clsx(classes.root, "postSearchItem")}>
            <div className="left">
                <div className="nameImgStars">
                    {props.postData.assocName ? 
                            <>
                                <p>{props.postData.assocName}</p>
                                <img src={props.postData.postPhotoUrl} alt={""}/>
                                <StarRating rating={props.postData.assocRating} />
                            </>
                        :
                            <>
                                <p>{props.postData.userFirstName}</p>
                                <img src={props.postData.postPhotoUrl} alt={""}/>
                                <StarRating rating={props.postData.userRating} />
                            </>
                    }
                </div>
                <div className="itemVolumePrice">
                    <div className="item">
                        <p><DisplayString item={props.postData.item} locale={context.locale}/></p>
                    </div>
                    <div className="volume">
                        <p><span><FormattedMessage id="options.option.volume" defaultMessage="Volume"/></span></p>
                        <p>
                            {props.postData.minOfferVolume ?
                                <>
                                    <FormattedNumber value={props.postData.minOfferVolume} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                                    <FormattedMessage id="home.to" defaultMessage="to"/> {' '}
                                    <FormattedNumber value={props.postData.volumeTotal} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                                </>
                                :
                                <>
                                    <FormattedNumber value={props.postData.volumeTotal} style="decimal" maximumFractionDigits={1}/> {/*eslint-disable-line react/style-prop-object*/}
                                    {' '}
                                </>
                            }
                            <DisplayString item={props.postData.volumeUnits} locale={context.locale}/>
                        </p> 
                    </div>
                    <div className="price">
                        <p><strong><FormattedNumber value={props.postData.priceTotal/props.postData.volumeTotal} /* style="currency" currency="COP" */ minimumFractionDigits={0} maximumFractionDigits={0}/>  COP / <DisplayString item={props.postData.volumeUnits} locale={context.locale}/></strong></p> {/* eslint-disable-line react/style-prop-object */}
                    </div>
                </div>
            </div>
            <div className="distance">
                <p><span><FormattedMessage id="options.option.distance" defaultMessage="Distance"/></span></p>
                <p>~<FormattedNumber value={props.postData.distance} style="decimal" maximumFractionDigits={0}/> KM</p> {/* eslint-disable-line react/style-prop-object */}
            </div>
        </Paper>
        </NavLink>
        
    );
}

