import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { FormattedMessage, FormattedNumber } from "react-intl";
import { NavLink } from "react-router-dom";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialog from '@material-ui/core/Dialog';
//import MuiDialogActions from '@material-ui/core/DialogActions';

import { 
    createStyles, Theme, withStyles, WithStyles,
    //Dialog,
    IconButton,
    Divider
} from '@material-ui/core';

/* import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'; */
import StorefrontIcon from '@material-ui/icons/Storefront';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';

import CloseIcon from '@material-ui/icons/Close';
//import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import StarRating from "./StarRating";

import items from "../JSONS/DICT_flat_item.json";
//import disp from "../JSONS/DICT_disp.json";

import { Context } from "./Wrapper";
import { DisplayString, getKeyValue } from "../utils";

import {
    PublicProfileAssocInterface,
    //StaticRoleModelOutbound,
    PublicPostInterface,
    typeItems,
} from "../interfaces";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    //children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {/* <Typography variant="h6">{children}</Typography> */}
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        //maxWidth: "600px", //maxWidth: "320px"
        minWidth: "600px",
        //'@media only screen and (max-width: 1024px)': {
        '@media only screen and (max-width: 620px)': {
            maxWidth: "90vw",
            minWidth: "90vw",
        }
    },
}))(MuiDialogContent);

const Dialog = withStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            margin: 'auto',
        },
    },
}))(MuiDialog);

/* const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions); */


interface IProps {
    publicProfileAssoc: PublicProfileAssocInterface | null
    open: boolean
    handleClose: () => void
    postId?: string | null
    setPostId?: React.Dispatch<React.SetStateAction<string | null>>
    //assocId: string | null
    //setAssocId: React.Dispatch<React.SetStateAction<string | null>>
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}


export default function ProfileAssocDialog(props: IProps) {

    const context = useContext(Context);

    /* const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
        props.handleClose();

        if (event.currentTarget.id !== props.postId) {
            props.setLoading(true);
            props.setPostId(event.currentTarget.id);
        }
    } */

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
        props.handleClose();

        if (event.currentTarget.id !== props.postId && props.setPostId && props.setLoading) {
            props.setLoading(true);
            props.setPostId(event.currentTarget.id);
        }
    }

    //console.log(props.publicProfileAssoc);
    //console.log(props.publicProfileAssoc?.staticData.data.buyItems.items);
    //console.log(props.publicProfileAssoc?.staticData.data.buyItems.items.length);


    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open} className="profileUserDialog">
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}/>
            <DialogContent>
                {props.publicProfileAssoc ? 
                    <div>
                        
                        {props.publicProfileAssoc.assocData.photoUrl ?
                            <img className="profileImg" src={props.publicProfileAssoc.assocData.photoUrl} alt={""}/> :
                            <img className="profileImg" src={""} alt={""}/>
                        }
                        <p>{props.publicProfileAssoc.assocData.name}</p>

                        <div className="orgType">
                            <OrgIcon orgType={props.publicProfileAssoc.assocData.orgType}/>
                            <FormattedMessage id={`profileAssoc.${props.publicProfileAssoc.assocData.orgType}`} defaultMessage={props.publicProfileAssoc.assocData.orgType}/>
                        </div>

                        <span className="starRating">
                            <StarRating rating={props.publicProfileAssoc.assocData.rating}/>
                            <p>({props.publicProfileAssoc.assocData.reviewCalcMap.numReviews})</p>
                        </span>

                        <div className="assocTel">
                            <PhoneIcon/>
                            <p>{props.publicProfileAssoc.assocData.phoneNumber}</p>
                        </div>

                        {props.publicProfileAssoc.assocData.bio && <p className="bio">{props.publicProfileAssoc.assocData.bio}</p>}
                        

                        {props.publicProfileAssoc.staticData &&
                            <div style={{ height: '220px', width: '100%', marginTop: '5px' }} /* className="map" */>
                            <GoogleMapReact
                                defaultCenter={{
                                    lat: props.publicProfileAssoc.staticData.coordinatesOffset._latitude,
                                    lng: props.publicProfileAssoc.staticData.coordinatesOffset._longitude
                                }}
                                defaultZoom={10}
                                options={{
                                    maxZoom: 16,
                                    clickableIcons: false
                                }}
                            >
                                <Circle
                                    lat={props.publicProfileAssoc.staticData.coordinatesOffset._latitude}
                                    lng={props.publicProfileAssoc.staticData.coordinatesOffset._longitude}
                                />

                            </GoogleMapReact>
                            </div>
                        }

                        <br/>

                        <h2 className="roles">
                            <FormattedMessage id="profileAssoc.orgOwner" defaultMessage="Organisation leader"/>
                        </h2>

                        <div className="orgOwner">
                            {props.publicProfileAssoc.publicUserDataOwner.userData.photoUrl ?
                                <img className="" src={props.publicProfileAssoc.publicUserDataOwner.userData.photoUrl} alt={""}/> :
                                <img className="" src={""} alt={""}/>
                            }
                            <p>
                                {props.publicProfileAssoc.publicUserDataOwner.userData.firstName} {' '}
                                {props.publicProfileAssoc.publicUserDataOwner.userData.lastName}
                            </p>
                        </div>

                        <br/>

                        <h2 className="roles">
                            <FormattedMessage id="profileAssoc.products" defaultMessage="Products"/>
                        </h2>
                            
                        {props.publicProfileAssoc.staticData.data.buyItems.items.length > 0 &&
                            <div className="roleDesc">
                                <p className="role">
                                    <FormattedMessage id="profileAssoc.buying" defaultMessage="We buy"/>
                                </p>
                                <div className="roleItems">
                                    {props.publicProfileAssoc.staticData.data.buyItems.items.map((value: string, i: number) => 
                                        <div key={i} className="roleItem">
                                            <img src={getKeyValue<keyof typeItems, typeItems>(value)(items).image} alt=""/>
                                            <p>
                                                <DisplayString item={value} locale={context.locale}/>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <Divider/>
                            </div>
                        }
                        {props.publicProfileAssoc.staticData.data.sellItems.items.length > 0 &&
                            <div className="roleDesc">
                                <p className="role">
                                    <FormattedMessage id="profileAssoc.selling" defaultMessage="We sell"/>
                                </p>
                                <div className="roleItems">
                                    {props.publicProfileAssoc.staticData.data.sellItems.items.map((value: string, i: number) => 
                                        <div key={i} className="roleItem">
                                            <img src={getKeyValue<keyof typeItems, typeItems>(value)(items).image} alt=""/>
                                            <p>
                                                <DisplayString item={value} locale={context.locale}/>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <Divider/>
                            </div>
                        }

                        {props.publicProfileAssoc.postsData.length > 0 &&
                            <div className="livePosts">
                                <h2>
                                    <FormattedMessage id="profileUser.livePosts" defaultMessage="Live Posts"/>
                                </h2>

                                {props.publicProfileAssoc.postsData?.map((value: PublicPostInterface, i: number) =>

                                <NavLink key={i} to={`/publicacion?postId=${value.id}`}>
                                <div className="livePost" id={value.id} onClick={handleClick} >
                                    <p>
                                        <DisplayString item={value.item} locale={context.locale}/>
                                    </p>
                                    <div className="imgVolPrice">
                                        <img src={getKeyValue<keyof typeItems, typeItems>(value.item)(items).image} alt=""/>
                                        <div>
                                            <p>
                                                <FormattedMessage id="options.option.volume" defaultMessage="Volume"/>
                                            </p>
                                            {(value.singleBuyerBool === true) ?
                                                <p>
                                                    <FormattedNumber value={value.volumeTotal} style="decimal" maximumFractionDigits={2}/> {/* eslint-disable-line react/style-prop-object */}
                                                    <DisplayString item={value.volumeUnits} locale={context.locale}/>
                                                </p> :
                                                <p className="volValue">
                                                    <FormattedNumber value={value.minOfferVolume as number} style="decimal" maximumFractionDigits={2}/> {/* eslint-disable-line react/style-prop-object */}
                                                    <FormattedMessage id="home.to" defaultMessage="to"/> {' '}
                                                    <FormattedNumber value={value.volumeTotal} style="decimal" maximumFractionDigits={2}/> {/* eslint-disable-line react/style-prop-object */}
                                                    <DisplayString item={value.volumeUnits} locale={context.locale}/>
                                                </p>
                                            }

                                        </div>
                                        <div>
                                            <p>
                                                <FormattedMessage id="options.option.price" defaultMessage="Price"/>
                                            </p>
                                            <p><FormattedNumber value={value.priceTotal} minimumFractionDigits={0} maximumFractionDigits={0}/> COP</p>
                                        </div>
                                    </div>

                                    <Divider/>
                                </div>
                                </NavLink>
                                )}
                            </div>
                        }
                    </div>
                        
                    :
                    <div>
                        <p>Loading...</p>
                    </div>
                }
            </DialogContent>
        </Dialog>
        
    );
}

const Circle = ({ lat, lng }: { lat: number, lng: number }) => {
    return (
        <div className="circle"/>
    );
}

const OrgIcon = ({orgType} : {orgType: string}) => {
    switch (orgType) {
        case "orgAssoc":
            return <PeopleAltIcon/>
        case "orgDistributor":
            return <LocalShippingIcon/>
        case "orgShop":
            return <StoreIcon/>
        case "orgRestaurant":
            return <RestaurantIcon/>
        case "orgSupermarket":
            return <ShoppingCartIcon/>
        case "orgCafe":
            return <LocalCafeIcon/>
        case "orgFruteria":
            return <StorefrontIcon/>
        case "orgOther":
            return <PeopleIcon/>
    }

    return null
}