import React, { Component } from "react";
import ReactPlayer from 'react-player/youtube';
 
class Answer1 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-líderes-de-asociaciones-cómo-crear-el-perfil-de-la-asociación">Para líderes de asociaciones, ¿cómo crear el perfil de la asociación?</h1>
            
            <h2 id="antes-de-crear-un-perfil-de-asociación">Antes de crear un perfil de asociación</h2>
            <p>Para crear un perfil de asociación, un líder o administrador debe <strong>realizar un registro personal</strong> en Listo, es decir, crear un perfil propio.</p>

            <p>Durante este proceso podrá <strong>seleccionar como función Administrador de asociación</strong>, una vez haya finalizado el registro personal, puede crear el perfil de la asociación.</p>

            <br/>

            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=UZ26mXkFa30&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>
            <h2 id="crear-un-perfil-de-asociación">Crear un perfil de asociación</h2>

            <ol>
              <li>
                En el menú ubicado en la parte inferior de la pantalla, se encuentra la pestaña Asociaciones.
              </li>
              <li>
                Dentro de esta hay un botón llamado <strong>Crear asociación</strong>, haga clic en él y empezará a crear el perfil de la asociación.
              </li>
              <li>
                En el primer recuadro <strong>escriba el nombre de la asociación</strong>.
              </li>
              <li>
                En el segundo <strong>ingrese el número de contacto principal</strong> de la asociación, es decir, <strong>el número del administrador</strong> o de alguien más que sea <strong>el contacto principal</strong>.
              </li>
              <li>
                Debajo de este recuadro está el botón para <strong>seleccionar la ubicación</strong> de <strong>la oficina</strong>, <strong>bodega</strong> o <strong>punto de acopio de la asociación</strong>. <strong>Oprima este botón</strong>.
              </li>
              <li>
                Verá un mapa de la ubicación en la que se encuentra, debe <strong>desplazarse</strong> por él, hasta <strong>encontrar la ubicación que corresponda a la sede de la asociación</strong> y oprimir el botón con una flecha que apunta a la derecha.
              </li>
              <li>Una vez hecho esto, regresará a <strong>la ventana Crear asociación</strong>, ahora esta indica que <strong>la ubicación de la asociación es pública</strong>, es decir, todos los usuarios registrados en Listo podrán encontrar la ubicación de la asociación dentro de la plataforma.
                <blockquote>
                  <p>Si el administrador no quiere que la ubicación sea pública, puede marcar la casilla ubicada en el recuadro <strong>Hacer la ubicación anónima</strong>, para que la ubicación de la asociación sea <strong>privada</strong>.</p>
                </blockquote>
              </li>
              <li>
                <strong>Oprima el botón Siguiente</strong>, esto llevará a una nueva pregunta <strong>¿Qué artículos compra la asociación?</strong>.
              </li>
              <li>
                <strong>Oprima el botón Seleccionar artículos</strong> y esto le mostrará la lista de los productos que se pueden comercializar a través de Listo.
              </li>
              <li><strong>Seleccione los productos</strong> que la asociación compra simplemente <strong>haciendo clic sobre ellos</strong>, cuando haya hecho esto oprima <strong>el botón ubicado en la parte inferior derecha</strong> de la pantalla.
                <blockquote>
                  <p>Si la asociación compra un producto que no se encuentra en la lista, regrese a la página anterior y debajo del botón <strong>Seleccionar artículos</strong>, habrá un recuadro donde podrá <strong>enviar una sugerencia para agregar ese producto</strong>. Ahora <strong>oprima el botón Siguiente</strong>.</p>
                </blockquote>
              </li>
              <li>
                <strong>¿Qué artículos vende su asociación?</strong><br />
            Para seleccionar los productos que vende la asociación, siga los mismos pasos anteriormente mencionados: <strong>oprima el botón Seleccionar artículos</strong>, <strong>seleccione los productos</strong> que correspondan haciendo clic sobre ellos.
              </li>
              <li>Oprima <strong>el botón ubicado en la parte inferior derecha</strong> de la pantalla.
                <blockquote>
                  <p>Nuevamente, si la asociación vende un producto que no se encuentra en la lista, puede <strong>enviar una sugerencia para agregar ese producto</strong> en el recuadro que se encuentra debajo del botón <strong>Seleccionar artículos</strong>.</p>
                </blockquote>
              </li>
              <li>
                Oprima <strong>el botón Ver resumen</strong>. Esto mostrará toda la información anteriormente ingresada, así usted podrá <strong>verificar que todo esté bien</strong> antes de crear el perfil de la asociación.
              </li>
              <li>Si todo está bien, <strong>oprima el botón Enviar</strong> y en el cuadro de texto que aparecerá en pantalla <strong>oprima Confirmar</strong>.
                <blockquote>
                  <p>Si hay algo que desee cambiar, <strong>oprima la flecha</strong> que apunta a la izquierda (ubicada en la esquina superior izquierda de la pantalla) para regresar y realizar los cambios pertinentes.</p>
                </blockquote>
              </li>
              <li>
                Ahora, <strong>agregue el logo</strong> de la asociación, puede <strong>tomar una foto</strong> o <strong>seleccionarla desde la galería</strong>.
              </li>
              <li>Una vez se haya incluido la foto del perfil de la asociación, <strong>oprima el botón Siguiente</strong> y <strong>¡listo!</strong></li>
            </ol>

            <p><strong>El perfil de la asociación ha sido creado.</strong></p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer1;