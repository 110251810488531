import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from "react-intl";

import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
      fontFamily: '"Helvetica Neue",sans-serif',
      fontWeight: 400,
      textTransform: 'none',
      lineHeight: 1,
      letterSpacing: 'normal',
      fontSize: '20px',
      color: '#666',
      padding: '0px',
      background: '#fff',
      '&:hover': {
        background: '#fff',
        color: '#000',
      },
      marginTop: '0px',
      marginBottom: '3px',
  },
  options: {
      fontSize: '15px',
      padding: '6px 8px',
      width: '170px',
      color: '#666',
  }
}));

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.root}>
        <FormattedMessage id="nav.how_to_use" defaultMessage="How to use Listo?"/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={NavLink} to="/registro" className={classes.options}>Registro</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/agricultor" className={classes.options}>Soy agricultor</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/comprador" className={classes.options}>Soy comprador</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/aso" className={classes.options}>Asociación</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/audios" className={classes.options}>Audios</MenuItem>
      </Menu>
    </div>
  );
}