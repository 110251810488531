import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface Props {
    photoUrlArray: string[]
}

function ImgCarousel(props:Props): React.ReactElement {
    return (
        <Carousel>
            {props.photoUrlArray.map( (photoUrl: string, i: number) => <img key={i} src={photoUrl} alt=""/> )}
        </Carousel>
    );
}

export default ImgCarousel;