import React/* , { useEffect } */  from 'react';

//import functions from "./../firebase";

import {
    //CF,
    //BuySearchParamsInterface,
    //BuySearchSortOptions,
    //PostDataMinimalInterface,
    //Position
} from "../interfaces";


/* interface Result {
    errorBool: boolean,
    errorKey: null | string
    response: {
        radiusKeySearch: number | null,
        postDataArray: PostDataMinimalInterface[]
    }
} */

/* interface _Result<T> {
    errorBool: boolean,
    errorKey: null | string
    response: T
} */


//var CF_WEB_B_geoQuery_v1_onCall = functions.httpsCallable('CF_WEB_B_geoQuery_v1_onCall');
//var CF_WEB_B_geoQuery_v1_onCall = functions.httpsCallable('CF_WEB_B_geoQuery_v1_onCall');
/* const func: () => number = () => {
    if (false) {
        return 0
    } else {
        return func();
    }
} */

/* const func2: <T>(x: T, y: number) => number = (x, y) => {

    if (y < 5) {
        return func2(x, y + 1);
    }
    return y
} */

//const x = func2<number>(2, 1);

/* interface IProps {
    functionName: string
    args: any
    retryNum?: number
} */

/* const func3 = () => {
    return new Error()
} */

//const callFDCloudFunction: any = async (functionName: string, args: any, retryNum: number = 0) => {
//const callFDCloudFunction: (functionName: string, args: any, retryNum?: number) => Promise<CF<Result> | null | undefined> = async (functionName: string, args: any, retryNum: number = 0) => {
//const callFDCloudFunction: (functionName: string, args: any, retryNum?: number) => Promise<CF<Result> | null | undefined> = async (functionName, args, retryNum = 0) => {
//const callFDCloudFunction: <T>(functionName: string, args: T, retryNum?: number) => Promise<CF<Result> | null | undefined> = async (functionName, args, retryNum = 0) => {
/* const callFDCloudFunction: <T>(functionName: string, args: T, retryNum?: number) => Promise<CF<Result>> = async (functionName, args, retryNum = 0) => {

    const callable = functions.httpsCallable(functionName);

    try {
        const result: CF<Result> = await callable(args);

        //throw new Error()

        return result;
    } catch (error) {
        if ((error.code === 'internal' || error.code === 'unknown') && retryNum < 5) {
        //if (error && retryNum < 5) {
            retryNum++;
            console.log(retryNum);
            console.log(error);
            //setTimeout(() => {}, 500);
            //return setTimeout(callFDCloudFunction(functionName, args, retryNum), 500);
            //return callFDCloudFunction(functionName, args, retryNum);

            
            ////const timeout = setTimeout(() => {
                ////return callFDCloudFunction(functionName, args, retryNum);
            ////}, 500);
           

            setTimeout(() => {
                return callFDCloudFunction(functionName, args, retryNum);
            }, 5000);

            //timeout;
            return callFDCloudFunction(functionName, args, retryNum);
            //return null

        } else {
            console.log("error1");
            console.log(error);
            //return error
            //throw new Error();
            //throw error
            throw new Error(error);
            //return new Error(error);
            //return 2
        }
    }
} */


export default function Test() {

    //const [aye, setAye] = useState(0);

    //let i: number = 0;

    /* const asyncFunc = async () => {

        //let i: number = 0;

        console.log("asyncFunc");

        try {
            let BuySearchParamsInterface: BuySearchParamsInterface = {
                sortOption: BuySearchSortOptions.sortByDistanceLowHigh,
                lat: 4.710463,
                lng: -74.072236,
                radius: null,
                lastDocId: null,
                itemOrCatKey: null,
                variety: null,
                processed: null,
                deliveryBool: true,
                priceMin: null,
                priceMax: null,
                volUnits: null,
                volMin: null,
                volMax: null
            }

            //let error = new Error()

            //const result: CF<Result> = await CF_WEB_B_geoQuery_v1_onCall(BuySearchParamsInterface);
            //const result: CF<Result> | any = await callFDCloudFunction("CF_WEB_B_geoQuery_v1_onCall", BuySearchParamsInterface);
            //const result: CF<Result> | null | undefined = await callFDCloudFunction("CF_WEB_B_geoQuery_v1_onCall", BuySearchParamsInterface);
            //const result: CF<Result> | null | undefined = await callFDCloudFunction<BuySearchParamsInterface>("CF_WEB_B_geoQuery_v1_onCall", BuySearchParamsInterface);
            const result: CF<Result> | Error | undefined = await callFDCloudFunction<BuySearchParamsInterface>("CF_WEB_B_geoQuery_v1_onCall", BuySearchParamsInterface);

            console.log("result");
            console.log(result);

            //throw new Error();
            return;
        } catch (error) {
            ////console.log("error");
            //if (i < 5) {
            ////if ((error.code === 'internal' || error.code === 'unknown') && i < 5) {
                ////i++;
                ////console.log(i);
                ////console.log("error");
                ////console.log(error);
                ////console.log(error.code);
                ////asyncFunc();
            ////} else {
                ////console.log("maxReached")
                ////return;
            ////}
            //console.log("errorHere");
            //console.log(error);
            
        }
    } */

    /* const func1: (x: number) => Promise<null> = async (x: number) => {

        setTimeout(() => {
            console.log("start", x)
        }, 6000);

        const ff = setTimeout(() => {
            console.log("ff")
            return null // doesn't reach this code
        }, 6000);

        if (x < 5) {
            setTimeout(() => {
                console.log("setTimeout", x)
            }, 5000);
            return func1(5);
            //return null
        } else {
            console.log("end");
            return null
        }
    } */

    /* const func2: () => NodeJS.Timeout = () => {

        setTimeout(() => {
            console.log("func2_0")
        }, 7000);

        return setTimeout(() => {
            console.log("func2_rtn")
        }, 5000);
    } */

    /* const func3: (x: number) => NodeJS.Timeout = (x) => {

        setTimeout(() => {
            console.log("func3_0")
        }, 7000);

        if (x < 5) {
            return setTimeout(() => {
                console.log("func3", x)
            }, 5000)
        } else {
            return func3(x++);
        }

        ////return setTimeout(() => {
            ////console.log("func2_rtn")
        ////}, 5000);
    } */

    /* useEffect(() => {
        console.log("useEffect");

        //asyncFunc();
        //func1(0);
        //func2();
        func3(0);

    }, []); */

    return (
        <div>
            <p>{"aye"}</p>
        </div>
    );
}
