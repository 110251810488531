import React, { Component, useState } from "react";
import SimpleExpansionPanel from "../components/accordian";
import ReactGA from 'react-ga';
import { NavLink } from "react-router-dom";
import ReactPlayer from 'react-player/youtube';
import { Dialog, DialogContent, makeStyles, createStyles } from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import PostsCarousel from "../components/PostsCarousel";
import OrdersCarousel from "../components/OrdersCarousel";

import yourscreenshot from "../images/screenshots/iPhone11_soylistoapp.png";
import appicon from "../images/appicon.png";
import playstore from "../images/playstore.png";
import appstore from "../images/appstore.png";
import LOGO_MADR from "../images/LOGO_MADR_1024.jpg";
import embajada from "../images/embajada.jpg";
import prosperity_fund from "../images/logo_prosperity_fund.jpg";
import PotatoesSQ from "../images/2KPotatoes.jpg";
 
class Home extends Component {
  render() {
    return (
        <div className="body">

        {ReactGA.pageview(window.location.pathname)}

        <Thousand/>

        <div className="iphonePreview">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 0 0" style={{position: "absolute"}}>
                <clipPath id="screenMask" clipPathUnits="objectBoundingBox" transform="scale(0.00257, 0.00119)">
                    <path d="M6490.24,1234.36H6216.28c-2.57,0-10.55-.07-12.07-0.07a87.524,87.524,0,0,1-12-1.03,40.051,40.051,0,0,1-11.4-3.79,38.315,38.315,0,0,1-16.82-16.84,39.948,39.948,0,0,1-3.78-11.42,72.257,72.257,0,0,1-1.04-12.02c-0.06-1.83-.06-5.56-0.06-5.56V452.125h0s0.06-11.391.06-12.086a87.9,87.9,0,0,1,1.04-12.025,39.843,39.843,0,0,1,3.78-11.413,38.283,38.283,0,0,1,16.82-16.847,39.762,39.762,0,0,1,11.4-3.785,71.909,71.909,0,0,1,12-1.037c16.99-.567,36.32-0.061,34.51-0.061,5.02,0,6.5,3.439,6.63,6.962a35.611,35.611,0,0,0,1.2,8.156,21.326,21.326,0,0,0,19.18,15.592c2.28,0.192,6.78.355,6.78,0.355H6433.7s4.5-.059,6.79-0.251a21.348,21.348,0,0,0,19.18-15.591,35.582,35.582,0,0,0,1.19-8.154c0.13-3.523,1.61-6.962,6.64-6.962-1.81,0,17.52-.5,34.5.061a71.923,71.923,0,0,1,12.01,1.038,39.832,39.832,0,0,1,11.4,3.784,38.283,38.283,0,0,1,16.82,16.844,40.153,40.153,0,0,1,3.78,11.413,87.844,87.844,0,0,1,1.03,12.023c0,0.695.06,12.084,0.06,12.084h0V1183.64s0,3.72-.06,5.55a72.366,72.366,0,0,1-1.03,12.03,40.2,40.2,0,0,1-3.78,11.41,38.315,38.315,0,0,1-16.82,16.84,40.155,40.155,0,0,1-11.4,3.79,87.669,87.669,0,0,1-12.01,1.03c-1.52,0-9.5.07-12.07,0.07" transform="translate(-6159.12 -394.656)"></path>
                </clipPath>
            </svg>
            <img className="iphoneScreen" src={yourscreenshot} alt=""/>
        </div>

        <div className="appInfo">
            <div className="appIconShadow">
                <svg width="0" height="0">
                    <defs>
                        <clipPath id="shape120">
                            <path id="shape" className="cls-1" d="M6821,495.533v-4.281c0-1.2-.04-2.4-0.04-3.642a57.7,57.7,0,0,0-.68-7.882,26.144,26.144,0,0,0-2.48-7.483,25.115,25.115,0,0,0-11.04-11.044,26.118,26.118,0,0,0-7.49-2.481,47.28,47.28,0,0,0-7.88-.68c-1.2-.04-2.4-0.04-3.64-0.04h-53.5c-1.2,0-2.4.04-3.64,0.04a57.813,57.813,0,0,0-7.88.68,26.323,26.323,0,0,0-7.49,2.481,25.115,25.115,0,0,0-11.04,11.044,26.144,26.144,0,0,0-2.48,7.483,47.313,47.313,0,0,0-.68,7.882c-0.04,1.2-.04,2.4-0.04,3.642v53.5c0,1.2.04,2.4,0.04,3.641a57.7,57.7,0,0,0,.68,7.883,26.137,26.137,0,0,0,2.48,7.482,25.115,25.115,0,0,0,11.04,11.044,26.261,26.261,0,0,0,7.49,2.481,47.28,47.28,0,0,0,7.88.68c1.2,0.04,2.4.04,3.64,0.04h53.5c1.2,0,2.4-.04,3.64-0.04a57.654,57.654,0,0,0,7.88-.68,26.057,26.057,0,0,0,7.49-2.481,25.115,25.115,0,0,0,11.04-11.044,26.137,26.137,0,0,0,2.48-7.482,47.316,47.316,0,0,0,.68-7.883c0.04-1.2.04-2.4,0.04-3.641V495.533h0Z" transform="translate(-6701 -458)" filter="url(#f1)"></path>
                        </clipPath>
                    </defs>
                </svg>
                <img className="appIconLarge" src={appicon} alt=""/>
            </div>
            <div className="appNamePriceContainer">
                <h1 className="appName">Listo</h1>
                <h2 className="appPrice">Gratis</h2>
            </div>
            <div className="appDescriptionContainer">
                <p className="appDescription">Fácil intercambio de productos entre agricultores y compradores</p>
            </div>
            <div className="downloadButtonsSuperContainer">
            <div className="downloadButtonsContainer">
                <a className="playStoreLink" href="https://play.google.com/store/apps/details?id=com.farming_data.farming_data_flutter&amp;hl=es_419" rel="noopener noreferrer" target="_blank"><img className="playStore" src={playstore} alt=""/></a>
                <a className="appStoreLink" href="https://apps.apple.com/co/app/listo-mercado-agr%C3%ADcola/id1509312528?ls=1" rel="noopener noreferrer" target="_blank"><img className="appStore" src={appstore} alt=""/></a>
            </div>

            <div className="downloadInstructions">
                <NavLink to="/listo_instrucciones_21_01.pdf" target="_blank">
                    <button>
                        <CloudDownloadIcon/>
                        <p>Descarga instructivo aquí</p>
                    </button>
                </NavLink>
            </div>
            </div>
        </div>

        <PostsCarousel/>
        <OrdersCarousel/>

        <div className="sponsors">  
            <div className="sponsorsContent">
                <img src={LOGO_MADR} alt="MADR" style={{margin: "0px 20px"}}/>
                <img src={embajada} alt="MADR" style={{margin: "0px 20px"}}/>
                <img src={prosperity_fund} alt="MADR" style={{margin: "0px 20px"}}/>
            </div>
        </div>

        <div className="features">
            <div className="feature">
                <div>
                    <span className="fa-stack fa-1x">
                        <i className="iconBack fas fa-circle fa-stack-2x"></i>
                        <i className="iconTop fas fa-exchange-alt fa-stack-1x"></i>
                    </span>
                </div>
                <div className="featureText">
                    <h3>Comprar y vender directamente</h3>
                    <p>Diseñada pensando en los pequeños productores y compradores, nuestra plataforma introduce mayor transparencia y conectividad en los mercados agrícolas rurales.</p>
                </div>
            </div>
            <div className="feature">
                <div>
                    <span className="fa-stack fa-1x">
                        <i className="iconBack fas fa-circle fa-stack-2x"></i>
                        <i className="iconTop fas fa-map-marker-alt fa-stack-1x"></i>
                    </span>
                </div>
                <div className="featureText">
                    <h3>Contenido localmente relevante creado solo para ti</h3>
                    <p>Basándonos en un diseño dirigido exclusivamente por el usuario, tenemos en cuenta factores clave de accesibilidad, como la alfabetización y los dialectos locales.</p>
                </div>
            </div>
            <div className="feature">
                <div>
                    <span className="fa-stack fa-1x">
                        <i className="iconBack fas fa-circle fa-stack-2x"></i>
                        <i className="iconTop fas fa-mobile-alt fa-stack-1x"></i>
                    </span>
                </div>
                <div className="featureText">
                    <h3>Conéctate usando cualquier tipo de teléfono móvil</h3>
                    <p>Tanto si utilizas Android, iOS o un teléfono móvil básico, nuestra plataforma es accesible para ti. Simplemente con un menú de voz o mensaje de texto, ayudamos a facilitar la compra y venta de productos.</p>
                </div>
            </div>
        </div>

        <div className="testimonials">
            <ReactPlayer url='https://www.youtube.com/watch?v=532dWmuNlto&feature=youtu.be&ab_channel=ListoporFarmingData' width='320px' height='320px' controls={true}/>
            <ReactPlayer url='https://www.youtube.com/watch?v=U7_qoUNa7mo&feature=youtu.be&ab_channel=ListoporFarmingData' width='320px' height='320px' controls={true}/>
            <ReactPlayer url='https://www.youtube.com/watch?v=Yoe2r_8nkGU&feature=youtu.be&ab_channel=ListoporFarmingData' width='320px' height='320px' controls={true}/>
        </div>

        <div className="featuresVideo">
            <div className="video-container">
                <iframe id="ytplayer" title="unique" width="100%" height="100%" src="https://www.youtube.com/embed?listType=playlist&amp;list=PLkIpW7YoNIH3Onwl9k3V6cNhiW0p8jV6-" frameBorder="0" allowFullScreen={true} className="video"></iframe>
            </div>
        </div>

        <div className="featuresFAQ">
            <div id="faq">
                <h1 className="faq">Preguntas frecuentes</h1>
                <SimpleExpansionPanel/>
            </div>
        </div>

        </div>
    );
  }
}
 
export default Home;


const useStyles = makeStyles(() => 
    createStyles({
        root: {
            "& .MuiPopover-root": {
                img: {
                    width: "100%",
                    height: "100%"
                }
            },
            "& .MuiDialog-paper": {
                margin: "16px"
            },
            "& .MuiDialog-paperWidthSm": {
                maxWidth: "800px"
            },
        }
    })
);

function Thousand () {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="oneThousand" onClick={handleClickOpen}>
                <p>¡Llegamos a los 2.000 usuarios! Gracias a todos ustedes.</p>
            </div>
            <Dialog className={classes.root}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialogContent_2K" onClick={handleClose}>
                    <img src={PotatoesSQ} alt="" width='100%'/>
                </DialogContent>
            </Dialog>
        </>
    );
}