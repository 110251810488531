import React, { useContext } from "react";
import { Map, Circle/* , GoogleApiWrapper, IMapProps */ } from 'google-maps-react';

import { GoogleContext } from "./GoogleWrapper";

/* interface IMapPropsLatLng extends IMapProps {
    lat: number;
    lng: number
} */

interface IMapPropsLatLng {
  lat: number;
  lng: number
}

const containerStyle = {
  position: "absolute",
  width: '100%',
  height: '100%'
}

const MapContainer: React.FC<IMapPropsLatLng> = (props) => {

  const context = useContext(GoogleContext);

    return (
            <Map
                google={context.google}
                zoom={10}
                containerStyle={containerStyle}
                streetViewControl={false}
                mapTypeControl={false}
                fullscreenControl={false}
                initialCenter={{
                    lat: props.lat,
                    lng: props.lng
                  }}
                //style={style}
                //draggable={true}
            >
              <Circle
                radius={1200}
                center={{lat: props.lat, lng: props.lng}}
                strokeColor='blue'
                strokeOpacity={0.2}
                strokeWeight={2}
                fillColor='blue'
                fillOpacity={0.2}
              />
            </Map>
    );
}
 
export default MapContainer;

/* export default GoogleApiWrapper({
  apiKey: ("AIzaSyDkHqSJAFT7ydQ9w0lHsOUsGTnX-xVpYCw")
})(MapContainer) */