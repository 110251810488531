import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer3 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-compradores-cómo-editar-antes-del-intercambio">Para compradores, ¿cómo editar antes del intercambio?</h1>
            
            <p>En Listo es posible editar la información de una publicación antes de realizar el intercambio.</p>
            
            <blockquote>
              <p>Por ejemplo, en caso de que <strong>la fecha de intercambio se adelante</strong> o <strong>se posponga</strong>, puede <strong>comunicarse con el agricultor o el administrador de la asociación</strong> e informarle de este cambio, esto con el objetivo de llevar el registro de todos los eventos relacionados con esa transacción.</p>
            </blockquote>

            <br/>
            <ul>
              <li><Link to="#cómo-hacer-una-edición">Hacer una edición</Link></li>
              <li><Link to="#cómo-aceptar-o-rechazar-una-edición">Aceptar o rechazar una edición</Link></li>
            </ul>

            <br/>
            <br/>

            <h2 id="cómo-hacer-una-edición">Cómo hacer una edición</h2>
            <blockquote>
              <p>Una vez su oferta ha sido aceptada o después de que usted haya aceptado una solicitud de contribución a su pedido, puede hacer cambios en la misma.</p>
            </blockquote>

            <ol>
              <li>
                Acceda a <strong>la oferta</strong> o <strong>la solicitud</strong>, en la ventana de la misma diríjase a <strong>la parte inferior de la pantalla</strong>, cuando alcance el final haga clic en <strong>el botón Editar</strong>.
              </li>
              <li>Esto lo llevará a la ventana <strong>Editar oferta</strong>, aquí verá <strong>información relacionada</strong> con la oferta o la solicitud.
                <blockquote>
                  <p>Cabe resaltar que antes de realizar cualquier cambio a una oferta o solicitud, le recomendamos que se comunique con el otro usuario para informarle previamente de los cambios que quiere realizar.</p>
                </blockquote>
              </li>
              <li>
                En esta ventana, usted puede <strong>cambiar el volumen</strong>, <strong>el precio unitario</strong> o <strong>precio total</strong>, <strong>la fecha de intercambio</strong>, <strong>el método de entrega</strong> y <strong>la tarifa de entrega</strong>.
              </li>
              <li>
                <strong>Realice los cambios</strong> que desee y <strong>oprima el botón Siguiente</strong>.
              </li>
              <li>
                Esto lo llevará al <strong>resumen de la edición</strong>, verá <strong>una tabla</strong> donde aparece la oferta previa, si <strong>mueve esta tabla hacia la izquierda</strong> verá la nueva oferta.
              </li>
              <li><strong>Verifique que todo esté bien</strong> y oprima <strong>el botón Enviar</strong> y seguidamente el botón <strong>Confirmar</strong>.</li>
            </ol>

            <br/>

            <p>Ahora simplemente se debe esperar a que el otro usuario acepte la nueva edición.</p>

            <br/>
            <br/>

            <h2 id="cómo-aceptar-o-rechazar-una-edición">Cómo aceptar o rechazar una edición</h2>
            <ol>
              <li>
                Diríjase a <strong>la campana de notificaciones</strong> ubicada en <strong>la página de inicio</strong>.
              </li>
              <li>
                Allí <strong>verá la notificación</strong> que le ha sido enviada, <strong>haga clic</strong> sobre ella.
              </li>
              <li>
                Abrirá <strong>la ventana de edición</strong> y podrá <strong>ver los cambios</strong> que la otra parte ha sugerido.
              </li>
              <li>
                Al final de la pantalla encontrará <strong>los botones Aceptar</strong> y <strong>Rechazar</strong>.
              </li>
              <li>
                <strong>Haga clic sobre el botón</strong> que corresponda a la acción que desea realizar.
              </li>
            </ol>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer3;