import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { FormattedMessage } from "react-intl";
import clsx from 'clsx';


interface Props {
	variety: string | null
	processed: string | null
	
	earliestExchangeDate: string
	postEndDate: string

	dateString: {
		start: string
		end: string
	}

	delivery: boolean
	collection: boolean

	deliveryString: {
		delivery: string
		collection: string
	}

	cash: boolean
	digital: boolean
}


const useStyles = makeStyles({
	table: {
	},
	rows: {
		fontFamily: '"Helvetica Neue",sans-serif',
		fontSize: "20px",
		borderBottom: "none",
		paddingLeft: "0px",
		paddingTop: "4px",
		paddingBottom: "4px",
		'@media only screen and (max-width: 768px)': {
		fontSize: "15px"
		}
	},
	rowValues: {
		fontWeight: 500,
	},
});


export default function SimpleTable(props: Props) {
	const classes = useStyles();

	return (
		<TableContainer>
		<Table className={classes.table} size="small" aria-label="a dense table">
			<TableBody>
			
			{props.variety !== null &&
				<TableRow key="variety">
				<TableCell component="th" scope="row" className={classes.rows}>
					<FormattedMessage id="variety" defaultMessage="Variety"/>
				</TableCell>
				<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
					{props.variety}
				</TableCell>
				</TableRow>
			}

			{props.processed !== null &&
				<TableRow key="processed">
				<TableCell component="th" scope="row" className={classes.rows}>
					<FormattedMessage id="processed" defaultMessage="Processed"/>
				</TableCell>
				<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
					{props.processed}
				</TableCell>
				</TableRow>
			}

			<TableRow key="exchangeStartDate">
				<TableCell component="th" scope="row" className={classes.rows}>
				<FormattedMessage id={props.dateString.start} defaultMessage="Earliest exchange date"/>
				</TableCell>
				<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
				{props.earliestExchangeDate}
				</TableCell>
			</TableRow>

			<TableRow key="exchangeEndDate">
				<TableCell component="th" scope="row" className={classes.rows}>
				<FormattedMessage id={props.dateString.end} defaultMessage="Latest exchange date"/>
				</TableCell>
				<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
				{props.postEndDate}
				</TableCell>
			</TableRow>

			<TableRow key="delivery">
				<TableCell component="th" scope="row" className={classes.rows}>
				<FormattedMessage id="delivery_method" defaultMessage="Delivery method"/>
				</TableCell>
				<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
				{(props.delivery === true && props.collection === true) && 
					<>
					<FormattedMessage id="delivery_and_collection" defaultMessage="Delivery & Collection"/>
					</>
				}
				{(props.delivery === true && props.collection === false) && 
					<>
					<FormattedMessage id={props.deliveryString.delivery} defaultMessage="Delivery"/>
					</>
				}
				{(props.delivery === false && props.collection === true) && 
					<>
					<FormattedMessage id={props.deliveryString.collection} defaultMessage="Collection"/>
					</>
				}
				</TableCell>
			</TableRow>

			<TableRow key="payment">
				<TableCell component="th" scope="row" className={classes.rows}>
				<FormattedMessage id="payment_method" defaultMessage="Payment method"/>
				</TableCell>
				<TableCell align="right" className={clsx(classes.rows, classes.rowValues)}>
				{(props.cash === true && props.digital === true) && 
					<>
					<FormattedMessage id="cash_and_digital" defaultMessage="Cash & Digital"/>
					</>
				}
				{(props.cash === true && props.digital === false) && 
					<>
					<FormattedMessage id="cash" defaultMessage="Cash"/>
					</>
				}
				{(props.cash === false && props.digital === true) && 
					<>
					<FormattedMessage id="digital" defaultMessage="Digital"/>
					</>
				}
				</TableCell>
			</TableRow>
			
			</TableBody>
		</Table>
		</TableContainer>
	);
}
