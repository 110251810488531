import React, { Component } from "react";
import ReactPlayer from 'react-player/youtube';
 
class Answer3 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-líderes-de-asociaciones-cómo-enviar-difusiones-a-sus-miembros">Para líderes de asociaciones, ¿cómo enviar difusiones a sus miembros?</h1>
            
            <p>Listo ofrece varias herramientas a las asociaciones, una de ellas es <strong>un canal de comunicación unidireccional</strong> entre asociación y asociado, llamado <strong>Difusiones</strong>.</p>

            <br/>

            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=pRPbsfmjGrI&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>
            <h2 id="sobre-las-difusiones">Sobre las Difusiones</h2>

            <p>Las Difusiones son mensajes cortos que tienen como objetivo hacer llegar cierta información a los miembros de una asociación. Esta información <strong>llegará como un mensaje de texto</strong> a los asociados, este servicio <strong>no genera ningún tipo de costo</strong> sobre <strong>la recepción de mensajes de texto</strong>.</p>

            <p>Asimismo, los asociados <strong>no necesitan tener conexión a Internet para recibirlos</strong>, simplemente requieren tener señal en sus celulares.</p>

            <blockquote>
              <p>Por ejemplo, si la asociación quiere <strong>informar el precio de compra</strong> de un producto, lo puede hacer a través de una difusión; o si desea <strong>convocar a una reunión</strong> también puede hacerse a través de este medio.</p>
            </blockquote>

            <br/>
            <h2 id="enviar-las-difusiones">Enviar las Difusiones</h2>

            <ol>
              <li>
                En la aplicación, diríjase al <strong>perfil de la asociación</strong>, ubicado en <strong>la pestaña Asociaciones</strong>.
              </li>
              <li>
                En la página de inicio de la asociación, encontrará <strong>el botón Difusiones</strong>, al <strong>oprimirlo</strong> verá la ventana de difusiones y desde allí podrá enviar información.
              </li>
              <li>
                <strong>Escriba en el recuadro Mensaje</strong> lo que desee comunicar, este mensaje <strong>no puede ser mayor a 160 caracteres</strong>.
              </li>
              <li>
                Al <strong>oprimir el botón Enviar</strong>, la difusión será dirigida a todos los asociados que ya sean miembros de la asociación en Listo.
              </li>
            </ol>

            <br/>
            <h2 id="ver-difusiones-anteriores">Ver Difusiones anteriores</h2>

            <p>Listo también permite llevar <strong>el registro de las difusiones enviadas</strong>, en la venta Difusiones hay <strong>una sección llamada Difusiones anteriores</strong>, allí encontrará todas las difusiones enviadas previamente.</p>

            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer3;