import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import Main from "./pages/Main";
import Redirect from "./pages/Redirect";
import pdfDownload from "./pages/pdfDownload";

//ReactGA.initialize('UA-167408819-1');

class Ground extends Component {

    render() {
        return (
        <Router>
            <Switch>
                {/*<Route exact path="/" component={Main}/>*/}
                <Route path="/redirect" component={Redirect} id="redirect"/>
                <Route path="/listo_instrucciones_21_01.pdf" component={pdfDownload} id="listo_instrucciones_21_01.pdf"/>
                <Route path="/" component={Main}/>
            </Switch>
        </Router>
        );
    }
}

export default Ground;