import FirebaseFirestore from '@firebase/firestore-types';

/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ 


export interface CloudResultPost {
    data: ResultPost;
}

export interface CloudResultOrder {
    data: ResultOrder;
}

export interface CloudResultPostCarousel {
    data: ResultPostCarousel
}

export interface CloudResultOrderCarousel {
    data: ResultOrderCarousel
}

/*Cloud Function*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface CF<T> {
    data: T
}

export interface Result<T> {
    errorBool: boolean;
    errorKey?: any;
    response: T;
}

/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ 


export interface ResultPost {
    errorBool: boolean;
    errorKey?: any;
    response: PostModelPublicWebInterface;
}

export interface ResultOrder {
    errorBool: boolean;
    errorKey?: any;
    response: OrderModelPublicWebInterface;
}

export interface ResultPostCarousel {
    errorBool: boolean
    errorKey: null | string
    response: PostCarouselModelPublicWebInterface[]
}

export interface ResultOrderCarousel {
    errorBool: boolean
    errorKey: null | string
    response: OrderCarouselModelPublicWebInterface[]
}


/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ 


export interface PostPublic {
    postId: string | null
    language: string
}

export interface OrderPublic {
    orderId: string | null
    language: string
}


/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/


export interface PostModelPublicWebInterface {
    id: string
    state: string
    item: string
    variety: string | null
    processed: string | null
    labels: string[] | null
    volumeUnits: string
    volumeTotal: number
    priceTotal: number
    priceCurrency: string
    coordinatesOffset: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    radiusOffsetMetres: number
    delivery: boolean
    collection: boolean
    paymentCashBool: boolean
    paymentDigitalBool: boolean
    description: string | null

    singleBuyerBool: boolean
    minOfferVolume: number | null
    minOfferVolumeKg: number | null

    kgPerUnit: number

    postEndDateStr: string
    earliestExchangeDateStr: string

    userData: UserModelPublic
    assocData: AssocModelPublic | null

    photoUrlArray: string[] | null
    photoUrlStock: string

    postEndDaysDelta: number
    earliestExchangeDaysDelta: number

    shortLinkWeb: string

    dispMap: DispMapInterface
}

export interface OrderModelPublicWebInterface {
    id: string,
    createdDatetime: string
    uid: string
    exchangeDateStart: string
    exchangeDateEnd: string
    orderNum: string
    coordinatesOffset: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    singleSellerBool: boolean
    item: string
    processed: string | null
    variety: string | null
    labels: string[] | null
    state: string
    targetVolumeUnits: string
    targetVolumeTotal: number
    minimumOfferVolume: null | number
    targetVolumeTotalKg: number
    minimumOfferVolumeKg: null | number
    targetPriceTotal: number
    delivery: boolean
    collection: boolean
    publicBool: boolean
    assocId: string | null

    userData: UserModelPublic
    assocData: AssocModelPublic | null

    remainingVolume: number,
    remainingVolumeKg: number,
    openBool: boolean
    paymentCashBool: boolean
    paymentDigitalBool: boolean

    photoUrlStock: string

    shortLinkWeb: string

    dispMap: DispMapInterfaceOrder
}

/*
export interface PostDataMinimalInterfaceDisp {
    distance: number | null
    photoUrlPost: null | string
    photoUrlProfile: null | string
    photoUrlAssoc: null | string
    assocBool: boolean
    id: string
    item: string
    internalVolumeKg: number
    priceTotal: number
    singleBuyerBool: boolean
    minOfferVolumeKg: number | null
    dispMap: DispMapInterface
}
*/
export interface PostCarouselModelPublicWebInterface {
    distance: number
    photoUrlPost: null | string
    photoUrlProfile: null | string
    photoUrlAssoc: null | string
    assocBool: boolean
    id: string
    item: string
    internalVolumeKg: number
    priceTotal: number

    singleBuyerBool: boolean
    minOfferVolumeKg: number | null
    dispMap: DispMapInterface
}

export interface OrderCarouselModelPublicWebInterface {
    distance: number | null
    id: string
    item: string
    targetVolumeTotalKg: number
    remainingVolumeKg: number
    targetPriceTotal: number
    photoUrlOrder: string
    photoUrlProfile: string
    photoUrlAssoc: string | null
    assocBool: boolean
    dispMap: DispMapInterface
}


/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface UserModelPublic {
    uid: string
    deleted: boolean

    firstName: null | string
    lastName: null | string
    phoneNumber: null | string
    photoUrl: null | string
    rating: null | number
    appUserBool: null | boolean

    brickMap: null | BrickMapInterface
    
    reviewCalcMap: any
}

export interface UserBioModelPublic extends UserModelPublic {
    bio: null | string
    publicPhotoArray: null | string[]
}

export interface BrickMapInterface {
    state: null | string
    stateDatetime: null | FirebaseFirestore.Timestamp
    requestNum: null | number
}

export interface AssocModelPublic {
    assocId: string
    name: string
    phoneNumber: string
    address: string
    photoUrl: string | null
    photoUrlInternal: string | null
    memberCountAccepted: number
    rating: number
    reviewCalcMap: any
    deleted: boolean
}

interface DispMapInterface {
    item: string
    variety: string | null
    processed: string | null
    volumeUnits: string
    priceCurrency: string
}

interface DispMapInterfaceOrder {
    item: string
    processed: string | null
    variety: string | null
    targetVolumeUnits: string
}

/*POST SEARCH*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

/*OUTBOUND*/

export interface BuySearchParamsInterface {
    sortOption: BuySearchSortOptions
    lat: number
    lng: number
    radius: number | null
    lastDocId: string | null
    itemOrCatKey: string | null
    variety: string | null
    processed: string | null
    deliveryBool: boolean
    priceMin: number | null
    priceMax: number | null
    volUnits: string | null
    volMin: number | null
    volMax: number | null
}

export enum BuySearchSortOptions {
    sortByDistanceLowHigh="sortByDistanceLowHigh",
    sortByPriceLowHigh="sortByPriceLowHigh",
    sortByPriceHighLow="sortByPriceHighLow",
    sortByVolumeLowHigh="sortByVolumeLowHigh",
    sortByVolumeHighLow="sortByVolumeHighLow",
}

/*INBOUND*/

export interface PostDataMinimalInterface {
    distance: number
    id: string
    item: string
    volumeUnits: string
    volumeTotal: number
    minOfferVolume: number | null
    priceTotal: number
    postPhotoUrl: string
    userFirstName: string
    userRating: number
    assocName: string | null
    assocRating: number | null
    coordinatesOffset: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint

    photoUrlProfile: string
    photoUrlAssoc: string | null
}

/*ORDER SEARCH*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

/*OUTBOUND*/

export interface OrderSearchParamsInterface {
    sortOption: BuySearchSortOptions
    lat: number
    lng: number
    radius: number | null
    lastDocId: string | null
    itemOrCatKey: string | null
    variety: string | null
    processed: string | null
    collectionBool: boolean
    volUnits: string | null
    volMin: number | null
    volMax: number | null
}

/*INBOUND*/

export interface OrderDataResultsInterface {
    orderDataArrayBatch: OrderModelPublicInterfaceDistance[]
    radiusKeySearch: number | null
}

export interface OrderModelPublicInterfaceDistance {
    distance: number | null
    pricePerKg: number

    coordinates: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    radiusOffsetMetres: number
    userOrderRelationshipKey: null | string,
    shortLinkApp: string


    id: string,
    createdDatetime: string
    uid: string
    exchangeDateStart: string
    exchangeDateEnd: string
    orderNum: string
    coordinatesOffset: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    singleSellerBool: boolean
    item: string
    processed: string | null
    variety: string | null
    labels: string[] | null
    state: string
    targetVolumeUnits: string
    targetVolumeTotal: number
    minimumOfferVolume: null | number
    targetVolumeTotalKg: number
    minimumOfferVolumeKg: null | number
    targetPriceTotal: number
    delivery: boolean
    collection: boolean
    publicBool: boolean
    assocId: string | null
    userData: UserModelPublic
    assocData: AssocModelPublic | null
    remainingVolume: number,
    remainingVolumeKg: number,
    openBool: boolean
    paymentCashBool: boolean
    paymentDigitalBool: boolean
}

/*JSONS*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface typeItems {
    [key: string]: {
        type: string
        catArray: string[]
        units: string[]
        varieties: null | string[]
        processed: null | string[]
        labels: null | string[]
        image: string
    }
}

export interface typeCats {
    [key: string]: {
        type: string
        topCat: boolean
        image: string
        children: string[]
        units: string[]
    }
}

export interface typeDisp {
    [key: string]: {
        disp_en: string
        disp_es: string
    }
}

/*OTHER*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface GeolocationCoordinates {
    accuracy: number
    altitude: number
    altitudeAccuracy: null | number
    heading: null | number
    latitude: number
    longitude: number
    speed: null | number
}

export interface Position {
    coords: GeolocationCoordinates
}

/*PublicProfileUserInterface*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface PublicProfileUserInterface {
    userData: UserBioModelPublic
    staticData: null | StaticRoleModelOutbound[]
    postsData: null | PublicPostInterface[]
}

export interface StaticRoleModelOutbound extends StaticRoleModelBase {
    createdDatetime: string
}

interface StaticRoleModelBase extends StaticRoleModelInbound {
    id: string
    uid: null | string
    coordinates: FirebaseFirestore.GeoPoint
    coordinatesOffset:  {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    radiusOffsetMetres: number
    deleted: boolean
    deletedDatetime: null | FirebaseFirestore.Timestamp
    assocId: null | string
}

interface StaticRoleModelInbound {
    role: string
    visibleBool: boolean
    data: any
    userLocId: string
}

export interface PublicPostInterface {
    id: string
    uid: string
    userData: UserModelPublic
    userPostingRelationship: string | null
    item: string
    variety: string | null
    processed: string | null
    labels: string[] | null
    volumeUnits: string
    volumeTotal: number
    kgPerUnit: number
    searchVolumeUnits: string
    searchVolumeTotal: number
    priceTotal: number
    priceCurrency: string
    distance: number | null
    coordinates: FirebaseFirestore.GeoPoint
    coordinatesOffset: FirebaseFirestore.GeoPoint
    radiusOffsetMetres: number
    postEndDaysDelta: number
    earliestExchangeDaysDelta: number
    postEndDate: string
    earliestExchangeDate: string
    delivery: boolean
    collection: boolean
    assocId: string | null
    assocData: AssocModelPublic | null
    description: string | null
    photoArray: string[] | null
    photoUrlArray: string[] | null
    paymentCashBool: boolean
    paymentDigitalBool: boolean
    shortLinkApp: string
    singleBuyerBool: boolean
    minOfferVolume: number | null
    minOfferVolumeKg: number | null
    searchMinOfferVolume: number | null
}

/*PublicProfileAssocInterface*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface PublicProfileAssocInterface {
    assocData: AssocPublicDataInterface
    publicUserDataOwner: PublicProfileUserInterface // NB: THIS IS THE INTERFACE FROM THE PREVIOUS SNIPPET
    staticData: StaticRoleModelOutbound
    postsData: PublicPostInterface[] // THIS IS ALSO DEFINED IN THE OTHER SNIPPET
}

interface AssocPublicDataInterface {
    name: string
    phoneNumber: string
    anonLocBool: boolean
    memberCountAccepted: number
    deleted: boolean
    orgType: string
    orgTypeNameOther: string | null
    nit: string | null

    photoUrl: string
    bio: string | null
    publicPhotoArray: string[]
    userRelationshipState: string
    rating: number
    reviewCalcMap: ReviewMapInterface
}

interface StaticRoleModelInbound {
    role: string
    visibleBool: boolean
    data: any
    userLocId: string
}

export interface ReviewMapInterface {
    numBuyingReviews: number
    buyerRating: number
    numSellingReviews: number
    sellerRating: number
    numReviews: number
    fullRating: number
    rawReviews: any
}


/*Static Layer*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

export interface FlatDataInterface {
    [key: string]: StaticUserMapInterface | StaticAssocInterface;
}

interface FilterMapBitInterface {
    [key: string]: string[]
}

export interface FilterMapInterface {
    role: {
        all: FilterMapBitInterface
        live: FilterMapBitInterface
    },
    item: {
        all: FilterMapBitInterface
        live: FilterMapBitInterface
    }
}

interface StaticUserMapInterfaceRaw {
    assocBool: boolean
    uid: string
    coordinatesOffset: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    data: StaticUserRoleInterface[]
}

export interface StaticUserMapInterface extends StaticUserMapInterfaceRaw {
    firstName: string | null
    lastName: string | null
    photoUrl: string | null
    rating: number | null
    postsLiveNum: number
    postsLiveItemArray: string[]
    roleKey: string
}

interface StaticUserRoleInterface {
    id: string
    createdDatetime: string
    data: any
    role: string
}

interface StaticAssocInterfaceRaw {
    assocBool: boolean
    assocId: string | null
    createdDatetime: string
    id: string
    coordinates: {_latitude: number, _longitude: number} //FirebaseFirestore.GeoPoint
    buyItems: string[]
    sellItems: string[]
}

export interface StaticAssocInterface extends StaticAssocInterfaceRaw {
    name: string
    phoneNumber: string
    address: string
    photoUrl: string
    memberCountAccepted: number
    orgType: string
    postsLiveNum: number
    postsLiveItemArray: string[]
}