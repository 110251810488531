import React, { useState, useContext } from 'react';

import {
    Button,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    createStyles,
    makeStyles
} from '@material-ui/core';

//import { /* createStyles, */ makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from "react-intl";

import disp from "../../JSONS/DICT_disp.json";
import cats from "../../JSONS/DICT_flat_cat.json";
import items from "../../JSONS/DICT_flat_item.json";

import { hasKey, getKeyValue, DisplayString, RE } from "../../utils";

import { Context } from "../Wrapper";

import {
    typeItems,
    typeCats,
    typeDisp,
    BuySearchSortOptions
} from "../../interfaces";


interface typeItemsDisp {
    [key: string]: {
        disp_en: string
        disp_es: string
        imgUrl: string
        type: string
    }
}

interface V {
    disp_en: string
    disp_es: string
    imgUrl: string
    type: string
}

interface M extends Map<string,V> {}


let topCats: typeItemsDisp = {};


Object.keys(cats).forEach((key: string) => {

    const getTopCat = getKeyValue<keyof typeCats, typeCats>(key)(cats).topCat;

    if (getTopCat === true) {

        const getEs = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_es;
        const getEn = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_en;
        const getImg = getKeyValue<keyof typeCats, typeCats>(key)(cats).image;

        topCats[key] = {
            disp_es: getEs,
            disp_en: getEn,
            imgUrl: getImg,
            type: "cat"
        }
    }
});


function sorting(unsorted: typeItemsDisp, locale: string, key?: string, cat?: V): M {

    let sort: string[][]

    if (RE(locale)) {
        sort = Object.keys(unsorted).map(function(key) {
            return [key, unsorted[key].disp_es];
        })
    } else {
        sort = Object.keys(unsorted).map(function(key) {
            return [key, unsorted[key].disp_en];
        })
    }

    sort.sort(function(a, b) {
        if (a[1] > b[1]) {
            return 1;
        }
        if (a[1] < b[1]) {
            return -1;
        }
        return 0;
    })

    let rtn2: M = new Map();

    if (key && cat) {
        rtn2.set(key, cat)
    }

    sort.forEach(value => {
        rtn2.set(value[0], unsorted[value[0]])
    })

    return rtn2
}

const useStyles = makeStyles(() =>
    createStyles({
        root: { 
            fontSize: "13px",
            minHeight: "48px",
            "& .MuiTypography-root": {
                fontSize: "13px",
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            "& .arrowKey": {
                paddingLeft: "35px"
            }
        }
    }),
);


interface IProps {
    itemSelect: string | null
    setItemSelect: React.Dispatch<React.SetStateAction<string | null>>
    sortBy: BuySearchSortOptions
    setSortBy: React.Dispatch<React.SetStateAction<BuySearchSortOptions>>
    setVariety: React.Dispatch<React.SetStateAction<string | null>>
    setProcessed: React.Dispatch<React.SetStateAction<string | null>>
    setVolume: React.Dispatch<React.SetStateAction<{min: number | null; max: number | null;}>>
    setVolumeUnits: React.Dispatch<React.SetStateAction<string | null>>
    handleClick: (event: React.MouseEvent<HTMLButtonElement>, setState: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => void
}


export default function ItemSelect(props: IProps) {

    const classes = useStyles();

    const context = useContext(Context);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [itemsDisp, setItemsDisp] = useState(sorting(topCats, context.locale));
    const [history, setHistory] = useState([itemsDisp]);


    const handleSelect = (event: any, key: string) => {

        if (hasKey(cats, key)) {

            if (itemsDisp.get(key)?.type === "catAll") {
                props.setItemSelect(key);
                props.setVolumeUnits("units_kg");
                setAnchorEl(null);
                return;
            }

            const getEs = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_es;
            const getEn = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_en;
            const getImg = getKeyValue<keyof typeCats, typeCats>(key)(cats).image;

            let cat = {
                disp_es: `${getEs}: buscar todo`,
                disp_en: `${getEn}: search all`,
                imgUrl: getImg,
                type: "catAll"
            }

            let _items: typeItemsDisp = {};
            
            const getChildren = getKeyValue<keyof typeCats, typeCats>(key)(cats).children;

            getChildren.forEach(key => {

                const getEs = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_es;
                const getEn = getKeyValue<keyof typeDisp, typeDisp>(key)(disp).disp_en;

                let getImg: string;
                let getCat: string;

                if (hasKey(cats, key)) {
                    getImg = getKeyValue<keyof typeCats, typeCats>(key)(cats).image;
                    getCat = "cat"
                } else {
                    getImg = getKeyValue<keyof typeItems, typeItems>(key)(items).image;
                    getCat = "item"
                }

                _items[key] = {
                    disp_es: getEs,
                    disp_en: getEn,
                    imgUrl: getImg,
                    type: getCat
                }
            })

            let _itemsSorted: M = new Map();
            _itemsSorted = sorting(_items, context.locale, key, cat)

            setItemsDisp(_itemsSorted);
            setHistory(history.concat(itemsDisp));
            return;
        } else {
            props.setItemSelect(key);
            props.setVolumeUnits("units_kg");
            setAnchorEl(null);
            return;
        }
    }

    const handleClickBack = () => {

        if (history.length === 1) {
            setAnchorEl(null);
            return;
        } else {
            setHistory(history.splice(0, history.length -1))
            setItemsDisp(history.pop() as M)
        }
    }

    const handleClickReset = () => {

        
        //setHistory(history.splice(0, history.length -1))

        if (props.itemSelect === null) {
            setAnchorEl(null);
        } else {

            props.setVariety(null);
            props.setProcessed(null);
            props.setVolume({min: null, max:  null});
            props.setVolumeUnits(null);

            if (props.sortBy === BuySearchSortOptions.sortByVolumeHighLow || props.sortBy === BuySearchSortOptions.sortByVolumeLowHigh) {
                props.setSortBy(BuySearchSortOptions.sortByDistanceLowHigh);
            }
            
            //setItemsDisp(history.splice(1)[0]);
            //setHistory(history.splice(1));

            //let i = sorting(topCats, context.locale);

            //setItemsDisp(i);
            //setHistory([i]);

            setAnchorEl(null);
            props.setItemSelect(null);
        }
        
        //setItemsDisp(history.pop() as M)
    }

    const handleClose = (setState:  React.Dispatch<React.SetStateAction<HTMLElement | null>>) => {
        setState(null);
    };

    return (
        <div className="itemOption">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => props.handleClick(e, setAnchorEl)}>
                <p>
                    {(props.itemSelect !== null) ? 
                        <span className="option">
                            <DisplayString item={props.itemSelect} locale={context.locale}/>
                        </span> :
                        <FormattedMessage id="options.option.item" defaultMessage="Item"/>
                    }
                </p>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose(setAnchorEl)}

                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}

                PaperProps={{
                    style: {
                        maxHeight: 300,
                        width: 350,
                    }
                }}
            >
                <div className="itemOptionButtons">
                    <button onClick={() => handleClickBack()}>
                        {'<'}
                    </button>
                    <button onClick={() => handleClickReset()}>
                        <FormattedMessage id="options.option.any" defaultMessage="Any"/>
                    </button>
                </div>
                <div className="itemOptions">
                {Array.from(itemsDisp.keys()).map((key, index) => {
                    return (
                        <div key={key}>
                            <MenuItem onClick={event => handleSelect(event, key)} className={classes.root}>
                                <ListItemIcon>
                                    <img src={itemsDisp.get(key)?.imgUrl} alt="" id="itemOptionImg" width="30px" height="30px"/>
                                </ListItemIcon>
                                <ListItemText>
                                    {(RE(context.locale)) ? itemsDisp.get(key)?.disp_es : itemsDisp.get(key)?.disp_en}
                                </ListItemText>
                                {(itemsDisp.get(key)?.type === "cat") &&
                                    <ListItemIcon className="arrowKey">
                                        <strong>{'>'}</strong>
                                    </ListItemIcon>
                                }
                            </MenuItem>
                            {(itemsDisp.size -1 !== index) && <Divider/>}
                        </div>
                    );
                })}
                </div>
            </Menu>
        </div>
    );
}
