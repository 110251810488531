import React, { useContext, useEffect, useState, memo } from 'react';
import GoogleMapReact from 'google-map-react';
import { FormattedNumber, FormattedMessage } from "react-intl";
import { IconButton, Popover } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { NavLink } from "react-router-dom";
import { Context } from "./Wrapper";
import StarRating from "./StarRating";
//import { GoogleContext } from "./GoogleWrapper";
//import Paper from "../components/Paper";

import { DisplayString/* , RE */ } from "../utils";

import {
    PostDataMinimalInterface,
} from "../interfaces";

//import alt from "../images/icon_trader.png";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
                "& .MuiPaper-root": {
                position: "absolute",
                fontSize: "16px",

                //padding: "10px 20px 10px 20px",
                padding: "10px 15px 10px 15px",

                color: "black",
                backgroundColor: "#f9f9f9",
                "& span": {
                    //fontWeight: 600,
                    //color: "gray"
                }
            }
        },
    }),
);

function createMapOptions(maps: any) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      /* zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: true, */

      maxZoom: 16,
      clickableIcons: false
    };
}

const AnyReactComponent = ({ lat, lng, value, hover }: { lat: number, lng: number, value: PostDataMinimalInterface, hover: boolean }) => {

    const classes = useStyles();

    const context = useContext(Context);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="markerBalloon" onClick={() => handleClick}>
            {/* <NavLink to={`/publicacion?postId=${value.id}`} target="_blank"> */}
            <IconButton /* variant="outlined" */ onClick={handleClick}>
                <img
                    src={value.postPhotoUrl}
                    alt={""}
                    width={hover === true ? "64px" : "40px"}
                    height={hover === true ? "64px" : "40px"}
                    className={hover === true ? "hover" : "notHover"}
                />
            </IconButton>
            {/* </NavLink> */}
            <Popover
                id={id}
                className={classes.root}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <NavLink to={`/publicacion?postId=${value.id}`} target="_blank">
                    <div className="balloon">
                        <div className="left">
                            {value.assocName ?
                                <>
                                    <span className="name">
                                        {value.assocName}
                                    </span>
                                    {/* <>{value.assocData.photoUrl && <img src={value.assocData.photoUrl} alt={""}/>}</> */}
                                    <>{value.photoUrlAssoc ? <img src={value.photoUrlAssoc} alt={""}/>: <img src={value.photoUrlProfile} alt={""}/>}</>
                                    <StarRating rating={value.assocRating}/>
                                </>
                                : 
                                <>
                                    <span className="name">
                                        {value.userFirstName}
                                    </span>
                                    {/* <>{value.userData.photoUrl && <img src={value.userData.photoUrl} alt={""}/>}</> */}
                                    <img src={value.photoUrlProfile} alt={""}/>
                                    <StarRating rating={value.userRating}/>
                                </>
                            }
                        </div>
                        <div className="right">
                            <div className="top">
                                <p>
                                    <span><DisplayString item={value.item} locale={context.locale}/> {' '}</span>
                                </p>
                            </div>
                            <div className="middle">
                                <p><span><FormattedMessage id="options.option.volume" defaultMessage="Volume"/></span></p>
                                <p>
                                    {value.minOfferVolume ?
                                        <>
                                            <FormattedNumber value={value.minOfferVolume} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                                            <FormattedMessage id="home.to" defaultMessage="to"/> {' '}
                                            <FormattedNumber value={value.volumeTotal} style="decimal" maximumFractionDigits={1}/> {/* eslint-disable-line react/style-prop-object */}
                                        </>
                                        :
                                        <>
                                            <FormattedNumber value={value.volumeTotal} style="decimal" maximumFractionDigits={1}/> {/*eslint-disable-line react/style-prop-object*/}
                                            {' '}
                                        </>
                                    }
                                    <DisplayString item={value.volumeUnits} locale={context.locale}/>
                                </p> 
                            </div>
                            <div className="bottom">
                                <p><strong><FormattedNumber value={value.priceTotal/value.volumeTotal} /* style="currency" currency="COP" */  minimumFractionDigits={0} maximumFractionDigits={0}/>  COP / <DisplayString item={value.volumeUnits} locale={context.locale}/></strong></p> {/* eslint-disable-line react/style-prop-object */}
                            </div>
                        </div>
                    </div>
                </NavLink>
            </Popover>
        </div>
    );
};

interface IProps {
    postDataArray: PostDataMinimalInterface[]
    hover?: string
    mobile?: boolean
}

function SimpleMapFunction(props: IProps) {

    //console.log("SimpleMapFunction");

    //const classes = useStyles();

    //const context = useContext(Context);
    //const context = useContext(GoogleContext);

    const [myMap, setMyMap] = useState<google.maps.Map | undefined>(undefined);
    //const [_bounds, _setBounds] = useState<google.maps.LatLngBounds | null>(null);

    //const [balloon, setBalloon] = useState<{item: string, volume: number, volumeUnits: string, price: number} | null>(null);

    
    //const apiIsLoaded = (map: any, maps) => {
    const apiIsLoaded = (map: google.maps.Map) => {
        setMyMap(map);

        if (props.mobile) {
            _fitBounds(map);
        }
    };

    const _fitBounds = (map: google.maps.Map) => {
        
        const bounds = new google.maps.LatLngBounds();
        
        props.postDataArray.map(place => {
            bounds.extend(new google.maps.LatLng(
                place.coordinatesOffset._latitude,
                place.coordinatesOffset._longitude
            ));
            //_setBounds(bounds);
            return place.id;
        });

        map.fitBounds(bounds);         
    };

    useEffect(() => {
        //console.log("useEffect");

        if (props.postDataArray.length > 0 && myMap) {
            _fitBounds(myMap)
        }
    }, [props.postDataArray]); // eslint-disable-line react-hooks/exhaustive-deps

    /* function _onChildMouseEnter (key: any, childProps: any) {
        //setBalloon({item: childProps.value.item, volume: childProps.value.volumeTotal, price: childProps.value.priceTotal});
        setBalloon({item: childProps.value.item, volume: childProps.value.volumeTotal, volumeUnits: childProps.value.volumeUnits, price: childProps.value.priceTotal});
    } */
    
    /* const _onChildMouseLeave = () => {
        setBalloon(null);
    } */

    return (
        <div style={{ height: '98vh', width: '100%' }}>
        <GoogleMapReact
            //bootstrapURLKeys={{ key: "AIzaSyDkHqSJAFT7ydQ9w0lHsOUsGTnX-xVpYCw" }}
            defaultCenter={{
                lat: 4.710988,
                lng: -74.072092
            }}
            defaultZoom={6}
            yesIWantToUseGoogleMapApiInternals
            //onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            onGoogleApiLoaded={({ map }) => apiIsLoaded(map)}

            options={createMapOptions}
            //onChildMouseEnter={_onChildMouseEnter}
            //onChildMouseLeave={_onChildMouseLeave}

            //onClick={() => console.log("onClick")}

            hoverDistance={20}

            //bounds={_bounds}
        >
            {props.postDataArray.map((value, index) => {

                return(
                    
                        <AnyReactComponent
                            key={index}
                            lat={value.coordinatesOffset._latitude}
                            lng={value.coordinatesOffset._longitude}
                            //id={value.id}
                            //item={value.item}
                            value={value}
                            hover={value.id === props.hover}
                            //text={'text'}
                            //showBallon={value.id === balloon}
                            
                        />
                    
                    );
                
            })}
        </GoogleMapReact>
        {/* {balloon !== null &&
            <div className="snackbar">
                <Paper elevation={3} className={classes.root}>
                    <div className="div">
                    <span><DisplayString item={balloon.item} locale={context.locale}/></span> {'  '}
                    <FormattedNumber value={balloon.volume} style="decimal"/> <DisplayString item={balloon.volumeUnits} locale={context.locale}/> {'  '}
                    <strong><FormattedNumber value={balloon.price} style="currency" currency="COP"/></strong>
                    </div>
                </Paper>
            </div>
        } */}
      </div>
    );
}
 
export default memo(SimpleMapFunction); //export default SimpleMap;