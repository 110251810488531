import React, { createContext } from "react";
import { GoogleApiWrapper, IMapProps } from 'google-maps-react';

export const GoogleContext  = createContext({} as IMapProps);

const GoogleWrapper: React.FC<IMapProps> = (props) => {

    return (
        <GoogleContext.Provider value={ props }>
            {props.children}
        </GoogleContext.Provider>
    );
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyDkHqSJAFT7ydQ9w0lHsOUsGTnX-xVpYCw")
})(GoogleWrapper);