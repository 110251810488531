import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion'; //import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/AccordionSummary' //import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/AccordionActions' //import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
        fontFamily: '"Helvetica Neue",sans-serif',
        fontSize: '18px', //fontSize: '30px',
        fontWeight: theme.typography.fontWeightRegular,
    },
    answer: {
        fontFamily: '"Helvetica Neue",sans-serif',
        fontSize: '15px',//fontSize: '25px',
        fontWeight: theme.typography.fontWeightRegular,
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '15px',
    },
    answerContainer: {
        justifyContent: 'flex-start'
    }
}));
  
export default function SimpleExpansionPanel() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>¿Cómo funciona el pago?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Se puede hacer en efectivo o en línea.
                <br/><br/>
                Para los intercambios presenciales, el pago se puede hacer en persona y en efectivo. Instamos a todos nuestros usuarios a asegurarse de tomar medidas de higiene de acuerdo con las recomendaciones de las autoridades sanitarias.
                <br/><br/>
                Si ha organizado entregas y ha negociado el costo de transporte con la otra parte, el comprador y el vendedor pueden comunicarse entre sí por chat e intercambiar las condiciones de pago. La información de contacto de la otra parte se pone a disposición después de que se haya aceptado la oferta o solicitud de contribución.
                <br/><br/>
                ¿Y los pagos en línea? Listo cuenta con la extensión MercadoPago, que permite realizar pagos digitales vía tarjeta de crédito, sistema PSE, o giros a través de Efecty, los pagos digitales se pueden integrar a cada una de sus compras y ventas. Simplemente asocie su cuenta de MercadoPago con su cuenta de Listo.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>¿Cómo funciona el transporte o la logística en Listo?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Puede seleccionar la opción <strong>Entrega</strong> y agregar cualquier nota sobre su capacidad para organizar la entrega del producto, o puede seleccionar la opción <strong>Recolección</strong> si desea que recojan el producto directamente de la finca. Por el momento, al seleccionar <strong>Entrega</strong> y leer las especificaciones de esta opción en el cuadro de resumen, el vendedor y el comprador pueden contactarse a través del chat para discutir o negociar detalles más específicos. Recuerde incluir la tarifa de envío según su negociación en la oferta.
                <br/><br/>
                Esté atento a este espacio, ya que también estamos planeando integrar transportistas locales en cada pedido. Hemos escuchado y recibido sugerencias sobre esto, ya que obtener acceso a un transportista local para llegar a otro destino es importante tanto para los agricultores como para los compradores.
                <br/><br/>
                Para contactar con los transportistas locales cerca de su ubicación, haga clic en el botón <strong>Transporte</strong> ubicado en la página de inicio, allí podrá observar la lista de transportistas disponibles.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            >
            <Typography className={classes.heading}>¿Qué significa entrega o recolección?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Entrega significa que usted organizará la entrega del producto, hasta una cierta distancia de acuerdo con sus preferencias, para llegar al comprador final.
                <br/><br/>
                Recolección significa que usted prefiere que el comprador llegue a un punto de encuentro para recoger el producto. Por ejemplo, esto puede ser en su finca, la oficina de su asociación, o el centro de acopio en su municipio.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            >
            <Typography className={classes.heading}>¿Cuánto cuesta usar la plataforma Listo?</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.answerContainer}>
            <Typography className={classes.answer}>
                ¡Es gratis! Actualmente no hay ningún costo por usar Listo.
                <br/><br/>
                ¡En el futuro, siempre y cuando nuestros precios cambien, le avisaremos inmediatamente!
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            >
            <Typography className={classes.heading}>¿Habrá una versión web de la aplicación?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                ¡Sí, muy pronto! Por favor, esté pendiente de este espacio y también suscríbase a nuestros canales en redes sociales. Haremos un anuncio sobre el lanzamiento de nuestra aplicación web pronto.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
            >
            <Typography className={classes.heading}>Tengo más de un número de teléfono móvil, ¿cuál debería usar para registrar una cuenta?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Recomendamos registrarse con un número de uso constante. Por ejemplo, el teléfono que utiliza con mayor frecuencia para la recepción de llamadas de posibles compradores o vendedores y, si utiliza un teléfono inteligente, el que se usa continuamente para administrar aplicaciones.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
            >
            <Typography className={classes.heading}>Quiero contactar a más compradores, ¿qué puedo hacer en Listo?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Recomendamos hacer una publicación de lo que tiene listo para la venta en las próximas semanas o meses. Algunos compradores estarán buscando ciertos productos, así que cuando les avisamos sobre nuevas publicaciones, pueden ver los productos que usted está ofreciendo.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
            >
            <Typography className={classes.heading}>Mi ubicación es incorrecta en la aplicación, ¿cómo la cambio?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Vaya a su perfil y haga clic en <strong>Administrar mis lugares favoritos</strong>. Si solo tiene una ubicación y es incorrecta, haga clic en ella para abrir los detalles de la misma y seguidamente haga clic en <strong>Cambiar ubicación principal</strong>. Después, asegúrese de haber guardado el lugar correcto en el mapa y haga clic en <strong>Selecciona la ubicación</strong> y luego en <strong>CONFIRMAR</strong>. Ahora, podrá agregar los detalles de la ubicación, una vez hecho esto haga clic en <strong>ENVIAR</strong>. Regresará a su lista de lugares, donde verá la ubicación recién agregada. Ahora podrá eliminar su ubicación anterior.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
            >
            <Typography className={classes.heading}>¿Por qué no aparece nada en “Compras cerca de ti”?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Esto significa que todavía no hay compradores o asociaciones de agricultores que hayan creado un pedido público. Esto se basa en su ubicación actual. Para buscar compradores en otros lugares, haga clic en Buscar y seleccione su artículo. Luego, cambie a la vista de mapa y mueva el mapa a otra ubicación, por ejemplo una gran ciudad cercana. Debería poder encontrar más compradores allí.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
            >
            <Typography className={classes.heading}>¿Para qué sirve la pestaña de asociaciones?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Esta pestaña es para grupos de agricultores. Por lo tanto, hay funciones para que los líderes de estas asociaciones envíen difusiones, administren su lista de miembros y organicen el acopio de volúmenes más grandes.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11a-content"
            id="panel11a-header"
            >
            <Typography className={classes.heading}>Quiero crear un perfil para mi tienda o negocio, ¿cómo puedo hacerlo?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                Sí, ¡gracias por hacernos saber! Estamos trabajando arduamente para agregar esta nueva característica, para que los administradores de las empresas locales también puedan crear un perfil empresarial en Listo.
                <br/><br/>
                Por favor, cree una cuenta personal ahora y le informaremos cuando pueda crear un perfil empresarial.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header"
            >
            <Typography className={classes.heading}>Algo está mal con la aplicación y no funciona correctamente.</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className={classes.answer}>
                ¡Lamentamos las molestias! Nuestro equipo comprueba regularmente los errores técnicos, aunque a veces puede haber un pequeño error que se escape de nuestras manos. En este caso, asegúrese de haber actualizado la aplicación a la versión más reciente, ya sea en Play Store o App Store.
                <br/><br/>
                Si la aplicación no se abrió la primera vez, también puede intentar cerrarla y abrirla de nuevo. A veces, al cerrar la aplicación, se borrarán algunos datos de caché antiguos para que se actualicen.
                <br/><br/>
                Si todavía tiene algún problema técnico, contáctenos a través de WhatsApp o correo electrónico.
            </Typography>
            </AccordionDetails>
        </Accordion>
        
        </div>
    );
}