import React from "react";
import { useLocation } from "react-router-dom";

const NoMatch: React.FC = () => {
    let location = useLocation();

    return (
        <div className="body">
            <div>
                <h2>Error 404</h2>
                <h3>No match for <code>{location.pathname}</code></h3>
            </div>
        </div>
    );
}

export default NoMatch