import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ReactPlayer from 'react-player/youtube';

class Answer1 extends Component {
  render() {
    return (

      <div className="body">
        
        <div className="answers">

            <br/>

            <h1 id="para-agricultores-cómo-crear-una-publicación">Para agricultores, ¿cómo crear una publicación?</h1>

            <ul>
              <li><Link to="#opción-1-a-través-del-menú-de-mensajes">A través del menú de mensajes</Link></li>
              <li><Link to="#opción-2-a-través-del-menú-de-voz">A través del menú de voz</Link></li>
              <li><Link to="#opción-3-a-través-de-la-aplicación">A través de la aplicación</Link></li>
            </ul>

            <br/>

            <div className='vid'>
            <ReactPlayer url='https://www.youtube.com/watch?v=z9fzZqyikbI&feature=youtu.be' width='100%' height='100%' controls={true}/>
            </div>

            <br/>
            <br/>

            <h2 id="opción-1-a-través-del-menú-de-mensajes">Opción 1. A través del menú de mensajes</h2>

            <blockquote>
              <p>El menú de mensajes de Listo no le genera ningún tipo de costo al usuario, la recepción y el envío de mensajes son totalmente gratuitos, simplemente requiere tener una buena señal de celular.</p>
            </blockquote>

            <blockquote>
              <p>Este formato no se limita al tipo de teléfono celular que tenga un usuario, sino que su objetivo es que los usuarios tengan acceso a la plataforma en todo momento.</p>
            </blockquote>

            <blockquote>
              <p>El número <strong>891177</strong>  corresponde al destinatario y al canal de comunicación a través de mensajes de texto con Listo.</p>
            </blockquote>

            <br/>
            <h3 id="a-escribir-el-número-de-listo">A. Escribir el número de Listo</h3>
            <ol>
              <li>En su servicio de mensajes <strong>escriba el número 891177</strong>.</li>
              <li>Escriba las letras <strong>LT</strong> sin espacios como mensaje.</li>
              <li><strong>Envíe</strong> el mensaje al 891177.</li>
              <li>Recibirá como respuesta <strong>un amplio menú de opciones</strong>.</li>
            </ol>

            <br/>
            <h3 id="b-escribir-lt-v">B. Escribir LT v</h3>

            <ol>
              <li>
                Responda <strong>LT v</strong> para seleccionar la opción número 2, esta dice <strong>LT vender</strong>.
              </li>
              <li><strong>¿Qué producto quiere vender?</strong><br />
            Escriba <strong>LT</strong>, agregue un espacio y digite <strong>el número que represente el producto</strong> que quiere vender.
                <blockquote>
                  <p>Si quiere <strong>ver más productos escriba LT 0</strong>.</p>
                </blockquote>
              </li>
              <li><strong>¿En qué tipo de unidades está vendiendo?</strong>, es decir, kilogramos, arrobas u otros.<br />
            Responda <strong>LT</strong>, agregue un espacio y digite <strong>el número que represente la opción</strong> que desea seleccionar.
                <blockquote>
                  <p>Por ejemplo, si el menú dice “digite 1 para kilogramos, 2 para arrobas”, para vender en kilogramos, escriba <strong>LT 1</strong>.</p>
                </blockquote>
              </li>
              <li><strong>¿Qué cantidad está vendiendo?</strong>, por ejemplo, cuántos kilos tiene disponibles para la venta.<br />
            Responda <strong>LT</strong> y <strong>el número</strong> de kilogramos o arrobas que está vendiendo.
                <blockquote>
                  <p>No debe escribir la unidad de volumen, por ejemplo, <strong>no debe escribir KG</strong> por kilogramos, ya que esto se ha seleccionado en el paso anterior.</p>
                </blockquote>
              </li>
              <li>
                <strong>Pregunta sobre el precio total o unitario.</strong><br />
            Seleccione si quiere publicar <strong>el precio total</strong> o <strong>el precio unitario</strong>, responda con <strong>LT y el número de la opción</strong> que corresponda.
              </li>
              <li><strong>¿Cuál es el valor en pesos?</strong>
            Si escogió precio unitario responda <strong>LT</strong> y <strong>el precio por kilo o arroba de ese producto</strong>.
                <blockquote>
                  <p>Por ejemplo, si son <strong>5000 pesos por kilo</strong>, se escribe <strong>LT 5000</strong>. Por otro lado, si escogió precio total, responda <strong>LT</strong> y <strong>el precio total de todos los kilos o las arrobas</strong> que vaya a vender.</p>
                </blockquote>
              </li>
              <li><strong>¿En cuántos días estará el producto disponible para el intercambio?</strong><br />
            Usted puede ofertar un producto antes de haberlo cosechado o antes de realizar algún proceso poscosecha. Si desea realizar una venta a futuro, por ejemplo <strong>en 10 días</strong>, debe responder <strong>LT 10</strong>.
                <blockquote>
                  <p>Si por el contrario su producto está listo para intercambiar <strong>ese mismo día</strong>, debe responder <strong>LT 0</strong>.</p>
                </blockquote>
              </li>
              <li>
                <strong>¿Cuántos días a partir de hoy quiere publicar el producto?</strong><br />
            Esto es el tiempo durante el cual su producto será visible en Listo, es decir, el tiempo que la publicación estará activa dentro de la plataforma.<br />
            Responda <strong>LT</strong> seguido del <strong>número de días</strong> durante los que su producto estará disponible para la venta.
              </li>
              <li>
                <strong>¿En qué ubicación se encuentra para vender?</strong>
            Esta es la ubicación desde la que está haciendo la venta.<br />
            Responda <strong>LT</strong> seguido del <strong>número que corresponda a la ubicación</strong> que desee seleccionar. Esta ubicación de usuario ha sido previamente añadida durante el proceso de registro.
              </li>
              <li>
                <strong>¿Cuál es el método de envío del producto?</strong><br />
            Es decir, si quiere llevar el producto hasta el otro usuario, si el otro usuario debe recoger el producto en la ubicación previamente seleccionada, o si ambas opciones funcionan para usted.<br />
            Responda <strong>LT</strong> y <strong>el número de la opción</strong> que corresponda.
              </li>
              <li>Ahora recibirá una serie de mensajes con <strong>el resumen de la publicación</strong>, verifique que toda la información anteriormente registrada sea correcta. <strong>Si todo está bien, responda LT 1.</strong>
                <blockquote>
                  <p>Si por el contrario <strong>quiere cancelar la publicación, responda LT 2.</strong></p>
                </blockquote>
              </li>
            </ol>

            <p><strong>¡La publicación ha sido exitosa!</strong></p>

            
            <br/>
            <br/>

            <h2 id="opción-2-a-través-del-menú-de-voz">Opción 2. A través del menú de voz</h2>
            <blockquote>
              <p>Al igual que el menú de mensajes, este menú de voz no le genera ningún tipo de costo al usuario, la recepción de la llamada y el envío de respuestas son completamente gratuitos, simplemente requiere tener una buena señal de celular.</p>
            </blockquote>

            <blockquote>
              <p>Este menú está especialmente diseñado para los usuarios que tienen dificultades al escribir en sus teléfonos celulares.</p>
            </blockquote>

            <br/>
            <h3 id="a-escribir-el-número-de-listo-1">A. Escribir el número de Listo</h3>
            <ol>
              <li>En su servicio de mensajes <strong>escriba el número 891177</strong>.</li>
              <li>Escriba las letras <strong>LT</strong> sin espacios como mensaje.</li>
              <li><strong>Envíe</strong> el mensaje al 891177.</li>
              <li>Recibirá como respuesta <strong>un amplio menú de opciones</strong>.</li>
            </ol>

            <br/>
            <h3 id="b-escribir-lt-l-para-llamar">B. Escribir “LT l” para llamar</h3>

            <ol>
              <li>Responda <strong>LT l</strong> para seleccionar la opción número 1, esta dice <strong>LT llamar</strong>.
                <blockquote>
                  <p>Este mensaje va a generar una llamada telefónica del número 891177, conteste y escuche atentamente lo que dice la voz pregrabada.</p>
                </blockquote>
              </li>
              <li>Para <strong>publicar un producto para la venta</strong>, escriba <strong>1#</strong> para seleccionar <strong>la opción número 1</strong>.
                <blockquote>
                  <p>Cada vez que seleccione una opción escriba el número que corresponda <strong>seguido de la tecla numeral (#)</strong>.</p>
                </blockquote>
              </li>
              <li>
                Seleccione si quiere acceder a sus productos favoritos (que corresponden a los productos seleccionados durante el proceso de registro) o si quiere acceder a la lista completa de productos en Listo. Para seleccionar la opción número 1, <strong>escriba 1#</strong>.
              </li>
              <li>Escuche atentamente la lista de productos y <strong>escriba el número que corresponda al producto</strong> que quiere vender <strong>seguido de la tecla numeral (#)</strong>.
                <blockquote>
                  <p>Si aplica, ingrese la variedad del producto, seleccione la opción que corresponda y escriba el número seguido de la tecla numeral (#).</p>
                </blockquote>
              </li>
              <li>
                Ingrese <strong>la unidad de volumen</strong> que está vendiendo, es decir, si vende por kilogramos, arrobas u otro. <strong>Seleccione el número que corresponda</strong> y presione <strong>la tecla numeral (#)</strong>.
              </li>
              <li>
                Ingrese <strong>la cantidad</strong> que está vendiendo, por ejemplo, si son <strong>50 kilogramos</strong>, escriba <strong>50#</strong>.
              </li>
              <li>
                Seleccione <strong>el precio total</strong> por esa cantidad de producto o <strong>el precio unitario</strong> por kilos o arrobas, escriba <strong>el número que corresponda</strong> seguido de <strong>la tecla numeral (#)</strong>.
              </li>
              <li>
                Ingrese <strong>el precio del producto</strong>, de acuerdo con la opción previamente seleccionada escriba el precio seguido de <strong>la tecla numeral (#)</strong>. Por ejemplo, si el precio unitario por kilo es <strong>5000</strong>, escriba <strong>5000#</strong>.
              </li>
              <li>Debe <strong>confirmar el precio que ingresó</strong>, así que si <strong>el precio es correcto</strong> escriba <strong>1#</strong>.
                <blockquote>
                  <p>Si hubo <strong>un error al ingresar</strong> el precio, escriba <strong>2#</strong> y volverá al paso anterior para ingresar el precio del producto nuevamente.</p>
                </blockquote>
              </li>
              <li>Ingrese <strong>el número de días</strong> en los que su producto estará listo para el intercambio, usted puede ofertar un producto antes de haberlo cosechado o antes de realizar algún proceso poscosecha. Si desea realizar una venta a futuro, escriba <strong>el número que corresponda a los días</strong> que le tomará tener el producto listo, seguido de <strong>la tecla numeral (#)</strong>.
                <blockquote>
                  <p>Si el producto <strong>está listo el día</strong> en el que va a hacer la publicación, escriba <strong>0#</strong>.</p>
                </blockquote>
              </li>
              <li>
                Ingrese <strong>el número de días</strong> durante los que quiere publicar el producto, es decir, <strong>el tiempo que la publicación estará activa</strong> dentro de la plataforma. Escriba <strong>el número de días</strong> seguido de <strong>la tecla numeral #</strong>.
              </li>
              <li>
                Ingrese <strong>la ubicación desde donde está haciendo la publicación</strong>, esta ubicación de usuario ha sido previamente añadida durante el proceso de registro. Seleccione <strong>el número que corresponda a la ubicación</strong> seguido de <strong>la tecla numeral (#)</strong>.
              </li>
              <li>
                Ingrese <strong>el método de envío</strong> del producto, es decir, si quiere llevar el producto hasta el otro usuario, si el otro usuario debe recoger el producto en en la ubicación previamente seleccionada, o si ambas opciones funcionan para usted. Seleccione <strong>el número que corresponda</strong> seguido de <strong>la tecla numeral (#)</strong>.
              </li>
              <li>El menú leerá <strong>el resumen de la publicación</strong> que está a punto de realizar, escuche atentamente para <strong>verificar que todos los datos ingresados sean correctos</strong>. Si todo está bien, <strong>confirme la publicación</strong>, escriba <strong>1#</strong>.
                <blockquote>
                  <p>Por el contrario, si <strong>hubo un error</strong> en la información ingresada, escriba <strong>2# para cancelar</strong> la publicación.</p>
                </blockquote>
              </li>
              <li>Si ha decidido publicar el producto para la venta, el menú le indicará que <strong>la publicación ha sido exitosa</strong>. 
            Ahora puede <strong>colgar la llamada</strong>.
                <blockquote>
                  <p>Recibirá un mensaje de texto de confirmación del 891177 con el número de publicación, nombre del producto y volumen.</p>
                </blockquote>
              </li>
            </ol>

            <p><strong>¡La publicación ha sido exitosa!</strong></p>

            <br/>
            <br/>

            <h2 id="opción-3-a-través-de-la-aplicación">Opción 3. A través de la aplicación</h2>
            <blockquote>
              <p>Publique sus productos a través del botón <strong>Publicación en la página de inicio</strong> o a través de <strong>la pestaña Intercambio</strong> ubicada en el menú inferior.</p>
            </blockquote>

            <br/>
            <h3 id="a-seleccionar-la-categoría-y-el-producto">A. Seleccionar la categoría y el producto</h3>
            <blockquote>
              <p>Verá una lista de categorías que corresponden a los productos que se pueden comercializar a través de Listo.</p>
            </blockquote>

            <ol>
              <li><strong>Seleccione la categoría</strong> que contenga el producto que desea vender.</li>
              <li><strong>Seleccione el producto</strong> que desea vender. Esto lo llevará a una ventana donde debe escoger la variedad del producto si aplica.</li>
              <li>Oprima el botón <strong>Siguiente</strong>.</li>
            </ol>

            <br/>
            <h3 id="b-ingresar-los-detalles-de-la-publicación">B. Ingresar los detalles de la publicación</h3>

            <ol>
              <li><strong>Seleccione las unidades de volumen</strong> en las que está vendiendo ese producto.
                <blockquote>
                  <p>Por ejemplo, kilogramos, arrobas u otro.</p>
                </blockquote>
              </li>
              <li>
                <strong>Ingrese la cantidad</strong> que está vendiendo.
              </li>
              <li>
                Seleccione <strong>el precio total</strong> o <strong>el precio unitario</strong>.
              </li>
              <li>
                Oprima el botón <strong>Siguiente</strong>.
              </li>
              <li>
                <strong>Seleccione la fecha de intercambio</strong>, es decir el día en el que el producto estará listo para su venta. Una vez haya seleccionado el día en el calendario, debe <strong>oprimir el botón Aceptar</strong>.
              </li>
              <li>
                En la siguiente casilla, <strong>ingrese el número de días</strong> durante los cuáles la publicación estará activa dentro de la plataforma, es decir, el tiempo durante el cual su publicación será visible en Listo.
              </li>
              <li>
                <strong>Seleccione el lugar</strong> desde el cual está haciendo la publicación, si el usuario ha registrado varios lugares aparecerá una lista y en ella podrá escoger la ubicación de su preferencia.
              </li>
              <li>
                <strong>Seleccione el método de entrega</strong>, es decir, si se quiere llevar el producto hasta el otro usuario, si el otro usuario debe recoger el producto en la ubicación previamente seleccionada, o si ambas opciones funcionan para usted.
              </li>
              <li><strong>Oprima el botón Ver Resumen</strong>. Verifique que todo esté en orden antes de publicar el producto para la venta.
                <blockquote>
                  <p>Si hay algo que quiera cambiar, puede <strong>oprimir el botón Editar</strong>.</p>
                </blockquote>
              </li>
              <li>Si todo está bien, debe <strong>oprimir el botón Enviar</strong> y seguidamente <strong>el botón Confirmar</strong>.</li>
            </ol>

            <p><strong>¡La publicación ha sido exitosa!</strong></p>

            <br/>

        </div>

        </div>
    );
  }
}
 
export default Answer1;