import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { FormattedMessage, FormattedNumber } from "react-intl";
import { NavLink } from "react-router-dom";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialog from '@material-ui/core/Dialog';
//import MuiDialogActions from '@material-ui/core/DialogActions';

import { 
    createStyles, Theme, withStyles, WithStyles,
    /* Dialog, */
    IconButton,
    Divider
} from '@material-ui/core';

import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';

import StarRating from "./StarRating";

import items from "../JSONS/DICT_flat_item.json";
//import disp from "../JSONS/DICT_disp.json";

import { Context } from "./Wrapper";
import { DisplayString, getKeyValue } from "../utils";

import {
    PublicProfileUserInterface,
    StaticRoleModelOutbound,
    PublicPostInterface,
    typeItems,
} from "../interfaces";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    //children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {/* <Typography variant="h6">{children}</Typography> */}
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        //maxWidth: "600px", //maxWidth: "320px"
        minWidth: "600px",
        //'@media only screen and (max-width: 1024px)': {
        '@media only screen and (max-width: 620px)': {
            maxWidth: "90vw",
            minWidth: "90vw",
        }
    },
}))(MuiDialogContent);

const Dialog = withStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            margin: 'auto',
        },
    },
}))(MuiDialog);

/* const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions); */


interface IProps {
    publicProfileUser: PublicProfileUserInterface | null
    open: boolean
    handleClose: () => void
    postId?: string | null
    setPostId?: React.Dispatch<React.SetStateAction<string | null>>
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}


export default function CustomizedDialogs(props: IProps) {

    const context = useContext(Context);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
        props.handleClose();

        if (event.currentTarget.id !== props.postId && props.setPostId && props.setLoading) {
            props.setLoading(true);
            props.setPostId(event.currentTarget.id);
        }
    }

    //console.log("props.publicProfileUser");
    //console.log(props.publicProfileUser);

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open} className="profileUserDialog">
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}/>
            <DialogContent>
                {props.publicProfileUser ? 
                    <div>
                        
                        {props.publicProfileUser.userData.photoUrl ?
                            <img className="profileImg" src={props.publicProfileUser.userData.photoUrl} alt={""}/> :
                            <img className="profileImg" src={""} alt={""}/>
                        }
                        <p>{props.publicProfileUser.userData.firstName} {props.publicProfileUser.userData.lastName}</p>
                        <span className="starRating">
                            <StarRating rating={props.publicProfileUser.userData.rating}/>
                            <p>({props.publicProfileUser.userData.reviewCalcMap.numReviews})</p>
                        </span>

                        <NavLink to={`/descargar`}>
                        <div className="sendMessage">
                            <p>
                                <MailIcon fontSize="large"/>
                                </p>
                                <p>
                                <FormattedMessage id="post.send_message" defaultMessage="Send message"/>
                            </p>
                        </div>
                        </NavLink>
                        {props.publicProfileUser.userData.bio && <p className="bio">{props.publicProfileUser.userData.bio}</p>}
                        

                        {/* {(props.publicProfileUserInterface.staticData && props.publicProfileUserInterface.staticData[0].coordinatesOffset) && */}
                        {/*{(props.publicProfileUserInterface.staticData && props.publicProfileUserInterface.staticData[0].coordinatesOffset !== undefined) && */}
                        {(props.publicProfileUser.staticData && props.publicProfileUser.staticData.length > 0) &&
                            
                            <div style={{ height: '220px', width: '100%', marginTop: '5px' }} /* className="map" */>
                            <GoogleMapReact
                                defaultCenter={{
                                    lat: props.publicProfileUser.staticData[0].coordinatesOffset._latitude,
                                    lng: props.publicProfileUser.staticData[0].coordinatesOffset._longitude
                                }}
                                defaultZoom={10}
                                options={{
                                    maxZoom: 14,
                                    clickableIcons: false
                                }}
                            >
                                <Circle
                                    lat={props.publicProfileUser.staticData[0].coordinatesOffset._latitude}
                                    lng={props.publicProfileUser.staticData[0].coordinatesOffset._longitude}
                                />

                            </GoogleMapReact>
                            </div>
                        }

                        <br/>

                        {props.publicProfileUser.staticData && props.publicProfileUser.staticData.length > 0 &&
                            <h2 className="roles">
                                <FormattedMessage id="profileUser.roles" defaultMessage="Roles"/>
                            </h2>
                        }
                        {props.publicProfileUser.staticData?.map((value: StaticRoleModelOutbound, i: number) =>
                            <div key={i} className="roleDesc">

                                {value.role === "transporter" ?
                                    <>
                                        <p className="role">
                                            <FormattedMessage id={`profileUser.${value.role}`} defaultMessage={`${value.role}`}/>
                                        </p>
                                        <div className="roleItems">
                                            {value.data.vehicleType && 
                                                <div key={i} className="roleItem">
                                                        <TransportIcon vehicleType={value.data.vehicleType as string}/>
                                                        <p>
                                                            <DisplayString item={value.data.vehicleType} locale={context.locale}/>
                                                        </p>
                                                </div>
                                            }
                                        </div>
                                        {value.data.description && <p className="vehicleDesc">{value.data.description}</p>}
                                    </>
                                    :
                                    <>
                                        <p className="role">
                                            <FormattedMessage id={`profileUser.${value.role}`} defaultMessage={`${value.role}`}/>
                                        </p>
                                        <div className="roleItems">
                                            {value.data.items && value.data.items.map((value: string, i: number) =>
                                                <div key={i} className="roleItem">
                                                    <img src={getKeyValue<keyof typeItems, typeItems>(value)(items).image} alt=""/>
                                                    <p>
                                                        <DisplayString item={value} locale={context.locale}/>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                                <Divider/>
                            </div>
                        )}

                        
                        {(props.publicProfileUser.postsData && props.publicProfileUser.postsData.length > 0) &&
                            <div className="livePosts">
                                <h2>
                                    <FormattedMessage id="profileUser.livePosts" defaultMessage="Live Posts"/>
                                </h2>
                                {props.publicProfileUser.postsData?.map((value: PublicPostInterface, i: number) =>

                                    <NavLink key={i} to={`/publicacion?postId=${value.id}`}>
                                    <div className="livePost" id={value.id} onClick={handleClick} >
                                        <p>
                                            <DisplayString item={value.item} locale={context.locale}/>
                                        </p>
                                        <div className="imgVolPrice">
                                            <img src={getKeyValue<keyof typeItems, typeItems>(value.item)(items).image} alt=""/>
                                            <div>
                                                <p>
                                                    <FormattedMessage id="options.option.volume" defaultMessage="Volume"/>
                                                </p>
                                                {(value.singleBuyerBool === true) ?
                                                    <p>
                                                        <FormattedNumber value={value.volumeTotal} style="decimal" maximumFractionDigits={2}/> {/* eslint-disable-line react/style-prop-object */}
                                                        <DisplayString item={value.volumeUnits} locale={context.locale}/>
                                                    </p> :
                                                    <p className="volValue">
                                                        <FormattedNumber value={value.minOfferVolume as number} style="decimal" maximumFractionDigits={2}/> {/* eslint-disable-line react/style-prop-object */}
                                                        <FormattedMessage id="home.to" defaultMessage="to"/> {' '}
                                                        <FormattedNumber value={value.volumeTotal} style="decimal" maximumFractionDigits={2}/> {/* eslint-disable-line react/style-prop-object */}
                                                        <DisplayString item={value.volumeUnits} locale={context.locale}/>
                                                    </p>
                                                }

                                            </div>
                                            <div>
                                                <p>
                                                    <FormattedMessage id="options.option.price" defaultMessage="Price"/>
                                                </p>
                                                <p><FormattedNumber value={value.priceTotal} minimumFractionDigits={0} maximumFractionDigits={0}/> COP</p>
                                            </div>
                                        </div>

                                        <Divider/>
                                    </div>
                                    </NavLink>
                                )}
                            </div>
                        }
                    </div>:
                    <div>
                        <p>Loading...</p>
                    </div>
                }
            </DialogContent>
        </Dialog>
        
    );
}

const Circle = ({ lat, lng }: { lat: number, lng: number }) => {
    return (
        <div className="circle"/>
    );
}

const TransportIcon = ({vehicleType} : {vehicleType: string}) => {
    switch (vehicleType) {
        case "transport_bike":
            return (<><DirectionsBikeIcon/></>);
        case "transport_car":
            return (<><DirectionsCarIcon/></>);
        case "transport_motorbike":
            return (<><MotorcycleIcon/></>);
        case "transport_truck":
            return (<><LocalShippingIcon/></>);
    }

    return null
}