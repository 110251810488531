import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
 
class Answer6 extends Component {
  render() {
    return (
      <div className="body">
        
        <div className="answers">
            
            <br/>

            <h1 id="para-agricultores-cómo-ser-parte-de-una-asociación-en-listo">Para agricultores, ¿cómo ser parte de una asociación en Listo?</h1>
            
            <ul>
              <li><Link to="#opción-1-invitación-a-través-del-menú-de-mensajes">A través del menú de mensajes</Link></li>
              <li><Link to="#opción-2-invitación-a-través-de-la-aplicación">A través de la aplicación</Link></li>
            </ul>

            <br/>

            <p><strong>Los líderes o administradores de la asociación</strong>, son <strong>los encargados de crear el perfil</strong> de la misma en Listo. Una vez ellos hayan hecho esto, enviarán invitaciones a los productores para que ahora también sean parte de la asociación en Listo.</p>

            <p>La invitación llegará a través de <strong>un mensaje de texto</strong> y como <strong>una notificación de aplicación</strong>.</p>

            <br/>

            <h2 id="opción-1-invitación-a-través-del-menú-de-mensajes">Opción 1. Invitación a través del menú de mensajes</h2>
            <blockquote>
              <p>Recibirá un mensaje de texto del número 891177 que le indica que ha sido invitado a ser parte de una asociación.</p>
            </blockquote>

            <ol>
              <li>
                Lea las instrucciones en el mensaje y responda de acuerdo a la instrucción enviada.
              </li>
              <li>
                Escriba <strong>LT aso</strong> (este es el código de respuesta a la asociación), agregue un espacio y escriba <strong>los tres números únicos</strong> que le indica el mensaje para aceptar.
                <blockquote>
                  <p>Por ejemplo: <strong>LT aso 123</strong>.<br />
            Este proceso es el mismo en caso de se quiera rechazar la invitación.</p>
                </blockquote>
              </li>
            </ol>

            <br/>
            <br/>
            <h2 id="opción-2-invitación-a-través-de-la-aplicación">Opción 2. Invitación a través de la aplicación</h2>
            <ol>
              <li>Diríjase a la página de inicio, en la esquina superior derecha verá <strong>la campana de notificaciones</strong>, allí encontrará su actividad reciente y entre esta aparecerá la invitación para unirse a una asociación.
                <blockquote>
                  <p>Esta invitación también aparecerá en <strong>la pestaña Asociaciones</strong> ubicada en el menú inferior, la invitación estará en <strong>la sección Invitaciones pendientes</strong>.</p>
                </blockquote>
              </li>
              <li>Haga clic sobre la invitación y verá <strong>dos botones</strong>, uno es <strong>Aceptar</strong> y el otro es <strong>Rechazar</strong>, presione el botón que corresponda a la opción que desee seleccionar.</li>
            </ol>

            <p>Ahora, recibirá alertas de la asociación cada vez que esta quiera compartir información o invitaciones a pedidos grupales con usted.</p>
                    
            <br/>
            
        </div>


      </div>
    );
  }
}
 
export default Answer6;