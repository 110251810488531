import React, { Component } from "react";
import { Route, NavLink } from "react-router-dom";
import ReactGA from 'react-ga';

import Answer1 from "./agricultor/answer_1";
import Answer2 from "./agricultor/answer_2";
import Answer3 from "./agricultor/answer_3";
import Answer4 from "./agricultor/answer_4";
import Answer5 from "./agricultor/answer_5";
import Answer6 from "./agricultor/answer_6";

 
class Agricultor extends Component {
  render() {
    return (
      <div className="body">
        
        {ReactGA.pageview(window.location.pathname)}
        
        <div className="questions">
            <h1>Agricultores</h1>
                        
            <div>
                <ul>
                  <li><NavLink to="/agricultor/crear_una_publicación">¿Cómo crear una publicación?</NavLink></li>
                  <li><NavLink to="/agricultor/buscar_los_pedidos_activos_y_contribuir">¿Cómo buscar pedidos y contribuir?</NavLink></li>
                  <li><NavLink to="/agricultor/hacer_edicones_antes_del_intercambio">¿Cómo editar antes del intercambio?</NavLink></li>
                  <li><NavLink to="/agricultor/realizar_el_intercambio">¿Cómo realizar el intercambio?</NavLink></li>
                  <li><NavLink to="/agricultor/ingresar_calificaciones">¿Cómo enviar calificaciones?</NavLink></li>
                  <li><NavLink to="/agricultor/ser_parte_de_una_asociación">¿Cómo ser parte de una asociación en Listo?</NavLink></li>
                </ul>
            </div>

            <Route path="/agricultor/crear_una_publicación" component={Answer1}/>
            <Route path="/agricultor/buscar_los_pedidos_activos_y_contribuir" component={Answer2}/>
            <Route path="/agricultor/hacer_edicones_antes_del_intercambio" component={Answer3}/>
            <Route path="/agricultor/realizar_el_intercambio" component={Answer4}/>
            <Route path="/agricultor/ingresar_calificaciones" component={Answer5}/>
            <Route path="/agricultor/ser_parte_de_una_asociación" component={Answer6}/>

        </div>
      </div>
    );
  }
}
 
export default Agricultor;